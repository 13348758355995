import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import styled from 'styled-components';

const Link = styled.a`
  color: #452ec5 !important;
`

const announcement: {[id: number]: React.ReactNode} = {
  0: (
    <>
      We are moving <Link href="https://beta.yearn.finance/#/ironbank">Fantom Iron Bank to Yearn</Link>. This site will no longer support Fantom Opera network starting Nov 1, 2021. <Link href="https://medium.com/cream-finance/c-r-e-a-m-dd4982004bb9">More details</Link>.
    </>
  ),
  1: (
    <>
      Note: You are currently connected to the Goerli Testnet.
    </>
  )
}

interface AnnouncementProps {
  id: number;
}

const Announcement: React.FC<AnnouncementProps> = ({ id }) => {
  let announcementId = window.localStorage.getItem('a_id');
  const [dismissed, setDismissed] = useState(announcementId === String(id));

  if (dismissed) {
    return <></>
  }

  const dismiss = () => {
    // window.localStorage.setItem('a_id', String(id));
    setDismissed(true);
  }

  return (
    <Wrapper>
      <div>
        {announcement[id]}
      </div>
      {/*<Dismiss icon={faTimes} color="black" onClick={dismiss}/>*/}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #cdc5ff;
  width: 100%;
  text-align: center;
  color: black;
  padding: 10px 24px;
`;

const Dismiss = styled(FontAwesomeIcon)`
  cursor: pointer;
  position: absolute;
  right: 8px;
`;

export default Announcement;
