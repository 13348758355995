import { BigNumber, Contract } from 'ethers';
import { Signer } from "@ethersproject/abstract-signer";
import { Provider } from '@ethersproject/abstract-provider';
import PriceOracleABI from './ABIs/priceOracle';

class PriceOracle {
  private contract: Contract;

  address: string;

  constructor(address: string, provider: Provider | Signer) {
    this.contract = new Contract(address, PriceOracleABI, provider);
    this.address = address;
  }

  async getUnderlyingPrice(cToken: string): Promise<BigNumber> {
    return this.contract.getUnderlyingPrice(cToken);
  }
}

export default PriceOracle;
