const PriceOracleABI = [{
  'inputs': [{
    'internalType': 'address',
    'name': 'admin_',
    'type': 'address'
  }, {
    'internalType': 'address',
    'name': 'v1PriceOracle_',
    'type': 'address'
  }, {
    'internalType': 'address',
    'name': 'cEthAddress_',
    'type': 'address'
  }, {
    'internalType': 'address',
    'name': 'cXSushiAddress_',
    'type': 'address'
  }],
  'payable': false,
  'stateMutability': 'nonpayable',
  'type': 'constructor'
}, {
  'anonymous': false,
  'inputs': [{
    'indexed': false,
    'internalType': 'address',
    'name': 'tokenAddress',
    'type': 'address'
  }, {
    'indexed': false,
    'internalType': 'address',
    'name': 'source',
    'type': 'address'
  }, {
    'indexed': false,
    'internalType': 'enum PriceOracleProxy.AggregatorBase',
    'name': 'base',
    'type': 'uint8'
  }],
  'name': 'AggregatorUpdated',
  'type': 'event'
}, {
  'anonymous': false,
  'inputs': [{
    'indexed': false,
    'internalType': 'address',
    'name': 'tokenAddress',
    'type': 'address'
  }, {
    'indexed': false,
    'internalType': 'bool',
    'name': 'isLP',
    'type': 'bool'
  }],
  'name': 'IsLPUpdated',
  'type': 'event'
}, {
  'anonymous': false,
  'inputs': [{
    'indexed': false,
    'internalType': 'address',
    'name': 'admin',
    'type': 'address'
  }],
  'name': 'SetAdmin',
  'type': 'event'
}, {
  'anonymous': false,
  'inputs': [{
    'indexed': false,
    'internalType': 'address',
    'name': 'token',
    'type': 'address'
  }, {
    'indexed': false,
    'internalType': 'enum PriceOracleProxy.CurvePoolType',
    'name': 'poolType',
    'type': 'uint8'
  }, {
    'indexed': false,
    'internalType': 'address',
    'name': 'swap',
    'type': 'address'
  }],
  'name': 'SetCurveToken',
  'type': 'event'
}, {
  'anonymous': false,
  'inputs': [{
    'indexed': false,
    'internalType': 'address',
    'name': 'guardian',
    'type': 'address'
  }],
  'name': 'SetGuardian',
  'type': 'event'
}, {
  'anonymous': false,
  'inputs': [{
    'indexed': false,
    'internalType': 'address',
    'name': 'token',
    'type': 'address'
  }, {
    'indexed': false,
    'internalType': 'enum PriceOracleProxy.YvTokenVersion',
    'name': 'version',
    'type': 'uint8'
  }],
  'name': 'SetYVaultToken',
  'type': 'event'
}, {
  'constant': false,
  'inputs': [{
    'internalType': 'address',
    'name': '_admin',
    'type': 'address'
  }],
  'name': '_setAdmin',
  'outputs': [],
  'payable': false,
  'stateMutability': 'nonpayable',
  'type': 'function'
}, {
  'constant': false,
  'inputs': [{
    'internalType': 'address[]',
    'name': 'tokenAddresses',
    'type': 'address[]'
  }, {
    'internalType': 'address[]',
    'name': 'sources',
    'type': 'address[]'
  }, {
    'internalType': 'enum PriceOracleProxy.AggregatorBase[]',
    'name': 'bases',
    'type': 'uint8[]'
  }],
  'name': '_setAggregators',
  'outputs': [],
  'payable': false,
  'stateMutability': 'nonpayable',
  'type': 'function'
}, {
  'constant': false,
  'inputs': [{
    'internalType': 'address[]',
    'name': 'tokenAddresses',
    'type': 'address[]'
  }, {
    'internalType': 'enum PriceOracleProxy.CurveTokenVersion[]',
    'name': 'version',
    'type': 'uint8[]'
  }, {
    'internalType': 'enum PriceOracleProxy.CurvePoolType[]',
    'name': 'poolType',
    'type': 'uint8[]'
  }, {
    'internalType': 'address[]',
    'name': 'swap',
    'type': 'address[]'
  }],
  'name': '_setCurveTokens',
  'outputs': [],
  'payable': false,
  'stateMutability': 'nonpayable',
  'type': 'function'
}, {
  'constant': false,
  'inputs': [{
    'internalType': 'address',
    'name': '_guardian',
    'type': 'address'
  }],
  'name': '_setGuardian',
  'outputs': [],
  'payable': false,
  'stateMutability': 'nonpayable',
  'type': 'function'
}, {
  'constant': false,
  'inputs': [{
    'internalType': 'address[]',
    'name': 'cTokenAddresses',
    'type': 'address[]'
  }, {
    'internalType': 'bool[]',
    'name': 'isLP',
    'type': 'bool[]'
  }],
  'name': '_setLPs',
  'outputs': [],
  'payable': false,
  'stateMutability': 'nonpayable',
  'type': 'function'
}, {
  'constant': false,
  'inputs': [{
    'internalType': 'address[]',
    'name': 'tokenAddresses',
    'type': 'address[]'
  }, {
    'internalType': 'enum PriceOracleProxy.YvTokenVersion[]',
    'name': 'version',
    'type': 'uint8[]'
  }],
  'name': '_setYVaultTokens',
  'outputs': [],
  'payable': false,
  'stateMutability': 'nonpayable',
  'type': 'function'
}, {
  'constant': true,
  'inputs': [],
  'name': 'admin',
  'outputs': [{
    'internalType': 'address',
    'name': '',
    'type': 'address'
  }],
  'payable': false,
  'stateMutability': 'view',
  'type': 'function'
}, {
  'constant': true,
  'inputs': [{
    'internalType': 'address',
    'name': '',
    'type': 'address'
  }],
  'name': 'aggregators',
  'outputs': [{
    'internalType': 'contract AggregatorV3Interface',
    'name': 'source',
    'type': 'address'
  }, {
    'internalType': 'enum PriceOracleProxy.AggregatorBase',
    'name': 'base',
    'type': 'uint8'
  }],
  'payable': false,
  'stateMutability': 'view',
  'type': 'function'
}, {
  'constant': true,
  'inputs': [{
    'internalType': 'address',
    'name': '',
    'type': 'address'
  }],
  'name': 'areUnderlyingLPs',
  'outputs': [{
    'internalType': 'bool',
    'name': '',
    'type': 'bool'
  }],
  'payable': false,
  'stateMutability': 'view',
  'type': 'function'
}, {
  'constant': true,
  'inputs': [],
  'name': 'cEthAddress',
  'outputs': [{
    'internalType': 'address',
    'name': '',
    'type': 'address'
  }],
  'payable': false,
  'stateMutability': 'view',
  'type': 'function'
}, {
  'constant': true,
  'inputs': [],
  'name': 'cXSushiAddress',
  'outputs': [{
    'internalType': 'address',
    'name': '',
    'type': 'address'
  }],
  'payable': false,
  'stateMutability': 'view',
  'type': 'function'
}, {
  'constant': true,
  'inputs': [{
    'internalType': 'address',
    'name': '',
    'type': 'address'
  }],
  'name': 'crvTokens',
  'outputs': [{
    'internalType': 'bool',
    'name': 'isCrvToken',
    'type': 'bool'
  }, {
    'internalType': 'enum PriceOracleProxy.CurvePoolType',
    'name': 'poolType',
    'type': 'uint8'
  }, {
    'internalType': 'address',
    'name': 'curveSwap',
    'type': 'address'
  }],
  'payable': false,
  'stateMutability': 'view',
  'type': 'function'
}, {
  'constant': true,
  'inputs': [{
    'internalType': 'contract CToken',
    'name': 'cToken',
    'type': 'address'
  }],
  'name': 'getUnderlyingPrice',
  'outputs': [{
    'internalType': 'uint256',
    'name': '',
    'type': 'uint256'
  }],
  'payable': false,
  'stateMutability': 'view',
  'type': 'function'
}, {
  'constant': true,
  'inputs': [],
  'name': 'guardian',
  'outputs': [{
    'internalType': 'address',
    'name': '',
    'type': 'address'
  }],
  'payable': false,
  'stateMutability': 'view',
  'type': 'function'
}, {
  'constant': true,
  'inputs': [],
  'name': 'isPriceOracle',
  'outputs': [{
    'internalType': 'bool',
    'name': '',
    'type': 'bool'
  }],
  'payable': false,
  'stateMutability': 'view',
  'type': 'function'
}, {
  'constant': true,
  'inputs': [],
  'name': 'sushiAddress',
  'outputs': [{
    'internalType': 'address',
    'name': '',
    'type': 'address'
  }],
  'payable': false,
  'stateMutability': 'view',
  'type': 'function'
}, {
  'constant': true,
  'inputs': [],
  'name': 'usdcAddress',
  'outputs': [{
    'internalType': 'address',
    'name': '',
    'type': 'address'
  }],
  'payable': false,
  'stateMutability': 'view',
  'type': 'function'
}, {
  'constant': true,
  'inputs': [],
  'name': 'v1PriceOracle',
  'outputs': [{
    'internalType': 'contract V1PriceOracleInterface',
    'name': '',
    'type': 'address'
  }],
  'payable': false,
  'stateMutability': 'view',
  'type': 'function'
}, {
  'constant': true,
  'inputs': [],
  'name': 'wethAddress',
  'outputs': [{
    'internalType': 'address',
    'name': '',
    'type': 'address'
  }],
  'payable': false,
  'stateMutability': 'view',
  'type': 'function'
}, {
  'constant': true,
  'inputs': [],
  'name': 'xSushiExRateAddress',
  'outputs': [{
    'internalType': 'address',
    'name': '',
    'type': 'address'
  }],
  'payable': false,
  'stateMutability': 'view',
  'type': 'function'
}, {
  'constant': true,
  'inputs': [{
    'internalType': 'address',
    'name': '',
    'type': 'address'
  }],
  'name': 'yvTokens',
  'outputs': [{
    'internalType': 'bool',
    'name': 'isYvToken',
    'type': 'bool'
  }, {
    'internalType': 'enum PriceOracleProxy.YvTokenVersion',
    'name': 'version',
    'type': 'uint8'
  }],
  'payable': false,
  'stateMutability': 'view',
  'type': 'function'
}];

export default PriceOracleABI;
