export const CreamApiUrl = 'https://api.cream.finance';

export const CreamMarketDataUrl = `${CreamApiUrl}/api/v1/crtoken`;
export const CreamMarketChangeDataUrl = `${CreamApiUrl}/api/v1/changes`;
export const CreamMarketHistoryDataUrl = `${CreamApiUrl}/api/v1/history`;

export const BnbUsdPriceEndpoint = 'https://api.coingecko.com/api/v3/simple/price?ids=binancecoin&vs_currencies=usd';
export const EthUsdPriceEndpoint = 'https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd';
export const MaticUsdPriceEndpoint = 'https://api.coingecko.com/api/v3/simple/price?ids=matic-network&vs_currencies=usd';
export const CreamUsdPriceEndpoint = 'https://api.coingecko.com/api/v3/simple/price?ids=cream-2&vs_currencies=usd';

// Failure (uint256 error, uint256 info, uint256 detail)
export const failureSignature = '0x45b96fe442630264581b197e84bbada861235052c5a1aadfff9ea4e40a969aa0';

export const maticAddress = '0x3FaE5e5722C51cdb5B0afD8c7082e8a6AF336Ee8';
export const arbitrumEthAddress = '0x5441090C0401EE256b09DEb35679Ad175d1a0c97';
export const avaxAddress = '0xb3c68d69E95B095ab4b33B4cB67dBc0fbF3Edf56';

export const nativeAddress = '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE';

export const secondsPerYear = 31536000;
