import { BigNumber } from 'ethers';
import React, { useContext, useMemo } from 'react';
import BorrowRepayModal from '../../../components/Modal/BorrowRepayModal';
import {
  displayBalance,
  oracleNativeBalance,
  rateToApy,
  borrowLimitPercentage,
  nativeBalanceToUsd,
} from '../../../cream/utils';
import useModal from '../../../hooks/useModal';
import useUserBorrowSummary from '../../../hooks/useUserBorrowSummary';
import { MarketContext } from '../../../providers/MarketProvider';
import { ProtocolContext } from '../../../providers/ProtocolProvider';
import Header from './Header';
import MarketTable from './MarketTable';
import UserMarket from './UserMarket';
import TokenIconSymbol from '../../../components/TokenIconSymbol';
import { IDataTableColumn } from 'react-data-table-component';
import { useTheme } from 'styled-components';
import { useTranslation } from "react-i18next";

type UserBorrowMarketData = {
  asset: string;
  address: string;
  apy: string;
  borrowRate: BigNumber;
  balance: string;
  limit: string;
}

const useUserBorrowMarketData = (): UserBorrowMarketData[] => {
  const { markets, allMarketStats, allUserTokenStats } = useContext(MarketContext);
  const { protocol } = useContext(ProtocolContext);
  const { borrowLimitInNative } = useUserBorrowSummary();

  const data: UserBorrowMarketData[] = useMemo(() => {
    const data = [];
    for (const i in allUserTokenStats) {
      const userTokenStats = allUserTokenStats[i];
      const marketStats = allMarketStats[i];
      const market = markets[i];

      if (userTokenStats.borrowBalance.eq(0)) {
        continue;
      }

      const nativeBalance = oracleNativeBalance(userTokenStats.borrowBalance, marketStats.underlyingPrice)

      data.push({
        asset: market.underlyingSymbol,
        address: market.address,
        apy: rateToApy(marketStats.borrowRate, protocol.blocksPerYear),
        borrowRate: marketStats.borrowRate,
        balance: displayBalance(userTokenStats.borrowBalance, market.underlyingDecimal, 2),
        limit: borrowLimitPercentage(borrowLimitInNative, nativeBalance)
      })
    }
    return data;
  }, [markets, allUserTokenStats, allMarketStats, protocol, borrowLimitInNative]);

  return data;
}

export const UserBorrowMarket: React.FC = () => {
  const { t } = useTranslation();
  const userBorrowSummary = useUserBorrowSummary();
  const totalBorrowBalanceUSD = nativeBalanceToUsd(userBorrowSummary.totalBorrowBalanceInNative, userBorrowSummary.basePrice, 2);

  const columns: IDataTableColumn<UserBorrowMarketData>[] = [
    {
      name: t('BORROWED ASSET'),
      selector: 'asset',
      sortable: true,
      cell: (row) => <TokenIconSymbol data-tag="allowRowEvents" symbol={row.asset} />,
      grow: 100,
    },
    {
      name: t('APY / ACCRUED'),
      selector: 'apy',
      sortable: true,
      sortFunction: (a, b) => { return a.borrowRate.toNumber() - b.borrowRate.toNumber() },
      right: true,
    },
    {
      name: t('BALANCE'),
      selector: 'balance',
      cell: (row) => <div data-tag="allowRowEvents" style={{ whiteSpace: 'nowrap' }}>{row.balance}</div>,
      right: true,
    },
    {
      name: t('BORROW LIMIT'),
      selector: 'limit',
      right: true,
    },
  ];

  const { presentModal } = useModal();
  const onRowClicked = (row: UserBorrowMarketData) => {
    presentModal(<BorrowRepayModal marketAddress={row.address} />);
  }

  const data = useUserBorrowMarketData();
  const theme = useTheme();
  const table = <MarketTable columns={columns} data={data} keyField="asset"
                             onRowClicked={onRowClicked}
                             noDataComponent={t('NO BORROWED ASSETS')} />
  const leftHeader = <Header align="left" title={t('TOTAL BORROW BALANCE')} value={totalBorrowBalanceUSD} valueColor={theme.colors.secondary} />;
  const rightHeader = <Header align="right" title={t('Your Borrow Limit')} value={userBorrowSummary.borrowLimitPct} />;

  return (
    <UserMarket leftHeader={leftHeader} rightHeader={rightHeader} table={table} tableBackgroundColor={theme.colors.borrowCardBackground} />
  );
};

export default UserBorrowMarket;
