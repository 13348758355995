import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { ProtocolContext } from '../../providers/ProtocolProvider';
import Footer from '../Footer';
import MobileMenu from '../MobileMenu';
import TopBar from '../TopBar';
import Announcement from '../Announcement';
import { MediaType } from '../../media';
import { useMediaQuery } from 'react-responsive';
import {
  FantomProtocol, GoerliProtocol,
  Protocol
} from '../../cream/Protocols';

export type SiteProtocol = {
  title: string;
  value: Protocol;
};

const Page: React.FC = ({ children }) => {
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);
  const { protocol } = useContext(ProtocolContext);

  const protocols = [
    { title: 'Goerli', value: GoerliProtocol },
  ]

  return (
    <>
      <TopBar isMenuOpen={isMenuOpen} onMenuToggle={setMenuOpen} protocols={protocols} />
      <MenuWrap>
        {useMediaQuery({ maxWidth: MediaType.md }) && (
          <MobileMenu isMenuOpen={isMenuOpen} onMenuToggle={setMenuOpen} protocols={protocols}/>
        )}
        <StyledMain>
          <Announcement id={1} />
          {children}
        </StyledMain>
        <Footer />
      </MenuWrap>
    </>
  )
};

const MenuWrap = styled.div`
  height: calc(100vh - 53px);
  overflow: auto;
`;

const StyledMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - ${(props) => props.theme.topBarSize + props.theme.footerSize}px);
  width: 100%;
  background-color: #151b28;
`;

export default Page;
