import React, { useContext, useEffect, useMemo, useState } from 'react';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { SiteProtocol } from '../Page/Page';
import NavigationBar from './components/NavigationBar';
import Wallet from './components/Wallet';
import { Container } from 'react-bootstrap';
import logo from '../../assets/img/logo.svg';
import menuIcon from '../../assets/img/menu.svg';
import menuCloseIcon from '../../assets/img/sidemenu_close.svg';
import Dropdown from './components/Dropdown';
import { ProtocolContext } from '../../providers/ProtocolProvider';
import { useTranslation } from 'react-i18next';
import { MediaType } from '../../media';
import { useMediaQuery } from 'react-responsive'
import { NavLink } from 'react-router-dom';
import { Nav } from './type';

interface TopBarProps {
  isMenuOpen: boolean;
  onMenuToggle: (value: boolean) => void;
  protocols: SiteProtocol[];
}

const TopBar: React.FC<TopBarProps> = ({ isMenuOpen, onMenuToggle, protocols }) => {
  const { protocol: currentProtocol, changeProtocol } = useContext(ProtocolContext);
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const languages = [
    { title: 'EN', value: 'en' },
    { title: '中文', value: 'zh-Hant' },
    { title: '日本語', value: 'jp' },
    { title: '한국어', value: 'kr' },
    { title: 'Español', value: 'es' },
  ]

  const navs: Nav[] = useMemo(() => [
    {
      title: t('LENDING NAV'),
      link: '/',
    },
  ], [t]);

  const [selectedSubNav, setSelectedSubNav] = useState<Nav[] | undefined>(undefined);

  const pageSubNav = useMemo(() => {
    for (const n of navs) {
      if (!n.subNav) continue;
      for (const s of n.subNav) {
        if (matchPath(location.pathname, { path: s.link })) {
          return n.subNav
        }
      }
    }
    return undefined;
  }, [location.pathname, navs]);

  useEffect(() => {
    return () => setSelectedSubNav(undefined)
  }, [location.pathname]);

  const subNav = useMemo(() => {
    return selectedSubNav || pageSubNav;
  }, [pageSubNav, selectedSubNav])

  if (useMediaQuery({ minWidth: MediaType.md })) {
    return (
      <FixedContainer fluid>
        <FirstBar>
          <Logo onClick={() => history.push('/')} src={logo} alt={logo}/>
          <NavigationBar navs={navs} onSubNavSelected={setSelectedSubNav} />
          <Spacer />
          <Dropdown
            options={protocols}
            selectedValue={currentProtocol}
            alignTop={true}
            onSelect={(protocol) => { changeProtocol(protocol) }}
          />
          <Dropdown
            options={languages}
            selectedValue={i18n.language}
            alignTop={true}
            onSelect={(lang) => { i18n.changeLanguage(lang) }}
            style={{ marginRight: '16px' }}
          />
          <Wallet />
        </FirstBar>
        {subNav && (
          <SecondBar>
            /
            {subNav.map(s => <StyledLink exact activeClassName="active" to={s.link!} key={s.title}>{s.title}</StyledLink>) }
          </SecondBar>
        )}
      </FixedContainer>
    )
  } else {
    return (
      <FixedContainerMobile fluid>
        <Menu onClick={() => { onMenuToggle(!isMenuOpen) }}>
          {isMenuOpen ? (
            <img src={menuCloseIcon} alt={menuCloseIcon} style={{ marginRight: '6px' }}/>
          ) : (
            <img src={menuIcon} alt={menuIcon} style={{ marginRight: '6px' }}/>
          )}
          <span>{isMenuOpen ? 'CLOSE' : 'MENU'}</span>
        </Menu>
        <img src={logo} alt={logo} height={18}/>
        <Wallet />
      </FixedContainerMobile>
    )
  }
};

const FixedContainer = styled(Container)`
  position: sticky;
  top: 0;
  background-color: ${(props) => props.theme.colors.background};
  z-index: 30;
  font-size: 11px;
  font-weight: 500;
  width: 100%;
`;

const FixedContainerMobile = styled(FixedContainer)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 53px;
  background: #151B28;
  border-bottom: 1px solid #1E2738;
`;

const FirstBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 53px;
  border-bottom: 1px solid #1E2738;
`;

const SecondBar = styled.div`
  height: 47px;
  border-bottom: 1px solid #1E2738;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 200px;
`;

const StyledLink = styled(NavLink)`
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0 ${(props) => props.theme.spacing[4]};
  &:first-child {
    margin-left: ${(props) => props.theme.spacing[6]};
  }
  font-weight: 500;
  font-size: 13px;

  &.active {
    color: ${props => props.theme.colors.creamGreen};
  }

  &:hover {
    color: ${props => props.theme.colors.creamGreen};
    text-decoration: none !important;
  }
`;

const Logo = styled.img`
  cursor: pointer;
`

const Spacer = styled.div`
  flex-grow: 1;
  flex-basis: 50px;
`;

const Menu = styled.span`
  cursor: pointer;
  width: 77px;
`;

export default TopBar;
