const CreamETH2OracleABI = [
  {
    "inputs":[
      {
        "internalType":"address",
        "name":"_poster",
        "type":"address"
      }
    ],
    "stateMutability":"nonpayable",
    "type":"constructor"
  },
  {
    "anonymous":false,
    "inputs":[
      {
        "indexed":false,
        "internalType":"address",
        "name":"operator",
        "type":"address"
      },
      {
        "indexed":false,
        "internalType":"uint256",
        "name":"prevExchangeRate",
        "type":"uint256"
      },
      {
        "indexed":false,
        "internalType":"uint256",
        "name":"newExchangeRate",
        "type":"uint256"
      }
    ],
    "name":"ExchangeRatePosted",
    "type":"event"
  },
  {
    "inputs":[

    ],
    "name":"MAX_SWING",
    "outputs":[
      {
        "internalType":"uint256",
        "name":"",
        "type":"uint256"
      }
    ],
    "stateMutability":"view",
    "type":"function"
  },
  {
    "inputs":[

    ],
    "name":"UPDATE_PERIOD",
    "outputs":[
      {
        "internalType":"uint256",
        "name":"",
        "type":"uint256"
      }
    ],
    "stateMutability":"view",
    "type":"function"
  },
  {
    "inputs":[

    ],
    "name":"admin",
    "outputs":[
      {
        "internalType":"address",
        "name":"",
        "type":"address"
      }
    ],
    "stateMutability":"view",
    "type":"function"
  },
  {
    "inputs":[

    ],
    "name":"currentExchangeRate",
    "outputs":[
      {
        "internalType":"uint256",
        "name":"",
        "type":"uint256"
      }
    ],
    "stateMutability":"view",
    "type":"function"
  },
  {
    "inputs":[

    ],
    "name":"exchangeRate",
    "outputs":[
      {
        "internalType":"uint256",
        "name":"",
        "type":"uint256"
      }
    ],
    "stateMutability":"view",
    "type":"function"
  },
  {
    "inputs":[

    ],
    "name":"lastUpdated",
    "outputs":[
      {
        "internalType":"uint256",
        "name":"",
        "type":"uint256"
      }
    ],
    "stateMutability":"view",
    "type":"function"
  },
  {
    "inputs":[

    ],
    "name":"poster",
    "outputs":[
      {
        "internalType":"address",
        "name":"",
        "type":"address"
      }
    ],
    "stateMutability":"view",
    "type":"function"
  },
  {
    "inputs":[
      {
        "internalType":"address",
        "name":"_admin",
        "type":"address"
      }
    ],
    "name":"setAdmin",
    "outputs":[

    ],
    "stateMutability":"nonpayable",
    "type":"function"
  },
  {
    "inputs":[
      {
        "internalType":"uint256",
        "name":"newExchangeRate",
        "type":"uint256"
      }
    ],
    "name":"setExchangeRate",
    "outputs":[

    ],
    "stateMutability":"nonpayable",
    "type":"function"
  },
  {
    "inputs":[
      {
        "internalType":"address",
        "name":"_poster",
        "type":"address"
      }
    ],
    "name":"setPoster",
    "outputs":[

    ],
    "stateMutability":"nonpayable",
    "type":"function"
  },
  {
    "inputs":[
      {
        "internalType":"uint256",
        "name":"newExchangeRate",
        "type":"uint256"
      }
    ],
    "name":"updateExchangeRate",
    "outputs":[

    ],
    "stateMutability":"nonpayable",
    "type":"function"
  }
];

export default CreamETH2OracleABI;
