import { createTheme } from 'react-data-table-component';

createTheme('market', {
  text: {
    primary: 'white',
  },
  background: {
    default: 'none',
  },
  sortFocus: {
    default: 'white',
  }
});
