import { BigNumber } from 'ethers';
import React, { useContext, useMemo, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import SupplyWithdrawModal from '../../../components/Modal/SupplyWithdrawModal';
import TokenIconSymbol from '../../../components/TokenIconSymbol';
import { Category } from '../../../cream/Category';
import { displayBalance, rateToApy, totalSupplyInUsd, distributionApy } from '../../../cream/utils';
import useModal from '../../../hooks/useModal';
import { MarketContext } from '../../../providers/MarketProvider';
import { ProtocolContext } from '../../../providers/ProtocolProvider';
import MarketCard from './MarketCard';
import MarketSearchBar from './MarketSearchBar';
import { IDataTableColumn } from 'react-data-table-component';
import MarketTable from './MarketTable';
import { useTranslation } from "react-i18next";
import _ from 'lodash';

type SupplyMarketData = {
  asset: string;
  address: string;
  apy: string;
  distributionApy: string;
  supplyRate: BigNumber;
  wallet: string;
  category: Category;
}

const useSupplyMarketData = (): SupplyMarketData[] => {
  const { markets, allMarketStats, allUserTokenStats, lmRewardsStats, basePrice } = useContext(MarketContext);
  const { protocol } = useContext(ProtocolContext);

  const data = useMemo(() => {
    const data: SupplyMarketData[] = [];
    for (const i in allMarketStats) {
      const userTokenStats = allUserTokenStats[i];
      const marketStats = allMarketStats[i];
      const market = markets[i];

      const speeds = lmRewardsStats[i].rewardSpeeds;
      const totalSupply = totalSupplyInUsd(marketStats.supply, marketStats.underlyingPrice, marketStats.exchangeRate, basePrice);

      data.push({
        address: market.address,
        apy: rateToApy(marketStats.supplyRate, protocol.blocksPerYear),
        distributionApy: distributionApy(speeds, totalSupply, true),
        asset: market.underlyingSymbol,
        supplyRate: marketStats.supplyRate,
        wallet: displayBalance(userTokenStats.walletBalance, market.underlyingDecimal, 2),
        category: market.category,
      });
    }
    return data;
  }, [markets, allUserTokenStats, allMarketStats, lmRewardsStats, basePrice, protocol])

  return data;
}

const SupplyMarket: React.FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [category, setCategory] = useState<Category | null>(null);

  const columns: IDataTableColumn<SupplyMarketData>[] = [
    {
      name: t('ASSET'),
      selector: 'asset',
      sortable: true,
      cell: (row) => <TokenIconSymbol data-tag="allowRowEvents" symbol={row.asset} />,
    },
    {
      name: t('SUPPLY APY ALL CAP'),
      selector: 'apy',
      sortable: true,
      sortFunction: (a, b) => { return a.supplyRate.toNumber() - b.supplyRate.toNumber() },
      right: true,
    },
    {
      name: t('Reward APY'),
      selector: 'distributionApy',
      cell: (row) => <div data-tag="allowRowEvents" style={{ whiteSpace: 'nowrap' }}>{row.distributionApy}</div>,
      right: true,
    },
    {
      name: t('WALLET'),
      selector: 'wallet',
      cell: (row) => <div data-tag="allowRowEvents" style={{ whiteSpace: 'nowrap' }}>{row.wallet} {row.asset}</div>,
      right: true,
      conditionalCellStyles: [{
        when: (row) => row.wallet === '0',
        style: { color: theme.colors.grey },
      }]
    },
];

  const data = useSupplyMarketData();

  const [filterText, setFilterText] = useState('');
  // Note: cannot put `filterLegacy` in `filter` because `filter` will be used to hide "Show More".
  const filterLegacy = (d: SupplyMarketData) => {
    if (d.category === Category.legacy) {
      // Hide the asset if the user wallet balance is zero.
      return d.wallet !== '0';
    }
    return true;
  }
  const filter = (data: SupplyMarketData[]) => {
    if (!filterText && !category) {
      return _.sortBy(data, (d) => (d.wallet !== '0' ? 0 : 1));
    }

    return _.sortBy(data, (d) => (d.wallet !== '0' ? 0 : 1))
      .filter((d) => d.asset.toLowerCase().includes(filterText.toLowerCase()))
      .filter((d) => category ? d.category === category : true);
  }

  const { presentModal } = useModal();
  const onRowClicked = (row: SupplyMarketData) => {
    presentModal(<SupplyWithdrawModal marketAddress={row.address} />)
  }

  return (
    <MarketCard backgroundColor={theme.colors.supplyCardBackground}>
      <MarketSearchBar
        placeholder={t('SEARCH SUPPLY ASSETS')}
        themeColor={theme.colors.primary}
        onFilter={(text) => setFilterText(text)}
        onSelect={setCategory}
      />
      <TableWrapper className="d-flex flex-column" >
        <MarketTable columns={columns} data={data.filter(d => filterLegacy(d))} onRowClicked={onRowClicked} keyField="address"
                     filter={filter}
                     displayItems={10}
        />
      </TableWrapper>
    </MarketCard>
  )
};

const TableWrapper = styled.div`
  margin: 0 -16px;
  border-radius: 8px;
`;

export default SupplyMarket;
