import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import { Market } from '../../cream/Type';
import useApprove from '../../hooks/useApprove';
import useModal from '../../hooks/useModal';
import { FullWidthButton } from '../Button/Button';
import TitledModal from './components/TitledModal';

interface ResetAllowanceModalProps {
  market: Market;
}

const ResetAllowanceModal: React.FC<ResetAllowanceModalProps> = ({ market }) => {
  const { dismissModal } = useModal();
  const { resetApprove } = useApprove(market);
  const { creamGreen } = useTheme().colors;

  const { t } = useTranslation();

  return (
    <TitledModal title={t('Reset Allowance')}>
      {t('reset allowance notice', { symbol: market.underlyingSymbol })}
      <ContinueButton bgColor={creamGreen} textColor="black" onClick={() => {
        resetApprove();
        dismissModal();
      }}>
        {t('button.Reset Allowance')}
      </ContinueButton>
    </TitledModal>
  )
};

const ContinueButton = styled(FullWidthButton)`
  margin-top: 43px;
`;

export default ResetAllowanceModal;
