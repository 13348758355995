import { Category } from '../Category';
import { Market } from '../Type';

const AvalancheMarkets: Market[] = [
  {
    name: 'Cream Wrapped AVAX',
    address: '0xb3c68d69E95B095ab4b33B4cB67dBc0fbF3Edf56',
    symbol: 'crWAVAX',
    underlyingAddress: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
    underlyingDecimal: 18,
    underlyingName: 'Wrapped WAVAX',
    underlyingSymbol: 'WAVAX',
    category: Category.major,
    zeroAllowance: false,
  },
  {
    name: 'Cream Wrapped Ether',
    address: '0x338EEE1F7B89CE6272f302bDC4b952C13b221f1d',
    symbol: 'crWETH.E',
    underlyingAddress: '0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB',
    underlyingDecimal: 18,
    underlyingName: 'Wrapped Ether',
    underlyingSymbol: 'WETH.e',
    category: Category.major,
    zeroAllowance: false,
  },
  {
    name: 'Cream Tether USD',
    address: '0xCEb1cE674f38398432d20bc8f90345E91Ef46fd3',
    symbol: 'crUSDT.E',
    underlyingAddress: '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
    underlyingDecimal: 6,
    underlyingName: 'Tether USD',
    underlyingSymbol: 'USDT.e',
    category: Category.stable,
    zeroAllowance: false,
  },
  {
    name: 'Cream USD Coin',
    address: '0xe28965073C49a02923882B8329D3E8C1D805E832',
    symbol: 'crUSDC.E',
    underlyingAddress: '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664',
    underlyingDecimal: 6,
    underlyingName: 'USD Coin',
    underlyingSymbol: 'USDC.e',
    category: Category.stable,
    zeroAllowance: false,
  },
  {
    name: 'Cream Dai Stablecoin',
    address: '0x085682716f61a72bf8C573FBaF88CCA68c60E99B',
    symbol: 'crDAI.E',
    underlyingAddress: '0xd586E7F844cEa2F87f50152665BCbc2C279D8d70',
    underlyingDecimal: 18,
    underlyingName: 'Dai Stablecoin',
    underlyingSymbol: 'DAI.e',
    category: Category.stable,
    zeroAllowance: false,
  },
  {
    name: 'Cream Wrapped BTC',
    address: '0xB09b75916C5F4097C8b5812E63e216FEF97661Fc',
    symbol: 'crWBTC.E',
    underlyingAddress: '0x50b7545627a5162F82A992c33b87aDc75187B218',
    underlyingDecimal: 8,
    underlyingName: 'Wrapped BTC',
    underlyingSymbol: 'WBTC.e',
    category: Category.major,
    zeroAllowance: false,
  },
  {
    name: 'Cream Chainlink Token',
    address: '0x18931772Adb90e7f214B6CbC78DdD6E0F090D4B1',
    symbol: 'crLINK.E',
    underlyingAddress: '0x5947BB275c521040051D82396192181b413227A3',
    underlyingDecimal: 18,
    underlyingName: 'Chainlink Token',
    underlyingSymbol: 'LINK.e',
    category: Category.gov,
    zeroAllowance: false,
  },
];

export default AvalancheMarkets;
