import { faChevronDown, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useMemo, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { Category } from '../../../cream/Category';
import { useTranslation } from "react-i18next";
import media from '../../../media';

const searchBarRadius = '20px';

interface Option {
  title: string;
  value: Category | null;
}

interface MarketSearchBarProps {
  placeholder: string;
  onFilter: (text: string) => void;
  themeColor: string;
  onSelect?: (value: Category | null) => void;
  hideCategory?: boolean;
}

const MarketSearchBar: React.FC<MarketSearchBarProps> = ({ placeholder, onFilter, themeColor, onSelect, hideCategory }) => {
  const theme = useTheme();
  const [isExpanded, setExpanded] = useState(false);
  const [selected, setSelected] = useState<Category | null>(null);
  const { t } = useTranslation();

  const categories = useMemo<Option[]>(() => [
    { title: t('All Assets'), value: null },
    { title: t('MAJOR'), value: Category.major },
    { title: t('STABLE'), value: Category.stable },
    { title: t('WRAPPED'), value: Category.wrapped },
    { title: t('GOVERNANCE TOKEN'), value: Category.gov },
    { title: t('LEGACY'), value: Category.legacy },
  ], [t]);

  return (
    <Wrapper>
      <FontAwesomeIcon icon={faSearch} color={themeColor} style={{ marginLeft: searchBarRadius }} />
      <Input type="text" placeholder={placeholder} onChange={(e) => {onFilter(e.target.value)}} />
      {!hideCategory && (
        <AssetCategorySelector
          onClick={() => setExpanded(true)}
          onMouseEnter={() => setExpanded(true)}
          onMouseLeave={() => setExpanded(false)}
        >
          {categories.find(e => e.value === selected)?.title}
          <FontAwesomeIcon
            icon={faChevronDown} color={themeColor} style={{ marginLeft: theme.spacing[2] }} size="xs"
          />
          {isExpanded && (
            <Dropdown>
              {categories.map(category =>
                <DropdownItem
                  key={category.value}
                  onClick={(e) => {
                    e.stopPropagation();
                    setExpanded(false);
                    setSelected(category.value);
                    if (onSelect) {
                      onSelect(category.value);
                    }
                  }}
                >
                  {category.title}
                </DropdownItem>)
              }
            </Dropdown>)
          }
        </AssetCategorySelector>
      )}
    </Wrapper>
  )
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  border-radius: ${searchBarRadius};
  background-color: black;
  height: 40px;
`;

const Input = styled.input`
  height: 100%;
  border: none;
  background-color: black;
  outline: none;
  flex-grow: 1;
  color: white;
  margin-left: ${(props) => props.theme.spacing[2]};
  caret-color: ${(props) => props.theme.colors.grey};
  border-radius: ${searchBarRadius};
  width: 155px;

  ${media.up('sm')} {
    width: unset;
  }
`;

const AssetCategorySelector = styled.div`
  margin-right: ${searchBarRadius};
  position: relative;
  cursor: pointer;
  flex-shrink: 0;
`;

const Dropdown = styled.ul`
  position: absolute;
  background: #000000;
  border: 1px solid #1E2738;
  box-sizing: border-box;
  box-shadow: 0px 32px 64px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  top: 20px;
  right: -20px;
  z-index: 1;
  list-style-type: none;
  padding: 8px;
`;

const DropdownItem = styled.li`
  color: #E6E6E6;
  height: 40px;
  line-height: 40px;
  padding: 0 30px;
  white-space:nowrap;
  &:hover, &:active {
    color: black;
    background: #69E2DB;
    border-radius: 4px;
  }
`;

export default MarketSearchBar;
