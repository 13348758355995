import { useContext } from 'react';
import { Protocol } from '../cream/Protocols';
import { ProtocolContext } from '../providers/ProtocolProvider';

const useExplorer = () => {
  const { protocol: currentProtocol } = useContext(ProtocolContext);

  const openLink = (type: string) => {
    return (query: string, protocol: Protocol = currentProtocol) => {
      window.open(`${protocol.explorer}/${type}/${query}`, '_blank')?.focus();
    }
  }

  return {
    openTx: openLink('tx'),
    openAddress: openLink('address'),
  }
}

export default useExplorer;
