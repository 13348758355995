import { Category } from '../Category';
import { Market } from '../Type';

const FujiMarkets: Market[] = [
  {
    name: 'Cream Wrapped Ether',
    address: '0x73CF8c5D14Aa0EbC89f18272A568319F5BAB6cBD',
    symbol: 'crWETH',
    underlyingAddress: '0x7fCDc2C1EF3e4A0bCC8155a558bB20a7218f2b05',
    underlyingDecimal: 18,
    underlyingName: 'Wrapped Ether',
    underlyingSymbol: 'WETH',
    category: Category.major,
    zeroAllowance: false,
  },
  {
    name: 'Cream Chainlink',
    address: '0xf976C9bc0E16B250E0B1523CffAa9E4c07Bc5C8a',
    symbol: 'crLINK',
    underlyingAddress: '0x1741B9C475e0861a43B03F984928082Ac4f3fB95',
    underlyingDecimal: 18,
    underlyingName: 'Chainlink',
    underlyingSymbol: 'LINK',
    category: Category.major,
    zeroAllowance: false,
  },
  {
    name: 'Cream Avalanche',
    address: '0x5b4058A9000e86fe136Ac896352C4DFD539E32a1',
    symbol: 'crAVAX',
    underlyingAddress: '0xd00ae08403B9bbb9124bB305C09058E32C39A48c',
    underlyingDecimal: 18,
    underlyingName: 'Avalanche',
    underlyingSymbol: 'AVAX',
    category: Category.major,
    zeroAllowance: false,
  },
];

export default FujiMarkets;
