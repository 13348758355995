import { BigNumber, ethers } from 'ethers';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import {
  commify,
  displayBalance,
  displayChange,
  getExpectedBorrowBalance,
  nativeBalanceToUsd,
  rateToApy,
  tokenNativePrice,
  tokenPrice
} from '../../cream/utils';
import useApprove, { ApproveState } from '../../hooks/useApprove';
import useCream from '../../hooks/useCream';
import useIsNativeContract from '../../hooks/useIsNativeContract';
import useMarketData from '../../hooks/useMarketData';
import useModal from '../../hooks/useModal';
import useUserBorrowSummary from '../../hooks/useUserBorrowSummary';
import { ConnectionContext } from '../../providers/ConnectionProvider';
import { ProtocolContext } from '../../providers/ProtocolProvider';
import { TxContext } from '../../providers/TxProvider';
import BalanceInput from '../BalanceInput';
import { FullWidthButton, NumberedButton } from '../Button/Button';
import ActionModal, { Action } from './components/ActionModal';
import ButtonGroup from './components/ButtonGroup';
import ModalInfo from './components/ModalInfo';
import WarningText from './components/WarningText';

interface BorrowRepayModalProps {
  marketAddress: string;
}

const BorrowRepayModal: React.FC<BorrowRepayModalProps> = ({ marketAddress }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const actions: [Action, Action] = [
    {
      title: t('Borrow'),
      component: <Borrow marketAddress={marketAddress} />,
      themeColor: theme.colors.creamPink,
    },
    {
      title: t('Repay'),
      component: <Repay marketAddress={marketAddress} />,
      themeColor: theme.colors.creamGreen,
    }
  ];

  return (
    <ActionModal bgColor={theme.colors.borrowCardBackground} actions={actions} marketAddress={marketAddress} />
  )
}

interface BorrowProps {
  marketAddress: string;
}

const Borrow: React.FC<BorrowProps> = ({ marketAddress }) => {
  const { creamPink } = useTheme().colors;
  const cream = useCream();
  const { t } = useTranslation();

  const [market, marketStats, userTokenStats] = useMarketData(marketAddress);
  const { protocol } = useContext(ProtocolContext);
  const isNative = useIsNativeContract(market.address);
  const userBorrowSummary = useUserBorrowSummary();
  const { connected } = useContext(ConnectionContext);
  const borrowLimitInUsd = nativeBalanceToUsd(userBorrowSummary.borrowLimitInNative, userBorrowSummary.basePrice, 2);

  const [amount, setAmount] = useState('0');
  const borrowAmount = ethers.utils.parseUnits(amount, market.underlyingDecimal);
  const newBorrowLimit = getExpectedBorrowBalance(userBorrowSummary, marketStats, borrowAmount, true);

  const { addTx } = useContext(TxContext);
  const { dismissModal } = useModal();

  const borrow = useCallback(async () => {
    const response = await cream?.borrow(market, borrowAmount, isNative);
    if (response) {
      addTx(response.hash, `Borrow ${commify(amount)} ${market.underlyingSymbol}`);
      dismissModal();
    }
  }, [borrowAmount, amount, market, cream, isNative, addTx, dismissModal]);

  let borrowPowerRemain = BigNumber.from(0);
  if (userBorrowSummary.borrowLimitInNative.gt(userBorrowSummary.totalBorrowBalanceInNative)) {
    borrowPowerRemain = userBorrowSummary.borrowLimitInNative.sub(userBorrowSummary.totalBorrowBalanceInNative);
  }
  const priceInNative = tokenNativePrice(marketStats.underlyingPrice, market.underlyingDecimal);
  const borrowPowerRemainInToken = borrowPowerRemain.mul(ethers.utils.parseUnits('1', market.underlyingDecimal)).div(priceInNative);

  let errorMessage = '';
  let borrowReady = true;
  if (borrowAmount.lte(0)) {
    if (borrowAmount.lt(0)) {
      // Invalid borrow amount.
      errorMessage = t('Invalid Amount');
    }
    borrowReady = false;
  } else if (newBorrowLimit.newBorrowBalanceInNative.gt(userBorrowSummary.borrowLimitInNative)) {
    // Borrow limit is fully used.
    errorMessage = t('Insufficient Collateral');
    borrowReady = false;
  } else if (newBorrowLimit.newBorrowBalanceInNative.gt(userBorrowSummary.borrowLimitInNative.mul(BigNumber.from(8)).div(BigNumber.from(10)))) {
    // Borrow limit is 80% used.
    errorMessage = t('Unsafe Borrow');
  } else if (marketStats.cash.lt(borrowAmount)) {
    // Insufficient cash.
    errorMessage = t('Insufficient Liquidity');
    borrowReady = false;
  } else if (marketStats.borrowCap.gt(0)) {
    const borrowQuota = marketStats.borrowCap.sub(marketStats.borrow);
    if (borrowQuota.lt(borrowAmount)) {
      // Borrow cap reached.
      errorMessage = t('Borrow Cap Exceeded');
      borrowReady = false;
    }
  }

  return (
    <>
      <BalanceInput title={t('BORROW AMOUNT')}
                    subtitle={t('Borrow Limit')}
                    maxBalance={borrowPowerRemainInToken}
                    symbol={market.underlyingSymbol}
                    decimals={market.underlyingDecimal}
                    usdRate={tokenPrice(marketStats.underlyingPrice, market.underlyingDecimal, userBorrowSummary.basePrice)}
                    themeColor={creamPink}
                    disabled={false}
                    onChange={setAmount}
                    errorMessage={errorMessage}
                    hideMax={true}
      />
      <ModalInfo title={t('BORROW STATS')} indent={false} infos={[
        {
          title: t('Borrow APY'),
          value: rateToApy(marketStats.borrowRate, protocol.blocksPerYear),
        },
        {
          title: t('Borrow Balance'),
          value: (
            <div>
              {displayBalance(userTokenStats.borrowBalance, market.underlyingDecimal, 6)} {market.underlyingSymbol}
            </div>
          ),
        },
      ]} />
      <ModalInfo title={t('BORROW LIMIT')} indent={true} infos={[
        {
          title: t('Your Borrow Limit'),
          value: borrowLimitInUsd,
        },
        {
          title: t('Borrow Limit Used'),
          value: displayChange(userBorrowSummary.borrowLimitPct, newBorrowLimit.newBorrowLimitPct),
        },
      ]} />
      {marketStats.borrowPaused ? (
        <WarningText className="fw-bold">Borrowing for {market.underlyingSymbol} is currently disabled.</WarningText>
      ) : (
        <StyledButtonGroup>
          <FullWidthButton onClick={() => borrow()}
                           bgColor={creamPink}
                           textColor="black"
                           disabled={!borrowReady || !connected}>
            {t('Borrow')}
          </FullWidthButton>
        </StyledButtonGroup>
      )}
    </>
  )
}

interface RepayProps {
  marketAddress: string;
}

const Repay: React.FC<RepayProps> = ({ marketAddress }) => {
  const [market, marketStats, userTokenStats] = useMarketData(marketAddress);

  const { creamGreen } = useTheme().colors;
  const { addTx } = useContext(TxContext);
  const { dismissModal } = useModal();
  const { t } = useTranslation();
  const { protocol } = useContext(ProtocolContext);
  const { connected, walletAddress } = useContext(ConnectionContext);
  const { approveState, approveAll, allowance } = useApprove(market);

  const cream = useCream();
  const isNative = useIsNativeContract(market.address);
  const userBorrowSummary = useUserBorrowSummary();
  const borrowLimitInUsd = nativeBalanceToUsd(userBorrowSummary.borrowLimitInNative, userBorrowSummary.basePrice, 2);

  const [amount, setAmount] = useState('0');
  const repayAmount = ethers.utils.parseUnits(amount, market.underlyingDecimal);
  const newBorrowLimit = getExpectedBorrowBalance(userBorrowSummary, marketStats, repayAmount, false);

  const insufficientAllowance = useMemo(() => {
    return allowance.eq('0') || allowance.lt(repayAmount)
  }, [allowance, repayAmount]);

  const repay = useCallback(async () => {
    let response;
    if (repayAmount.eq(userTokenStats.borrowBalance)) {
      // Repay full amount.
      if (isNative) {
        if (walletAddress) {
          // 1.001x of borrow balance should be sufficient for native token to repay full.
          const fullRepayAmount = userTokenStats.borrowBalance.mul(1001).div(1000);
          response = await cream?.repayNativeFull(walletAddress, fullRepayAmount);
        }
      } else {
        response = await cream?.repay(market, ethers.constants.MaxUint256, isNative);
      }
    } else {
      response = await cream?.repay(market, repayAmount, isNative);
    }
    if (response) {
      addTx(response.hash, `Repay ~${commify(amount)} ${market.underlyingSymbol}`);
      dismissModal();
    }
  }, [userTokenStats, cream, walletAddress, repayAmount, amount, isNative, market, addTx, dismissModal]);

  let errorMessage = '';
  let repayReady = true;
  if (repayAmount.lte(0)) {
    if (repayAmount.lt(0)) {
      // Invalid repay amount.
      errorMessage = t('Invalid Amount');
    }
    repayReady = false;
  } else if (userTokenStats.walletBalance.lt(repayAmount)) {
    // Wallet balance is less than repay amount.
    errorMessage = t('Insufficient Balance');
    repayReady = false;
  } else if (insufficientAllowance) {
    // Insufficient allowance.
    errorMessage = t('Insufficient Allowance');
    repayReady = false;
  } else if (userTokenStats.borrowBalance.lt(repayAmount)) {
    // Excessive Repayment.
    errorMessage = t('Excessive Repayment');
    repayReady = false;
  }

  return (
    <>
      <BalanceInput title={t('REPAY AMOUNT')}
                    subtitle={t('Borrow Balance')}
                    maxBalance={userTokenStats.borrowBalance}
                    symbol={market.underlyingSymbol}
                    decimals={market.underlyingDecimal}
                    usdRate={tokenPrice(marketStats.underlyingPrice, market.underlyingDecimal, userBorrowSummary.basePrice)}
                    themeColor={creamGreen}
                    disabled={approveState !== ApproveState.APPROVED || !connected}
                    onChange={setAmount}
                    onMaxSelected={() => {
                      const { borrowBalance, walletBalance } = userTokenStats;
                      if (walletBalance.lt(borrowBalance)) {
                        return walletBalance;
                      } else {
                        return borrowBalance;
                      }
                    }}
                    errorMessage={errorMessage}
      />
      <ModalInfo title={t('BORROW STATS')} indent={false} infos={[
        {
          title: t('Borrow APY'),
          value: rateToApy(marketStats.borrowRate, protocol.blocksPerYear),
        },
        {
          title: t('Borrow Balance'),
          value: (
            <div>
              {displayBalance(userTokenStats.borrowBalance, market.underlyingDecimal, 6)} {market.underlyingSymbol}
            </div>
          ),
        },
      ]} />
      <ModalInfo title={t('BORROW LIMIT')} indent={true} infos={[
        {
          title: t('Your Borrow Limit'),
          value: borrowLimitInUsd,
        },
        {
          title: t('Borrow Limit Used'),
          value: displayChange(userBorrowSummary.borrowLimitPct, newBorrowLimit.newBorrowLimitPct),
        },
      ]} />
      <StyledButtonGroup>
        {isNative ? (
          <FullWidthButton bgColor={creamGreen}
                           textColor="black"
                           disabled={approveState !== ApproveState.APPROVED || !repayReady || !connected}
                           onClick={() => repay()}>
            {t('Repay')}
          </FullWidthButton>
        ) : (
          <>
            <NumberedButton bgColor={creamGreen}
                            textColor="black"
                            disabled={!insufficientAllowance || !connected}
                            onClick={() => approveAll()}
                            number={1}
            >
              {t('Approve')}
            </NumberedButton>
            <NumberedButton bgColor={creamGreen}
                            textColor="black"
                            disabled={approveState !== ApproveState.APPROVED || !repayReady || !connected}
                            onClick={() => repay()}
                            number={2}
            >
              {t('Repay')}
            </NumberedButton>
          </>
        )}
      </StyledButtonGroup>
    </>
  )
}

const StyledButtonGroup = styled(ButtonGroup)`
  margin-top: 100px;
`

export default BorrowRepayModal;
