import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import useModal from '../../../hooks/useModal';
import { ConnectionContext } from '../../../providers/ConnectionProvider';
import { TxContext } from '../../../providers/TxProvider';
import { Button } from '../../Button/Button';
import Loader from '../../Loader';
import WalletModal from '../../Modal/WalletModal';
import { Flexbox } from '../../FlexBox';

const TxButton: React.FC = () => {
  const { txs } = useContext(TxContext);
  const { walletAddress, ensName, disconnectWallet } = useContext(ConnectionContext);
  const { presentModal, dismissModal } = useModal();
  const pendingTxs = txs.filter((t) => !t.confirmed);

  const shortWalletAddress = useMemo(() => {
    if (ensName) {
      return ensName;
    }
    if (!walletAddress) {
      return '';
    }
    return `${walletAddress.substring(0, 6)}...${walletAddress.substring(38)}`;
  }, [walletAddress, ensName]);

  const openModal = () => presentModal(
    <WalletModal address={walletAddress || ''}
                 ensName={ensName || ''}
                 disconnectWallet={disconnectWallet}
                 dismissModal={dismissModal} />
  )

  return (pendingTxs.length !== 0 ? (
    <Flexbox>
      <LeftButton onClick={openModal}>{shortWalletAddress}</LeftButton>
      <RightButton onClick={openModal}>
        <Loader />
        <PendingTxCount>{pendingTxs.length}</PendingTxCount>
      </RightButton>
    </Flexbox>
  ) : (
    <Button onClick={openModal}>{shortWalletAddress}</Button>
  ))
}

const LeftButton = styled(Button)`
  border-radius: 4px 0 0 4px;
`;

const RightButton = styled(Button)`
  display: flex;
  align-items: center;
  border-radius: 0 4px 4px 0;
  margin-left: 2px;
`;

const PendingTxCount = styled.div`
  color: ${(props) => props.theme.colors.creamYellow};
  margin-left: 8px;
`

export default TxButton;
