import styled from 'styled-components';
import React, { useContext } from 'react';
import { FlexboxVertical } from '../../../components/FlexBox';
import { Skeleton } from '@material-ui/lab';
import { MarketContext } from '../../../providers/MarketProvider';
interface HeaderProps {
  align: 'left' | 'right';
  title: string;
  value: string;
  valueColor?: string;
}

const Header: React.FC<HeaderProps> = ({
  align,
  title,
  value,
  valueColor,
}) => {
  const { isLoading } = useContext(MarketContext);
  return (
    <HeaderWrapper align={align}>
      <div>{title}</div>
      {isLoading ? (
        <Skeleton width={100} height={20} style={{ borderRadius: 8, marginTop: 10 }} />
      ) : (
        <HeaderValue color={valueColor || '#fff'}>{value}</HeaderValue>
      )}
    </HeaderWrapper>
  )
};

interface HeaderWrapperProps {
  align: string;
}

const HeaderWrapper = styled(FlexboxVertical)<HeaderWrapperProps>`
  align-items: flex-${(props) => (props.align === 'left' ? 'start' : 'end')};
  color: #E6E6E6;
  font-size: 14px;
  line-height: 17px;
`;

const HeaderValue = styled.div<{ color: string; }>`
  color: ${(props) => props.color};
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  margin-top: 6px;
`;

export default Header;
