import React from 'react';
import './i18n';
import './theme/table';
import { Provider } from 'react-redux'; // import for side-effect
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import ScrollToTop from './components/ScrollToTop';
import Lending from './pages/Lending';
import ConnectionProvider from './providers/ConnectionProvider';
import CreamProvider from './providers/CreamProvider';
import MarketProvider from './providers/MarketProvider';
import ProtocolProvider from './providers/ProtocolProvider';
import TxProvider from './providers/TxProvider';
import theme from './theme';
import ModalProvider from './components/Modal';
import Page from './components/Page';
import store from './store/store';

import './styles/IceCreamDatePicker.css';

function App() {
  return (
    <Providers>
      <BrowserRouter>
        <ScrollToTop />
        <Page>
          <Switch>
            <Route path="/" exact>
              <Lending />
            </Route>
            <Route path="/lending/:type" exact>
              <Lending />
            </Route>
          </Switch>
        </Page>
      </BrowserRouter>
    </Providers>
  );
}

const Providers: React.FC = ({ children }) => (
  <Provider store={store}>
    <ProtocolProvider>
      <ConnectionProvider>
        <TxProvider>
          <CreamProvider>
            <MarketProvider>
              <ThemeProvider theme={theme}>
                <ModalProvider>
                  {children}
                </ModalProvider>
              </ThemeProvider>
            </MarketProvider>
          </CreamProvider>
        </TxProvider>
      </ConnectionProvider>
    </ProtocolProvider>
  </Provider>
);

export default App;
