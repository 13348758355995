import { Contract } from 'ethers';
import { Signer } from "@ethersproject/abstract-signer";
import { Provider, TransactionResponse } from '@ethersproject/abstract-provider';

import { GAS_MULTIPLIER } from "./constants";
import LiquidityMiningABI from './ABIs/liquidityMining';

class LiquidityMining {
  private contract: Contract;

  address: string;

  constructor(address: string, provider: Provider | Signer) {
    this.contract = new Contract(address, LiquidityMiningABI, provider);
    this.address = address;
  }

  async claimAllRewards(account: string): Promise<TransactionResponse> {
    const gas = await this.contract.estimateGas.claimAllRewards(account);
    return this.contract.claimAllRewards(account, {gasLimit: gas.mul(GAS_MULTIPLIER)});
  }
}

export default LiquidityMining;
