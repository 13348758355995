import { transparentize } from 'polished';
import React, { useState } from 'react';
import styled from 'styled-components';
import useMarketData from '../../../hooks/useMarketData';
import useTokenIcon from '../../../hooks/useTokenIcon';
import media from '../../../media';

export type Action = {
  title: string;
  themeColor: string;
  component: React.ReactNode;
}

interface ActionModalProps {
  actions: [Action, Action];
  marketAddress: string;
  bgColor: string;
}

const ActionModal: React.FC<ActionModalProps> = ({ actions, bgColor, marketAddress }) => {
  const [action, setAction] = useState(0);
  const [market] = useMarketData(marketAddress);

  return (
    <ModalWrapper bgColor={bgColor}>
      <SymbolIcon symbol={market.underlyingSymbol} symbolColor={actions[action].themeColor} />
      <ActionWrapper bgColor={bgColor}>
        <ActionTitleWrapper>
          {actions.map((a, idx) => (
            <ActionTitle
              key={idx}
              active={action === idx}
              themeColor={a.themeColor}
              onClick={() => setAction(idx)}
            >
              {a.title}
            </ActionTitle>
          ))}
        </ActionTitleWrapper>
        <ActionComponentWrapper>
          {actions[action].component}
        </ActionComponentWrapper>
      </ActionWrapper>
    </ModalWrapper>
  )
}

const ModalWrapper = styled.div<{ bgColor: string; }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 335px;

  ${media.up('sm')} {
    min-width: 540px;
  }
`;

interface SymbolIconProps {
  symbol: string;
  symbolColor: string;
}

const SymbolIcon: React.FC<SymbolIconProps> = ({ symbol, symbolColor }) => {
  const icon = useTokenIcon(symbol);

  return (
    <SymbolIconWrapper>
      {icon ? (
        <Icon src={icon} alt={symbol} />
      ): (
        <MissingIcon>😐</MissingIcon>
      )}
      <Symbol bgColor={symbolColor}>{symbol}</Symbol>
    </SymbolIconWrapper>
  );
}

const SymbolIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 54px;
  width: 54px;
  position: relative;
  top: 22px;
  
  ${media.up('sm')} {
    height: 70px;
    width: 70px;
  }
`

const Icon = styled.img`
  width: 54px;
  height: 54px;
  
  ${media.up('sm')} {
    height: 70px;
    width: 70px;
  }
`

const MissingIcon = styled.div`
  font-size: 54px;
  
  ${media.up('sm')} {
    font-size: 70px;
  }
`

const Symbol = styled.div<{ bgColor: string }>`
  position: relative;
  top: -8px;
  background-color: ${(props) => props.bgColor};
  padding: 6px 13px;
  color: black;
  border-radius: 4px;
  white-space: nowrap;
  font-weight: 500;
  
  ${media.up('sm')} {
    padding: 8px 18px;
  }
`

const ActionWrapper = styled.div<{ bgColor: string; }>`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.bgColor};
  border-radius: 4px;
  width: 100%;
`

const ActionTitleWrapper = styled.div`
  display: flex;
`

const ActionTitle = styled.div<{ active: boolean, themeColor: string }>`
  flex: 0 0 50%;
  font-size: ${(props) => props.theme.textSize.big};
  font-weight: 500;
  letter-spacing: ${(props) => props.theme.letterSpacing.tight};
  color: ${(props) => props.active ? props.themeColor : transparentize(0.5, 'white')};
  cursor: pointer;
  transition: all 0.2s ease-out;
  border-bottom: 1px solid ${(props) => props.active ? props.themeColor : transparentize(0.5, 'white')};
  
  ${media.up('sm')} {
    font-size: ${(props) => props.theme.textSize.large};
    text-align: center !important;
    padding: ${(props) => props.theme.spacing[4]} !important;
  }
  
  &:hover {
    color: ${(props) => props.themeColor};
    border-bottom: 1px solid ${(props) => props.themeColor};
    transition: all 0s;
  }
  
  &:first-child {
    text-align: left;
    padding: 20px 0 20px 28px;
  }
  
  &:last-child {
    text-align: right;
    padding: 20px 28px 20px 0;
  }
`

const ActionComponentWrapper = styled.div`
  padding: 20px;
  
  ${media.up('sm')} {
    padding: 60px;
  }
`

export default ActionModal;
