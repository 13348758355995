import { Provider, TransactionResponse } from '@ethersproject/abstract-provider';
import { Signer } from '@ethersproject/abstract-signer';
import axios from 'axios'
import BN from 'bignumber.js';
import { BigNumber, ethers } from 'ethers';
import { Configuration } from './Configuration';
import CompoundLens from './contract/CompoundLens';
import Comptroller from './contract/Comptroller';
import {
  BnbUsdPriceEndpoint,
  CreamUsdPriceEndpoint,
  EthUsdPriceEndpoint,
  MaticUsdPriceEndpoint,
} from './constants';
import AbstractCToken from './contract/AbstractCToken';
import CErc20 from './contract/CErc20';
import CEther from './contract/CEther';
import CreamETH2 from './contract/CreamETH2';
import CreamETH2Oracle from './contract/CreamETH2Oracle';
import Erc20 from './contract/Erc20';
import IceCream from './contract/IceCream';
import IceCreamFeeDistributor from './contract/IceCreamFeeDistributor';
import LiquidityMining from './contract/LiquidityMining';
import LiquidityMiningLens from './contract/LiquidityMiningLens';
import LongTermPool from './contract/LongTermPool';
import Maximillion from './contract/Maximillion';
import MultiCall from './contract/MultiCall';
import PriceOracle from './contract/PriceOracle';
import { BscProtocol, EthereumProtocol} from './Protocols';
import {
  CreamETH2Stats,
  IceCreamReward,
  LMRewardStats,
  LongTermPoolStats,
  Market,
  MarketStats,
  RewardSpeedInfo,
  UnclaimLMReward,
  UserLiquidityRewards,
  UserLongTermPoolStats,
  UserTokenStats
} from './Type';

export class Cream {
  config: Configuration;
  comptroller: Comptroller;
  lens: CompoundLens;
  multiCall: MultiCall;
  provider: Provider | Signer;

  constructor(config: Configuration, provider: Provider | Signer) {
    this.provider = provider;
    this.config = config;
    this.comptroller = new Comptroller(config.comptrollerAddress, provider);
    this.lens = new CompoundLens(config.lensAddress, provider);
    this.multiCall = new MultiCall(config.multiCallAddress, provider);
  }

  connect(signer: Signer) {
    this.provider = signer;
    this.comptroller.connect(signer);
  }

  async getBasePrice(): Promise<number> {
    return this.config.protocol.getBasePrice();
  }

  async getLMRewardTokenPrice(symbol: string): Promise<number> {
    let endpoint;
    switch (symbol.toLowerCase()) {
      case 'bnb':
        endpoint = BnbUsdPriceEndpoint;
        break;
      case 'cream':
        endpoint = CreamUsdPriceEndpoint;
        break;
      case 'matic':
        endpoint = MaticUsdPriceEndpoint;
        break;
      default:
        return 0;
    }

    const { data } = await axios.get(endpoint);
    for (const symbol in data) {
      return Number(data[symbol].usd);
    }
    return 0;
  }

  /**
   * Lens functions
   */

  async getUserStats(address: string, markets: Market[]): Promise<UserTokenStats[]> {
    const cTokenAddresses = markets.map(market => market.address);

    const cTokenBalancesAll = await this.lens.cTokenBalancesAll(cTokenAddresses, address);

    const userTokenStats = cTokenBalancesAll.map(balance => ({
      address: balance.cToken,
      walletBalance: balance.tokenBalance,
      crTokenBalance: balance.balanceOf,
      underlyingBalance: balance.balanceOfUnderlying,
      borrowBalance: balance.borrowBalanceCurrent,
      nativeTokenBalance: balance.nativeTokenBalance,
      collateralEnabled: balance.collateralEnabled,
      collateralBalance: balance.collateralBalance
    }));
    return userTokenStats;
  }

  async getMarketStats(markets: Market[]): Promise<MarketStats[]> {
    const cTokenAddresses = markets.map(market => market.address);

    const cTokenMetadataAll = await this.lens.cTokenMetadataAll(cTokenAddresses);

    const marketStats = cTokenMetadataAll.map<MarketStats>((metadata) => ({
      supply: metadata.totalSupply,
      borrow: metadata.totalBorrows,
      cash: metadata.totalCash,
      reserves: metadata.totalReserves,
      address: metadata.cToken,
      exchangeRate: metadata.exchangeRateCurrent,
      supplyRate: metadata.supplyRatePerBlock,
      borrowRate: metadata.borrowRatePerBlock,
      collateralFactor: metadata.collateralFactorMantissa,
      supplyPaused: metadata.supplyPaused,
      borrowPaused: metadata.borrowPaused,
      underlyingPrice: metadata.underlyingPrice,
      supplyCap: metadata.supplyCap,
      borrowCap: metadata.borrowCap,
      collateralCap: metadata.collateralCap,
      totalCollateralTokens: metadata.totalCollateralTokens,
      version: metadata.version
    }));

    return marketStats;
  }

  async getUserLiquidityRewards(account: string, markets: Market[]): Promise<UserLiquidityRewards[]> {
    const cTokenAddresses = markets.map(market => market.address);
    if (this.config.protocol === EthereumProtocol) {
      const sushiRewards = await this.lens.getClaimableSushiRewards(cTokenAddresses, this.config.protocol.sushiAddress, account);
      return cTokenAddresses.map<UserLiquidityRewards>((cTokenAddress, index) => ({
        address: cTokenAddress,
        reward: sushiRewards[index]
      }));
    } else if (this.config.protocol === BscProtocol) {
      const cakeRewards = await this.lens.getClaimableCakeRewards(cTokenAddresses, this.config.protocol.pancakeAddress, account);
      return cTokenAddresses.map<UserLiquidityRewards>((cTokenAddress, index) => ({
        address: cTokenAddress,
        reward: cakeRewards[index]
      }));
    } else {
      return [];
    }
  }

  /**
   * CErc20 functions
   */

  async supply(market: Market, amount: BigNumber, isNative: Boolean): Promise<TransactionResponse> {
    let cTokenInterface: AbstractCToken;

    if (isNative) {
      cTokenInterface = new CEther(market.address, this.provider);
    } else {
      cTokenInterface = new CErc20(market.address, this.provider);
    }
    return cTokenInterface.mint(amount);
  }

  async redeem(market: Market, amount: BigNumber, isNative: Boolean): Promise<TransactionResponse> {
    let cTokenInterface: AbstractCToken;

    if (isNative) {
      cTokenInterface = new CEther(market.address, this.provider);
    } else {
      cTokenInterface = new CErc20(market.address, this.provider);
    }

    return cTokenInterface.redeem(amount);
  }

  async redeemUnderlying(market: Market, underlyingTokenAmount: BigNumber, isNative: Boolean): Promise<TransactionResponse> {
    let cTokenInterface: AbstractCToken;

    if (isNative) {
      cTokenInterface = new CEther(market.address, this.provider);
    } else {
      cTokenInterface = new CErc20(market.address, this.provider);
    }
    return cTokenInterface.redeemUnderlying(underlyingTokenAmount);
  }

  async borrow(market: Market, amount: BigNumber, isNative: Boolean): Promise<TransactionResponse> {
    let cTokenInterface: AbstractCToken;

    if (isNative) {
      cTokenInterface = new CEther(market.address, this.provider);
    } else {
      cTokenInterface = new CErc20(market.address, this.provider);
    }
    return cTokenInterface.borrow(amount);
  }

  async repayNativeFull(borrower: string, amount: BigNumber): Promise<TransactionResponse> {
    // Maximillion could handle repay full amount of native token.
    const maximillion = new Maximillion(this.config.protocol.maximillionAddress, this.provider);
    return maximillion.repayBehalf(borrower, amount);
  }

  async repay(market: Market, amount: BigNumber, isNative: Boolean): Promise<TransactionResponse> {
    let cTokenInterface: AbstractCToken;

    if (isNative) {
      cTokenInterface = new CEther(market.address, this.provider);
    } else {
      cTokenInterface = new CErc20(market.address, this.provider);
    }
    return cTokenInterface.repayBorrow(amount);
  }

  async claimLiquidityRewards(market: Market, account: string): Promise<TransactionResponse> {
    const cTokenInterface = new CErc20(market.address, this.provider);
    if (this.config.protocol === EthereumProtocol) {
      return cTokenInterface.claimSushi(account);
    } else if (this.config.protocol === BscProtocol) {
      return cTokenInterface.claimCake(account);
    } else {
      throw new Error('Invalid network');
    }
  }

  /**
   * Comptroller functions
   */

  async enableCollateral(marketAddress: string): Promise<TransactionResponse> {
    return this.comptroller.enterMarkets([marketAddress]);
  };

  async disableCollateral(marketAddress: string): Promise<TransactionResponse> {
    return this.comptroller.exitMarket(marketAddress);
  };

  /**
   * Long-term pools functions
   */

  async getLongTermPoolStats(): Promise<LongTermPoolStats[]> {
    const pools = this.config.protocol.longTermPools;
    return Promise.all(pools.map((pool) => this.getSinglePoolStats(pool.address, pool.years)));
  }

  async getUserLongTermPoolStat(account: string, poolAddress: string): Promise<UserLongTermPoolStats> {
    return this.getUserSingleLongTermPoolStats(poolAddress, account);
  }

  async getUserLongTermPoolStats(account: string): Promise<UserLongTermPoolStats[]> {
    const pools = this.config.protocol.longTermPools;
    return Promise.all(pools.map((pool) => this.getUserSingleLongTermPoolStats(pool.address, account)));
  }

  async stakeToLongTermPool(poolAddress: string, amount: BigNumber): Promise<TransactionResponse> {
    const pool = new LongTermPool(poolAddress, this.provider);
    return pool.stake(amount);
  }

  async exitFromLongTermPool(poolAddress: string): Promise<TransactionResponse> {
    const pool = new LongTermPool(poolAddress, this.provider);
    return pool.exit();
  }

  /**
   * CreamETH2 functions
   */

  async getCreamETH2Stats(address: string): Promise<CreamETH2Stats> {
    const crETH2 = new CreamETH2(address, this.provider);
    const oracleAddress = await crETH2.oracle();
    const oracle = new CreamETH2Oracle(oracleAddress, this.provider);
    const [
      totalSupply,
      accumulated,
      exchangeRate
    ] = await Promise.all([
      crETH2.totalSupply(),
      crETH2.accumulated(),
      oracle.exchangeRate(),
    ]);
    return {
      totalSupply,
      accumulated,
      exchangeRate
    };
  }

  async getUserCreamETH2Balance(address: string, account: string): Promise<BigNumber> {
    const crETH2 = new CreamETH2(address, this.provider);
    return await crETH2.balanceOf(account);
  }

  /**
   * LiquidityMining functions
   */

  async getLMRewardsStats(markets: Market[]): Promise<LMRewardStats[]> {
    const cTokenAddresses = markets.map(market => market.address);
    const liquidityMiningLensAddress = this.config.protocol.liquidityMiningLensAddress;
    if (liquidityMiningLensAddress.length === 0) {
      return markets.map<LMRewardStats>((market) => ({
        cToken: market.address,
        rewardSpeeds: []
      }));
    }

    const liquidityMiningLens = new LiquidityMiningLens(liquidityMiningLensAddress, this.provider);
    const allMarketRewardsSpeeds = await liquidityMiningLens.getAllMarketRewardSpeeds(cTokenAddresses);
    if (allMarketRewardsSpeeds.length === 0) {
      return markets.map<LMRewardStats>((market) => ({
        cToken: market.address,
        rewardSpeeds: []
      }));
    }
    const rewardTokens = allMarketRewardsSpeeds[0].rewardSpeeds.map(speed => speed.rewardToken);
    const prices = await Promise.all(rewardTokens.map(token => this.getLMRewardTokenPrice(token.rewardTokenSymbol)));

    const lmRewardStats = new Array<LMRewardStats>(markets.length);
    for (let i = 0; i < markets.length; i++) {
      const rewardSpeeds = new Array<RewardSpeedInfo>(rewardTokens.length);
      for (let j = 0; j < rewardTokens.length; j++) {
        rewardSpeeds[j] = {
          rewardToken: {
            rewardTokenAddress: rewardTokens[j].rewardTokenAddress,
            rewardTokenSymbol: rewardTokens[j].rewardTokenSymbol,
            rewardTokenDecimals: rewardTokens[j].rewardTokenDecimals,
            rewardTokenUSDPrice: prices[j]
          },
          supplySpeed: allMarketRewardsSpeeds[i].rewardSpeeds[j].supplySpeed,
          borrowSpeed: allMarketRewardsSpeeds[i].rewardSpeeds[j].borrowSpeed
        };
      }
      lmRewardStats[i] = {
        rewardSpeeds
      };
    }
    return lmRewardStats;
  }

  async getUserUnclaimLMRewards(account: string): Promise<UnclaimLMReward[]> {
    const liquidityMiningLensAddress = this.config.protocol.liquidityMiningLensAddress;
    if (liquidityMiningLensAddress.length === 0) {
      return [];
    }
    const liquidityMiningLens = new LiquidityMiningLens(liquidityMiningLensAddress, this.provider);
    const availableRewards = await liquidityMiningLens.getRewardsAvailable(account);
    const prices = await Promise.all(availableRewards.map(reward => this.getLMRewardTokenPrice(reward.rewardToken.rewardTokenSymbol)));

    const unclaimRewards = availableRewards.map<UnclaimLMReward>((reward, index) => ({
      rewardToken: {
        rewardTokenAddress: reward.rewardToken.rewardTokenAddress,
        rewardTokenSymbol: reward.rewardToken.rewardTokenSymbol,
        rewardTokenDecimals: reward.rewardToken.rewardTokenDecimals,
        rewardTokenUSDPrice: prices[index]
      },
      amount: reward.amount
    }));

    return unclaimRewards;
  }

  async claimLMRewards(address: string): Promise<TransactionResponse> {
    const liquidityMiningAddress = this.config.protocol.liquidityMiningAddress;
    if (liquidityMiningAddress.length === 0) {
      throw new Error('Empty address');
    }

    const liquidityMining = new LiquidityMining(liquidityMiningAddress, this.provider);
    return liquidityMining.claimAllRewards(address);
  }

  /**
   * iceCream functions
   */

  async iceCreamStake(amount: BigNumber, timestamp: number): Promise<TransactionResponse> {
    const iceCream = new IceCream(this.config.protocol, this.provider);
    return iceCream.stake(amount, BigNumber.from(timestamp));
  }

  async iceCreamUnstake(): Promise<TransactionResponse> {
    const iceCream = new IceCream(this.config.protocol, this.provider);
    return iceCream.withdraw();
  }

  async iceCreamIncreaseUnlockTime(unlockTime: number): Promise<TransactionResponse> {
    const iceCream = new IceCream(this.config.protocol, this.provider);
    return iceCream.increaseUnlockTime(BigNumber.from(unlockTime));
  }

  async iceCreamIncreaseAmount(amount: BigNumber): Promise<TransactionResponse> {
    const iceCream = new IceCream(this.config.protocol, this.provider);
    return iceCream.increaseAmount(amount);
  }

  async iceCreamClaim(feeDistributor: string, address: string): Promise<TransactionResponse> {
    const contract = new IceCreamFeeDistributor(feeDistributor, this.provider);
    return contract.claim(address);
  }

  async iceCreamClaimable(address: string): Promise<IceCreamReward[]> {
    if (this.config.protocol !== EthereumProtocol) {
      return [];
    }

    const feeDistributors = [
      {
        address: '0x0Ca0f068edad122f09a39f99E7E89E705d6f6Ace',
        decimals: 18,
        symbol: 'yvCurve-IronBank',
      },
    ];
    return await Promise.all(feeDistributors.map(async (fd) => {
      const contract = new IceCreamFeeDistributor(fd.address, this.provider);

      const usdPrice = await this.getIceCreamClaimablePrice(fd.symbol);
      const claimable = await contract.claimable(address);
      const usdValue = new BN(ethers.utils.formatUnits(claimable, fd.decimals)).multipliedBy(usdPrice).toNumber();

      return {
        usdValue,
        address: fd.address,
        symbol: fd.symbol,
        decimals: fd.decimals,
        claimable: claimable,
      }
    }));
  }

  private async getIceCreamClaimablePrice(symbol: string): Promise<number> {
    if (this.config.protocol !== EthereumProtocol) {
      return 0;
    }

    const priceOracleAddress = '0x647A539282e8456A64DFE28923B7999b66091488';
    switch (symbol) {
      case 'yvCurve-IronBank':
        const oracle = new PriceOracle(priceOracleAddress, this.provider);
        const price = await oracle.getUnderlyingPrice('0x45406ba53bB84Cd32A58e7098a2D4D1b11B107F6');
        const ethPriceData = await axios.get(EthUsdPriceEndpoint);
        const ethPrice = ethPriceData.data.ethereum.usd;
        return new BN(price.toString()).div(1e18).multipliedBy(ethPrice).toNumber();
      default:
        return 0;
    }
  }

  /**
   * Private functions
   */

  private async getSinglePoolStats(poolAddress: string, duration: number): Promise<LongTermPoolStats> {
    const pool = new LongTermPool(poolAddress, this.provider);
    const [
      lpTokenAddress,
      endDate,
      rewardRate,
      totalSupplyInPool
    ] = await Promise.all([
      pool.lpToken(),
      pool.releaseTime(),
      pool.rewardRate(),
      pool.totalSupply()
    ]);
    return {
      poolAddress,
      lpTokenAddress,
      endDate,
      rewardRate,
      totalSupplyInPool,
      lockPeriod: duration,
    };
  }

  private async getUserSingleLongTermPoolStats(poolAddress: string, account: string): Promise<UserLongTermPoolStats> {
    const pool = new LongTermPool(poolAddress, this.provider);
    const lpTokenAddress = await pool.lpToken();
    const lpToken = new Erc20(lpTokenAddress, this.provider);
    const [
      allowance,
      walletBalance,
      lpDecimals,
      stakedBalance,
      earnedBalance
    ] = await Promise.all([
      lpToken.allowance(account, poolAddress),
      lpToken.balanceOf(account),
      lpToken.decimals(),
      pool.balanceOf(account),
      pool.earned(account)
    ]);
    return {
      poolAddress,
      allowance,
      walletBalance,
      lpDecimals,
      stakedBalance,
      earnedBalance
    };
  }
}

export default Cream;
