import { Contract } from 'ethers';
import { Signer } from "@ethersproject/abstract-signer";
import { Provider } from '@ethersproject/abstract-provider';

import LiquidityMiningLensABI from './ABIs/liquidityMiningLens';
import {
  RewardAvailable,
  RewardTokenInfo,
  MarketRewardSpeed
} from './types';

class CompoundLens {
  private contract: Contract;

  address: string;

  constructor(address: string, provider: Provider | Signer) {
    this.contract = new Contract(address, LiquidityMiningLensABI, provider);
    this.address = address;
  }

  getRewardsAvailable(address: string): Promise<RewardAvailable[]> {
    return this.contract.callStatic.getRewardsAvailable(address);
  }

  getRewardTokenInfo(rewardAddress: string): Promise<RewardTokenInfo> {
    return this.contract.callStatic.getRewardTokenInfo(rewardAddress);
  }

  getAllMarketRewardSpeeds(cTokens: string[]): Promise<MarketRewardSpeed[]> {
    return this.contract.callStatic.getAllMarketRewardSpeeds(cTokens);
  }
}

export default CompoundLens;
