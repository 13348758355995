import React from 'react';
import styled from 'styled-components';
import media from '../../../media';

interface TitledModalProps {
  title: string;
  titleColor?: string;
}

const TitledModal: React.FC<TitledModalProps> = ({ title, titleColor, children }) => {
  return (
    <ModalWrapper>
      <Header color={titleColor}>{title}</Header>
      <Divider/>
      <Body>
        {children}
      </Body>
    </ModalWrapper>
  )
}

const ModalWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.supplyCardBackground};
  border-radius: 8px;
`;

const Header = styled.div<{color: string | undefined}>`
  padding: 16px 20px;
  color: ${(props) => props.color || props.theme.colors.creamGreen};
  font-weight: 500;
  font-size: ${(props) => props.theme.textSize.large};
  
  ${media.up('sm')} {
    padding: 30px 60px;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${(props) => props.theme.colors.white};
  opacity: 0.2;
`;

const Body = styled.div`
  padding: 16px 20px;
  font-weight: 500;
  
  ${media.up('sm')} {
    padding: 30px 60px;
  }
`;
export default TitledModal;
