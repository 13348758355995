import { ethers, BigNumber } from 'ethers';
import BN from 'bignumber.js';
import { UserBorrowSummary, UserTokenStats, MarketStats, BorrowLimit, RewardSpeedInfo } from './Type';
import { secondsPerYear } from './constants';

export function commify(n: string | number) {
  const parts = n.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

export function uncommify(n: string) {
  return n.replace(/,/g, '');
}

export function rateToApy(rate: BigNumber, blocksPerYear: number, digits = 2): string {
  const bn = BN.clone({ POW_PRECISION: 6 });
  const apy = new bn(
    ethers.utils.formatEther(rate).toString()
  )
    .plus(1)
    .pow(blocksPerYear)
    .minus(1)
    .multipliedBy(100)
    .toFixed(digits);
  return apy + '%'
}

export function distributionApy(rewardSpeeds: RewardSpeedInfo[], denominator: BN, supply: boolean, digits = 2): string {
  if (denominator.eq(0)) {
    return Number(0).toFixed(digits) + '%';
  }

  let totalRewardsInUSD = new BN(0);
  const currentTime = Math.floor(Date.now() / 1000);
  for (const rewardSpeed of rewardSpeeds) {
    const speedInfo = supply ? rewardSpeed.supplySpeed : rewardSpeed.borrowSpeed;
    const start = speedInfo.start.toNumber();
    const end = speedInfo.end.toNumber();
    const speed = speedInfo.speed;
    const tokenPrice = rewardSpeed.rewardToken.rewardTokenUSDPrice;
    const decimals = rewardSpeed.rewardToken.rewardTokenDecimals;
    if (currentTime >= start && currentTime <= end) {
      totalRewardsInUSD = totalRewardsInUSD.plus(new BN(speed.toString()).multipliedBy(tokenPrice).multipliedBy(secondsPerYear).div(ethers.utils.parseUnits('1', decimals).toString()));
    }
  }
  const apy = totalRewardsInUSD.dividedBy(denominator).multipliedBy(100).toFixed(digits);
  return apy + '%';
}

export function totalBorrowsInUsd(totalBorrows: BigNumber, underlyingPrice: BigNumber, underlyingDecimal: number, basePrice: number): BN {
  const price = tokenPrice(underlyingPrice, underlyingDecimal, basePrice);
  return new BN(totalBorrows.toString()).multipliedBy(price).div(ethers.utils.parseUnits('1', underlyingDecimal).toString());
}

export function totalSupplyInUsd(totalSupply: BigNumber, underlyingPrice: BigNumber, exchangeRate: BigNumber, basePrice: number): BN {
  const supplyInWei = totalSupply.mul(exchangeRate).div(ethers.constants.WeiPerEther).mul(underlyingPrice).div(ethers.constants.WeiPerEther);
  return new BN(supplyInWei.toString()).multipliedBy(basePrice).div(ethers.constants.WeiPerEther.toString());
}

export function usdValue(amount: BigNumber, usdPrice: number, decimals: number): number {
  return new BN(amount.toString()).multipliedBy(usdPrice).div(ethers.utils.parseUnits('1', decimals).toString()).toNumber();
}

export function displayBalance(balance: BigNumber, tokenDecimals: number, digits: number, _commify = true): string {
  if (balance.eq(0)) {
    return '0';
  }

  const b = new BN(ethers.utils.formatUnits(balance, tokenDecimals)).toFixed(digits);
  return _commify ? commify(b) : b;
}

export function underlyingBalance(crTokenBalance: BigNumber, exchangeRate: BigNumber): BigNumber {
  return crTokenBalance.mul(exchangeRate).div(ethers.constants.WeiPerEther);
}

export function oracleNativeBalance(underlyingBalance: BigNumber, underlyingPrice: BigNumber): BigNumber {
  return underlyingBalance.mul(underlyingPrice).div(ethers.constants.WeiPerEther);
}

export function tokenNativePrice(underlyingPrice: BigNumber, underlyingDecimal: number): BigNumber {
  return underlyingPrice.mul(ethers.utils.parseUnits('1', underlyingDecimal)).div(ethers.constants.WeiPerEther);
}

export function tokenPrice(underlyingPrice: BigNumber, underlyingDecimal: number, basePrice: number): number {
  const priceInNative = tokenNativePrice(underlyingPrice, underlyingDecimal);
  const priceInUSD = new BN(priceInNative.toString()).multipliedBy(basePrice).div(ethers.constants.WeiPerEther.toString());
  return priceInUSD.toNumber();
}

export function displayFactor(f: BigNumber, digits: number = 2): string {
  const pct = new BN(ethers.utils.formatUnits(f, 18)).multipliedBy(100).toFixed(digits)
  return pct + '%';
}

export function displayRewards(f: BigNumber, token: string, digits: number = 4): string {
  const balance = new BN(ethers.utils.formatUnits(f, 18)).toFixed(digits);
  return balance + ' ' + token.toUpperCase();
}

export function toDigits(n: string, digits: number): string {
  return new BN(n).toFixed(digits);
}

export function sameAddress(a: string, b: string): boolean {
  return a.toLowerCase() === b.toLowerCase();
}

export function nativeBalanceToUsd(balance: BigNumber, priceInUsd: number, digits: number): string {
  const usdAmount = new BN(
    ethers.utils.formatEther(balance).toString()
  )
    .multipliedBy(priceInUsd)
    .toFixed(digits);
  return '$' + commify(usdAmount);
}

export function calculateCollateral(supplyBalance: BigNumber, collateralFactor: BigNumber): BigNumber {
  return supplyBalance.mul(collateralFactor).div(ethers.utils.parseUnits('1', '18'));
}

export function borrowLimitPercentage(borrowLimit: BigNumber, borrowBalance: BigNumber): string {
  if (borrowLimit.eq(0)) {
    return '0%'
  }
  const pct = new BN(borrowBalance.toString())
    .div(borrowLimit.toString())
    .multipliedBy(100)
    .toFixed(2)
  ;
  return pct + '%';
}

export function getNetRate(totalSupplyBalanceInNative: BigNumber, totalRateInNative: BigNumber): BigNumber {
  if (totalSupplyBalanceInNative.eq(BigNumber.from(0))) {
    return BigNumber.from(0)
  }
  return totalRateInNative.div(totalSupplyBalanceInNative);
}

export function displayChange(a: string, b: string): string {
  return a + ' -> ' + b;
}

export function getExpectedBorrowLimit(userBorrowSummary: UserBorrowSummary, marketStats: MarketStats, userTokenStats: UserTokenStats, amount: BigNumber, increase: boolean): BorrowLimit {
  if (!userTokenStats.collateralEnabled) {
    return {
      newBorrowLimitInNative: userBorrowSummary.borrowLimitInNative,
      newBorrowLimitInUsd: nativeBalanceToUsd(userBorrowSummary.borrowLimitInNative, userBorrowSummary.basePrice, 2),
      newBorrowBalanceInNative: userBorrowSummary.totalBorrowBalanceInNative,
      newBorrowBalanceInUsd: nativeBalanceToUsd(userBorrowSummary.totalBorrowBalanceInNative, userBorrowSummary.basePrice, 2),
      newBorrowLimitPct: userBorrowSummary.borrowLimitPct
    }
  } else {
    let changeAmount = BigNumber.from(0);
    if (marketStats.version === 1 && marketStats.collateralCap.gt(0)) {
      if (increase) {
        // Calculate the market remaining collateral buffer.
        const marketIncreaseLimit = underlyingBalance(
          marketStats.collateralCap.sub(marketStats.totalCollateralTokens),
          marketStats.exchangeRate
        );
        // The max collateral increase amount is equal to the market remaining collateral buffer.
        if (marketIncreaseLimit.gt(0) && marketIncreaseLimit.lt(amount)) {
          changeAmount = marketIncreaseLimit;
        }
      } else {
        // Calculate the user non-collateral balance.
        const userNonCollateralBalance = underlyingBalance(
          userTokenStats.crTokenBalance.sub(userTokenStats.collateralBalance),
          marketStats.exchangeRate
        );
        // Withdrawal will consume the non-collateral balance first.
        if (userNonCollateralBalance.lt(amount)) {
          changeAmount = amount.sub(userNonCollateralBalance);
        }
      }
    } else {
      // No collateral cap feature on the market.
      changeAmount = amount;
    }

    const nativeBalance = oracleNativeBalance(changeAmount, marketStats.underlyingPrice);
    let borrowLimitChange = userBorrowSummary.borrowLimitInNative;
    if (increase) {
      borrowLimitChange = borrowLimitChange.add(calculateCollateral(nativeBalance, marketStats.collateralFactor));
    } else {
      borrowLimitChange = borrowLimitChange.sub(calculateCollateral(nativeBalance, marketStats.collateralFactor));
    }
    const borrowLimitChangeInUsd = nativeBalanceToUsd(borrowLimitChange, userBorrowSummary.basePrice, 2);
    const borrowBalanceChangeInUsd = nativeBalanceToUsd(userBorrowSummary.totalBorrowBalanceInNative, userBorrowSummary.basePrice, 2);
    const borrowLimitPctChange = borrowLimitPercentage(borrowLimitChange, userBorrowSummary.totalBorrowBalanceInNative);
    return {
      newBorrowLimitInNative: borrowLimitChange,
      newBorrowLimitInUsd: borrowLimitChangeInUsd,
      newBorrowBalanceInNative: userBorrowSummary.totalBorrowBalanceInNative,
      newBorrowBalanceInUsd: borrowBalanceChangeInUsd,
      newBorrowLimitPct: borrowLimitPctChange
    }
  }
}

export function getExpectedBorrowBalance(userBorrowSummary: UserBorrowSummary, marketStats: MarketStats, amount: BigNumber, increase: boolean): BorrowLimit {
  const nativeBalance = oracleNativeBalance(amount, marketStats.underlyingPrice);
  let borrowBalanceChange = userBorrowSummary.totalBorrowBalanceInNative;
  if (increase) {
    borrowBalanceChange = borrowBalanceChange.add(nativeBalance);
  } else {
    borrowBalanceChange = borrowBalanceChange.sub(nativeBalance);
  }
  const borrowLimitChangeInUsd = nativeBalanceToUsd(userBorrowSummary.borrowLimitInNative, userBorrowSummary.basePrice, 2);
  const borrowBalanceChangeInUsd = nativeBalanceToUsd(borrowBalanceChange, userBorrowSummary.basePrice, 2);
  const borrowLimitPctChange = borrowLimitPercentage(userBorrowSummary.borrowLimitInNative, borrowBalanceChange);
  return {
    newBorrowLimitInNative: userBorrowSummary.borrowLimitInNative,
    newBorrowLimitInUsd: borrowLimitChangeInUsd,
    newBorrowBalanceInNative: borrowBalanceChange,
    newBorrowBalanceInUsd: borrowBalanceChangeInUsd,
    newBorrowLimitPct: borrowLimitPctChange
  }
}
