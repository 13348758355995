import React from 'react';
import styled from 'styled-components';
import media from '../../../media';
import SectionHeader from './SectionHeader';

type Info = {
  title: React.ReactNode;
  value: React.ReactNode;
}

interface ModalInfoProps {
  title: React.ReactNode;
  infos: Info[];
  indent?: boolean;
  noMarginTop?: boolean;
}

const ModalInfo: React.FC<ModalInfoProps> = ({ title, indent, infos, noMarginTop }) => {
  return (
    <Wrapper noMarginTop={!!noMarginTop}>
      <SectionHeader title={title} />
      {infos.map((info, idx) => (
        <InfoWrapper key={idx} indent={indent}>
          <InfoTitle indent={indent}>{info.title}</InfoTitle>
          <InfoValue indent={indent}>{info.value}</InfoValue>
        </InfoWrapper>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div<{ noMarginTop: boolean; }>`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => props.noMarginTop ? '0' : '42px'};
`

const InfoWrapper = styled.div<{ indent?: boolean; }>`
  display: flex;
  margin-left: ${(props) => props.indent ? '26px' : '0'};
  font-weight: 500;
  margin-top: 10px;
  color: ${(props) => props.theme.colors.white};

  ${media.up('sm')} {
    margin-left: ${(props) => props.indent ? '30px' : '0'};
  }
`

const InfoTitle = styled.div<{ indent?: boolean; }>`
  margin: auto;
  flex-basis: ${(props) => props.indent ? '50%' : '40%'};
`

const InfoValue = styled.div<{ indent?: boolean; }>`
  margin: auto;
  flex-grow: 1;
  text-align: right;
  flex-basis: ${(props) => props.indent ? '50%' : '60%'};
`

export default ModalInfo;
