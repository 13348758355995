import { BigNumber, Contract } from 'ethers';
import { Signer } from "@ethersproject/abstract-signer";
import { Provider, TransactionResponse } from '@ethersproject/abstract-provider';

import MaximillionABI from './ABIs/maximillion';
import { GAS_MULTIPLIER } from "./constants";

class Maximillion {
  private contract: Contract;

  address: string;

  constructor(address: string, provider: Provider | Signer) {
    this.contract = new Contract(address, MaximillionABI, provider);
    this.address = address;
  }

  async repayBehalf(borrower: string, repayAmount: BigNumber): Promise<TransactionResponse> {
    const gas = await this.contract.estimateGas.repayBehalf(borrower, {value: repayAmount});
    return this.contract.repayBehalf(
      borrower,
      {
        gasLimit: gas.mul(GAS_MULTIPLIER),
        value: repayAmount,
      }
    );
  }
}

export default Maximillion;
