/* eslint-disable camelcase */
import oneInch from '../assets/img/token/1inch.png';
import aave from '../assets/img/token/aave.svg';
import ada from '../assets/img/token/ada.svg';
import akro from '../assets/img/token/akro.png';
import alpha from '../assets/img/token/alpha.png';
import amp from '../assets/img/token/amp.png';
import armor from '../assets/img/token/armor.png';
import arnxm from '../assets/img/token/arnxm.png';
import atom from '../assets/img/token/atom.svg';
import auto from '../assets/img/token/auto.png';
import axs from '../assets/img/token/axs.png';
import bac from '../assets/img/token/bac.jpg';
import badger from '../assets/img/token/badger.png';
import bal from '../assets/img/token/bal.png';
import band from '../assets/img/token/band.png';
import bat from '../assets/img/token/bat.png';
import bch from '../assets/img/token/bch.svg';
import beth from '../assets/img/token/beth.svg';
import bnb from '../assets/img/token/bnb.svg';
import bnt from '../assets/img/token/bnt.png';
import bond from '../assets/img/token/bond.png';
import btc from '../assets/img/token/btc.svg';
import busd from '../assets/img/token/busd.svg';
import cake from '../assets/img/token/cake.png';
import cake_lp_busd_bnb from '../assets/img/token/cake-lp-busd-bnb.svg';
import cake_lp_btcb_bnb from '../assets/img/token/cake-lp-btcb-bnb.svg';
import cake_lp_cake_bnb from '../assets/img/token/cake-lp-cake-bnb.svg';
import cake_lp_eth_bnb from '../assets/img/token/cake-lp-eth-bnb.svg';
import cake_lp_usdt_busd from '../assets/img/token/cake-lp-usdt-busd.svg';
import cdai from '../assets/img/token/cdai.png';
import cel from '../assets/img/token/cel.png';
import comp from '../assets/img/token/comp.png';
import cover from '../assets/img/token/cover.png';
import crbal from '../assets/img/token/crbal.png';
import crbch from '../assets/img/token/crbch.png';
import crbnb from '../assets/img/token/crbnb.png';
import crbtc from '../assets/img/token/crbtc.png';
import crbusd from '../assets/img/token/crbusd.png';
import crchainlink from '../assets/img/token/crchainlink.png';
import crcomp from '../assets/img/token/crcomp.png';
import crcream from '../assets/img/token/crcream.png';
import crcrv from '../assets/img/token/crcrv.png';
import crdot from '../assets/img/token/crdot.png';
import cream from '../assets/img/token/cream.svg';
import creth from '../assets/img/token/creth.png';
import creth2 from '../assets/img/token/creth2.png';
import crftx from '../assets/img/token/crftx.png';
import crlend from '../assets/img/token/crlend.png';
import crltc from '../assets/img/token/crltc.png';
import crmta from '../assets/img/token/crmta.png';
import crrenbtc from '../assets/img/token/crrenbtc.png';
import crsrm from '../assets/img/token/crsrm.png';
import crsushi from '../assets/img/token/crsushi.png';
import cruni from '../assets/img/token/cruni.png';
import crusdc from '../assets/img/token/crusdc.png';
import crusdt from '../assets/img/token/crusdt.png';
import crv from '../assets/img/token/crv.png';
import crxrp from '../assets/img/token/crxrp.png';
import crycrv from '../assets/img/token/crycrv.png';
import cryeth from '../assets/img/token/cryeth.png';
import cryfi from '../assets/img/token/cryfi.png';
import cryusd from '../assets/img/token/cryusd.png';
import cryycrv from '../assets/img/token/cryycrv.png';
import cusdc from '../assets/img/token/cusdc.png';
import cusdt from '../assets/img/token/cusdt.png';
import cyusd from '../assets/img/token/cyusd.png';
import dai from '../assets/img/token/dai.svg';
import dot from '../assets/img/token/dot.svg';
import dpi from '../assets/img/token/dpi.png';
import dusd from '../assets/img/token/dusd.svg';
import eos from '../assets/img/token/eos.png';
import esd from '../assets/img/token/esd.png';
import eth from '../assets/img/token/eth.png';
import eurs from '../assets/img/token/eurs.png';
import eurt from '../assets/img/token/eurt.svg';
import farm from '../assets/img/token/farm.png';
import fei from '../assets/img/token/fei.png';
import fil from '../assets/img/token/fil.svg';
import frax from '../assets/img/token/frax.svg';
import ftm from '../assets/img/token/ftm.svg';
import ftt from '../assets/img/token/ftt.png';
import fusdt from '../assets/img/token/fusdt.svg';
import fxs from '../assets/img/token/fxs.png';
import gno from '../assets/img/token/gno.png';
import gusd from '../assets/img/token/gusd.png';
import hbtc from '../assets/img/token/hbtc.svg';
import hegic from '../assets/img/token/hegic.png';
import hfil from '../assets/img/token/hfil.svg';
import husd from '../assets/img/token/husd.svg';
import ibaud from '../assets/img/token/ibaud.png';
import ibbtc from '../assets/img/token/ibbtc.png';
import ibchf from '../assets/img/token/ibchf.png';
import ibeur from '../assets/img/token/ibeur.png';
import ibgbp from '../assets/img/token/ibgbp.png';
import ibjpy from '../assets/img/token/ibjpy.png';
import ibkrw from '../assets/img/token/ibkrw.png';
import iotx from '../assets/img/token/iotx.svg';
import kp3r from '../assets/img/token/kp3r.png';
import lend from '../assets/img/token/lend.png';
import link from '../assets/img/token/link.svg';
import lon from '../assets/img/token/lon.png';
import ltc from '../assets/img/token/ltc.svg';
import mana from '../assets/img/token/mana.png';
import matic from '../assets/img/token/matic.svg';
import mim from '../assets/img/token/mim.png';
import mln from '../assets/img/token/mln.png';
import mta from '../assets/img/token/mta.png';
import musd from '../assets/img/token/musd.png';
import ocean from '../assets/img/token/ocean.png';
import ogn from '../assets/img/token/ogn.png';
import omg from '../assets/img/token/omg.svg';
import pax from '../assets/img/token/pax.png';
import paxg from '../assets/img/token/paxg.png';
import perp from '../assets/img/token/perp.svg';
import pickle from '../assets/img/token/pickle.png';
import quick from '../assets/img/token/quick.png';
import rai from '../assets/img/token/rai.png';
import rari from '../assets/img/token/rari.png';
import renbtc from '../assets/img/token/renbtc.png';
import renzec from '../assets/img/token/renzec.png';
import rune from '../assets/img/token/rune.svg';
import sand from '../assets/img/token/sand.png';
import seur from '../assets/img/token/seur.svg';
import sfi from '../assets/img/token/sfi.svg';
import slp_dai_eth from '../assets/img/token/slp-dai-eth.svg';
import slp_eth_usdt from '../assets/img/token/slp-eth-usdt.svg';
import slp_sushi_eth from '../assets/img/token/slp-sushi-eth.svg';
import slp_usdc_eth from '../assets/img/token/slp-usdc-eth.svg';
import slp_wbtc_eth from '../assets/img/token/slp-wbtc-eth.svg';
import slp_yfi_eth from '../assets/img/token/slp-yfi-eth.svg';
import snx from '../assets/img/token/snx.png';
import spice from '../assets/img/token/spice.svg';
import srm from '../assets/img/token/srm.png';
import susd from '../assets/img/token/susd.svg';
import sushi from '../assets/img/token/sushi.svg';
import swag from '../assets/img/token/swag.svg';
import swap from '../assets/img/token/swap.png';
import sxp from '../assets/img/token/sxp.svg';
import tbtc from '../assets/img/token/tbtc.png';
import twt from '../assets/img/token/twt.png';
import uni from '../assets/img/token/uni.png';
import uni_v2_dai_eth from '../assets/img/token/uni-v2-dai-eth.svg';
import uni_v2_eth_usdt from '../assets/img/token/uni-v2-eth-usdt.svg';
import uni_v2_usdc_eth from '../assets/img/token/uni-v2-usdc-eth.svg';
import uni_v2_wbtc_eth from '../assets/img/token/uni-v2-wbtc-eth.svg';
import usdc from '../assets/img/token/usdc.png';
import usdp from '../assets/img/token/usdp.svg';
import usdt from '../assets/img/token/usdt.png';
import ust from '../assets/img/token/ust.png';
import vai from '../assets/img/token/vai.png';
import vsp from '../assets/img/token/vsp.svg';
import wavax from '../assets/img/token/wavax.png';
import wbtc from '../assets/img/token/wbtc.png';
import weth from '../assets/img/token/weth.png';
import wnxm from '../assets/img/token/wnxm.png';
import woo from '../assets/img/token/woo.png';
import xrp from '../assets/img/token/xrp.svg';
import xsushi from '../assets/img/token/xsushi.svg';
import xtz from '../assets/img/token/xtz.svg';
import xvs from '../assets/img/token/xvs.png';
import y3crv from '../assets/img/token/y3crv.svg';
import ycrv from '../assets/img/token/ycrv.png';
import yeth from '../assets/img/token/yeth.png';
import yfi from '../assets/img/token/yfi.png';
import ygg from '../assets/img/token/ygg.svg';
import yusd from '../assets/img/token/yusd.png';
import yycrv from '../assets/img/token/yycrv.png';
import yvweth from '../assets/img/token/yvWETH.svg';
import yvcurve_ib from '../assets/img/token/yvCurve-IB.svg';
import yvecrv from '../assets/img/token/yveCRV.svg';
import yvstecrv from '../assets/img/token/yvsteCRV.svg';

const tokenIcon: { [symbol: string]: any} = {
  aave,
  ada,
  akro,
  alpha,
  amp,
  armor,
  arnxm,
  atom,
  auto,
  axs,
  bac,
  badger,
  bal,
  band,
  bat,
  bch,
  beth,
  bnb,
  bnt,
  bond,
  btc,
  busd,
  cake,
  cdai,
  cel,
  comp,
  cover,
  crbal,
  crbch,
  crbnb,
  crbtc,
  crbusd,
  crchainlink,
  crcomp,
  crcream,
  crcrv,
  crdot,
  cream,
  creth,
  creth2,
  crftx,
  crlend,
  crltc,
  crmta,
  crrenbtc,
  crsrm,
  crsushi,
  cruni,
  crusdc,
  crusdt,
  crv,
  crxrp,
  crycrv,
  cryeth,
  cryfi,
  cryusd,
  cryycrv,
  cusdc,
  cusdt,
  cyusd,
  dai,
  dot,
  dpi,
  dusd,
  eos,
  esd,
  eth,
  eurs,
  eurt,
  ftm,
  farm,
  fei,
  fil,
  frax,
  ftt,
  fusdt,
  fxs,
  gno,
  gusd,
  hbtc,
  hegic,
  hfil,
  husd,
  ibaud,
  ibbtc,
  ibchf,
  ibeur,
  ibgbp,
  ibjpy,
  ibkrw,
  iotx,
  kp3r,
  lend,
  link,
  lon,
  ltc,
  mana,
  matic,
  mim,
  mln,
  mta,
  musd,
  ocean,
  ogn,
  omg,
  pax,
  paxg,
  pickle,
  perp,
  quick,
  rai,
  rari,
  renbtc,
  renzec,
  rune,
  sand,
  seur,
  sfi,
  snx,
  spice,
  srm,
  susd,
  sushi,
  swag,
  swap,
  sxp,
  tbtc,
  twt,
  uni,
  usdc,
  usdp,
  usdt,
  ust,
  vai,
  vsp,
  wavax,
  wbtc,
  weth,
  wnxm,
  woo,
  xrp,
  xsushi,
  xtz,
  xvs,
  y3crv,
  ycrv,
  yeth,
  yfi,
  ygg,
  yusd,
  yvweth,
  yycrv,
  '1inch': oneInch,
  'bbadger': badger,
  'bbtc': btc,
  'btcb': btc,
  'cake-lp-busd-bnb v1': cake_lp_busd_bnb,
  'cake-lp-busd-bnb v2': cake_lp_busd_bnb,
  'cake-lp-btcb-bnb v1': cake_lp_btcb_bnb,
  'cake-lp-btcb-bnb v2': cake_lp_btcb_bnb,
  'cake-lp-cake-bnb v1': cake_lp_cake_bnb,
  'cake-lp-cake-bnb v2': cake_lp_cake_bnb,
  'cake-lp-eth-bnb v1': cake_lp_eth_bnb,
  'cake-lp-eth-bnb v2': cake_lp_eth_bnb,
  'cake-lp-usdt-busd v1': cake_lp_usdt_busd,
  'cake-lp-usdt-busd v2': cake_lp_usdt_busd,
  'dai.e': dai,
  'link.e': link,
  'slp-dai-eth': slp_dai_eth,
  'slp-eth-usdt': slp_eth_usdt,
  'slp-sushi-eth': slp_sushi_eth,
  'slp-usdc-eth': slp_usdc_eth,
  'slp-wbtc-eth': slp_wbtc_eth,
  'slp-yfi-eth': slp_yfi_eth,
  'uni-v2-dai-eth': uni_v2_dai_eth,
  'uni-v2-eth-usdt': uni_v2_eth_usdt,
  'uni-v2-usdc-eth': uni_v2_usdc_eth,
  'uni-v2-wbtc-eth': uni_v2_wbtc_eth,
  'usdc.e': usdc,
  'usdt.e': usdt,
  'vvsp': vsp,
  'wbnb': bnb,
  'wbtc.e': btc,
  'weth.e': weth,
  'wftm': ftm,
  'wmatic': matic,
  'yvcurve-ironbank': yvcurve_ib,
  'yvcurve-seth': yvecrv,
  'yvcurve-steth': yvstecrv,
};

const useTokenIcon = (symbol: string) => {
  const symbolLower = symbol.toLowerCase();
  return tokenIcon[symbolLower];
};

export default useTokenIcon;
