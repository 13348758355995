import styled from 'styled-components';
import React, { useContext } from 'react';
import { Flexbox } from '../../../components/FlexBox';
import MarketCard from './MarketCard';
import { Skeleton } from '@material-ui/lab';
import { MarketContext } from '../../../providers/MarketProvider';
import media from '../../../media';

interface UserMarketsProps {
  leftHeader: React.ReactNode;
  rightHeader: React.ReactNode;
  table: React.ReactNode;
  tableBackgroundColor: string;
}

const UserMarket: React.FC<UserMarketsProps> = ({ leftHeader, rightHeader, table, tableBackgroundColor }) => {
  const { isLoading } = useContext(MarketContext);
  return (
    <MarketCard backgroundColor={"none"}>
      <HeadersWrapper>
        {leftHeader}
        {rightHeader}
      </HeadersWrapper>
      <TableWrapper backgroundColor={tableBackgroundColor} >
        {isLoading ? (
          <div style={{ padding: '16px' }}>
            <Skeleton width="50%" height={20} style={{ borderRadius: 8, marginTop: '0px' }}/>
            <Skeleton width="80%" height={20} style={{ borderRadius: 8, marginTop: '16px' }}/>
            <Skeleton width="60%" height={20} style={{ borderRadius: 8, marginTop: '16px' }}/>
            <Skeleton width="90%" height={20} style={{ borderRadius: 8, marginTop: '16px' }}/>
          </div>
        ) : (
          table
        )}
      </TableWrapper>
    </MarketCard>
  )
};

const HeadersWrapper = styled(Flexbox)`
  justify-content: space-between;
  padding: 0 20px;
  
  ${media.down('sm')} {
    padding: 0;
    margin: 0 -16px;
  }
`;

const TableWrapper = styled.div<{backgroundColor: string}>`
  margin: 26px -16px 0 -16px;
  border-radius: 8px;
  background: ${(props) => props.backgroundColor}
`;

export default UserMarket;
