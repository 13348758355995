import React from 'react';
import styled from 'styled-components';

interface SectionHeaderProps {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
}

const SectionHeader: React.FC<SectionHeaderProps> = ({ title, subtitle }) => {
  return (
    <Wrapper>
      <Title>{title}</Title>
      {subtitle && (
        <Subtitle>
          {subtitle}
        </Subtitle>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  margin-bottom: 12px;
`;

const Title = styled.div`
  opacity: 0.5;
  letter-spacing: ${(props) => props.theme.letterSpacing.small};
  font-size: ${(props) => props.theme.textSize.small};
  font-weight: 500;
  text-transform: uppercase;
`

const Subtitle = styled.div`
  flex-grow: 1;
  text-align: right;
`;

export default SectionHeader;
