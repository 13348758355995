import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BN from 'bignumber.js';
import { BigNumber, ethers } from 'ethers';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { toDigits } from '../../cream/utils';
import { MediaType } from '../../media';
import { Flexbox } from '../FlexBox'
import SectionHeader from '../Modal/components/SectionHeader';
import Slider from '../Slider';

const ascentColor = '#10111e';

interface BalanceInputProps {
  title: string;
  subtitle: string;
  maxBalance: BigNumber;
  symbol: string;
  decimals: number;
  usdRate: number;
  themeColor: string;
  disabled: boolean;
  onChange: (value: string) => void,
  onMaxSelected?: () => BigNumber;
  errorMessage?: string;
  hideMax?: boolean;
  hideUsdValue?: boolean;
}

const BalanceInput: React.FC<BalanceInputProps> = ({
  title,
  subtitle,
  maxBalance,
  symbol,
  decimals,
  themeColor,
  disabled,
  usdRate,
  onChange,
  onMaxSelected,
  errorMessage,
  hideMax,
  hideUsdValue,
}) => {
  const [inputAmount, setInputAmount] = useState('');
  const inputRef = useRef<any>(null);

  const displayMaxBalance = toDigits(ethers.utils.formatUnits(maxBalance, decimals), 2);

  const usdValue = useMemo(() => {
    if (!inputAmount || usdRate === 0 || inputAmount === '0') {
      return '$0'
    }
    const usd = new BN(inputAmount).multipliedBy(usdRate).toFixed(2)
    return `~$${usd}`
  }, [inputAmount, usdRate])

  const pctValue = useMemo(() => {
    if (inputAmount === '') {
      return 0;
    } else {
      const max = Number(ethers.utils.formatUnits(maxBalance, decimals));
      const input = Number(inputAmount);
      return input > max ? 100 : input / max;
    }
  }, [inputAmount, decimals, maxBalance])

  const toMaxValue = useCallback(() => {
    let max;
    if (onMaxSelected) {
      max = ethers.utils.formatUnits(onMaxSelected(), decimals);
    } else {
      max = ethers.utils.formatUnits(maxBalance, decimals);
    }
    setInputAmount(max);
    onChange(max);
  }, [onMaxSelected, onChange, decimals, maxBalance])

  const isSm = useMediaQuery({ maxWidth: MediaType.sm })

  const inputFontSize = useMemo(() => {
    if (!isSm) {
      return 'inherit';
    }

    const { length } = inputAmount;
    if (length > 20) {
      return '10px';
    } else if (length > 16) {
      return '13px'
    } else if (length > 14) {
      return '16px';
    }
    return 'inherit';
  }, [inputAmount, isSm])

  return (
    <Wrapper>
      <SectionHeader
        title={title}
        subtitle={(
          <>{subtitle}: <Balance themeColor={themeColor}>{displayMaxBalance} {symbol}</Balance></>
        )}
      />
      <Body onClick={() => inputRef.current.focus()} className={errorMessage ? 'error' : ''}>
        <BodyContent>
          <BodyInputWrapper>
            <InputWrapper>
              {disabled ? (
                <Input ref={inputRef} disabled />
              ) : (
                <Input type="number" value={inputAmount} className={errorMessage ? 'error' : ''} ref={inputRef}
                       fontSize={inputFontSize}
                       onChange={
                         (event) => {
                           setInputAmount(event.target.value);
                           onChange(event.target.value || '0'); // prevent empty string
                         }
                       } />
              )}
              <span>{symbol}</span>
            </InputWrapper>
            {!hideUsdValue && (
              <div>{usdValue}</div>
            )}
          </BodyInputWrapper>
          {!hideMax && !disabled && (
            <BodyMaxButton onClick={toMaxValue}>Max</BodyMaxButton>
          )}
        </BodyContent>
        <ErrorMessage style={{ display: errorMessage ? 'initial' : 'none' }}>
          <FontAwesomeIcon icon={faExclamationTriangle} color="black" /> {/*todo: this icon is not as same as designed*/}
          {errorMessage}
        </ErrorMessage>
      </Body>
      {!disabled && (
        <Tail>
          <Slider
            pctValue={pctValue}
            onChange={(pct) => {
              const amount = ethers.utils.formatUnits(maxBalance.mul(pct).div(100), decimals);
              setInputAmount(amount);
              onChange(amount);
            }}
            theme={themeColor}
          />
        </Tail>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Balance = styled.span<{ themeColor: string }>`
  color: ${(props) => props.themeColor};
`;

const Body = styled.div<{ error?: boolean }>`
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.colors.white};

  &.error {
    padding: 2px;
    border-radius: 4px;
    background-color: ${(props) => props.theme.colors.warning};
    color: ${(props) => props.theme.colors.warning} !important;
  }
`

const BodyContent = styled.div`
  display: flex;
  align-items: center;
  border-radius: 4px;
  background-color: ${ascentColor};
`;

const BodyInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 15px 18px 15px 18px;
  text-align: right;
  cursor: text;
`;

const BodyMaxButton = styled.button`
  background: none;
  outline: none;
  border: 0;
  color: white;
  opacity: 50%;
  padding: 0 18px 0 0;
`;

const ErrorMessage = styled.div`
  color: black;
  text-align: center;
  font-weight: 500;
  padding: 10px;
`;

const InputWrapper = styled(Flexbox)<{ error?: boolean }>`
  font-size: 18px;
  font-weight: 500;

  &.error {
    border-radius: 2px;
    background-color: ${(props) => props.theme.colors.warning};
  }
`;

interface InputProps {
  fontSize?: string;
}

const Input = styled.input<InputProps>`
  text-align: right;
  outline: none;
  background: none;
  border: 0;
  color: ${(props) => props.theme.colors.white};
  caret-color: ${(props) => props.theme.colors.white};
  flex-grow: 1;
  margin-right: 3px;
  width: 100%;
  font-size: ${(props) => props.fontSize};

  &.error {
    color: ${(props) => props.theme.colors.warning} !important;
  }
`

const Tail = styled.div`
  margin: 12px 4px 50px 4px;
`

export default BalanceInput;
