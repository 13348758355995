import styled, { keyframes } from 'styled-components';

interface LoaderProps {
  dimension?: string;
  bgColor?: string;
  fgColor?: string;
  width?: string;
}

const rotate = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`

const Loader = styled.div<LoaderProps>`
  border: ${(props) => props.width || '2px'} solid ${(props) => props.bgColor || props.theme.colors.creamYellow};
  border-top: ${(props) => props.width || '2px'} solid ${(props) => props.fgColor || 'transparent'};
  border-radius: 50%;
  width: ${(props) => props.dimension || '16px'};
  height: ${(props) => props.dimension || '16px'};
  animation: ${rotate} 2s linear infinite;
  flex-shrink: 0;
`

export default Loader;
