import { Category } from '../Category';
import { Market } from '../Type';

const IronBankMarkets: Market[] = [
    {
      name: 'Yearn Wrapped Ether',
      address: '0x41c84c0e2EE0b740Cf0d31F63f3B6F627DC6b393',
      symbol: 'cyWETH',
      underlyingAddress: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
      underlyingDecimal: 18,
      underlyingName: 'Wrapped Ether',
      underlyingSymbol: 'WETH',
      category: Category.major,
      zeroAllowance: false,
    },
    {
      name: 'Yearn Dai Stablecoin',
      address: '0x8e595470Ed749b85C6F7669de83EAe304C2ec68F',
      symbol: 'cyDAI',
      underlyingAddress: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
      underlyingDecimal: 18,
      underlyingName: 'Dai Stablecoin',
      underlyingSymbol: 'DAI',
      category: Category.stable,
      zeroAllowance: false,
    },
    {
      name: 'Yearn Y3CRV',
      address: '0x7589C9E17BCFcE1Ccaa1f921196FDa177F0207Fc',
      symbol: 'cyY3CRV',
      underlyingAddress: '0x9cA85572E6A3EbF24dEDd195623F188735A5179f',
      underlyingDecimal: 18,
      underlyingName: 'yearn Curve.fi DAI/USDC/USDT',
      underlyingSymbol: 'y3Crv',
      category: Category.legacy,
      zeroAllowance: false,
    },
    {
      name: 'Yearn ChainLink Token',
      address: '0xE7BFf2Da8A2f619c2586FB83938Fa56CE803aA16',
      symbol: 'cyLINK',
      underlyingAddress: '0x514910771AF9Ca656af840dff83E8264EcF986CA',
      underlyingDecimal: 18,
      underlyingName: 'ChainLink Token',
      underlyingSymbol: 'LINK',
      category: Category.gov,
      zeroAllowance: false,
    },
    {
      name: 'Yearn yearn.finance',
      address: '0xFa3472f7319477c9bFEcdD66E4B948569E7621b9',
      symbol: 'cyYFI',
      underlyingAddress: '0x0bc529c00C6401aEF6D220BE8C6Ea1667F6Ad93e',
      underlyingDecimal: 18,
      underlyingName: 'yearn.finance',
      underlyingSymbol: 'YFI',
      category: Category.gov,
      zeroAllowance: false,
    },
    {
      name: 'Yearn Synthetix Network Token',
      address: '0x12A9cC33A980DAa74E00cc2d1A0E74C57A93d12C',
      symbol: 'cySNX',
      underlyingAddress: '0xC011a73ee8576Fb46F5E1c5751cA3B9Fe0af2a6F',
      underlyingDecimal: 18,
      underlyingName: 'Synthetix Network Token',
      underlyingSymbol: 'SNX',
      category: Category.gov,
      zeroAllowance: false,
    },
    {
      name: 'Yearn Wrapped BTC',
      address: '0x8Fc8BFD80d6A9F17Fb98A373023d72531792B431',
      symbol: 'cyWBTC',
      underlyingAddress: '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
      underlyingDecimal: 8,
      underlyingName: 'Wrapped BTC',
      underlyingSymbol: 'WBTC',
      category: Category.major,
      zeroAllowance: false,
    },
    {
      name: 'Yearn Tether USD',
      address: '0x48759F220ED983dB51fA7A8C0D2AAb8f3ce4166a',
      symbol: 'cyUSDT',
      underlyingAddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
      underlyingDecimal: 6,
      underlyingName: 'Tether USD',
      underlyingSymbol: 'USDT',
      category: Category.stable,
      zeroAllowance: false,
    },
    {
      name: 'Yearn USD Coin',
      address: '0x76Eb2FE28b36B3ee97F3Adae0C69606eeDB2A37c',
      symbol: 'cyUSDC',
      underlyingAddress: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
      underlyingDecimal: 6,
      underlyingName: 'USD Coin',
      underlyingSymbol: 'USDC',
      category: Category.stable,
      zeroAllowance: false,
    },
    {
      name: 'Yearn Synth sUSD',
      address: '0x4e3a36A633f63aee0aB57b5054EC78867CB3C0b8',
      symbol: 'cySUSD',
      underlyingAddress: '0x57Ab1ec28D129707052df4dF418D58a2D46d5f51',
      underlyingDecimal: 18,
      underlyingName: 'Synth sUSD',
      underlyingSymbol: 'sUSD',
      category: Category.stable,
      zeroAllowance: false,
    },
    {
      name: 'Yearn mStable USD',
      address: '0xBE86e8918DFc7d3Cb10d295fc220F941A1470C5c',
      symbol: 'cyMUSD',
      underlyingAddress: '0xe2f2a5C287993345a840Db3B0845fbC70f5935a5',
      underlyingDecimal: 18,
      underlyingName: 'mStable USD',
      underlyingSymbol: 'mUSD',
      category: Category.stable,
      zeroAllowance: false,
    },
    {
      name: 'Yearn DefiDollar',
      address: '0x297d4Da727fbC629252845E96538FC46167e453A',
      symbol: 'cyDUSD',
      underlyingAddress: '0x5BC25f649fc4e26069dDF4cF4010F9f706c23831',
      underlyingDecimal: 18,
      underlyingName: 'DefiDollar',
      underlyingSymbol: 'DUSD',
      category: Category.legacy,
      zeroAllowance: false,
    },
    {
      name: 'Yearn STASIS EURS Token',
      address: '0xA8caeA564811af0e92b1E044f3eDd18Fa9a73E4F',
      symbol: 'cyEURS',
      underlyingAddress: '0xdB25f211AB05b1c97D595516F45794528a807ad8',
      underlyingDecimal: 2,
      underlyingName: 'STASIS EURS Token',
      underlyingSymbol: 'EURS',
      category: Category.stable,
      zeroAllowance: false,
    },
    {
      name: 'Yearn Synth sEUR',
      address: '0xCA55F9C4E77f7B8524178583b0f7c798De17fD54',
      symbol: 'cySEUR',
      underlyingAddress: '0xD71eCFF9342A5Ced620049e616c5035F1dB98620',
      underlyingDecimal: 18,
      underlyingName: 'Synth sEUR',
      underlyingSymbol: 'sEUR',
      category: Category.stable,
      zeroAllowance: false,
    },
    {
      name: 'Yearn DefiPulse Index',
      address: '0x7736Ffb07104c0C400Bb0CC9A7C228452A732992',
      symbol: 'cyDPI',
      underlyingAddress: '0x1494CA1F11D487c2bBe4543E90080AeBa4BA3C2b',
      underlyingDecimal: 18,
      underlyingName: 'DefiPulse Index',
      underlyingSymbol: 'DPI',
      category: Category.stable,
      zeroAllowance: false,
    },
    {
      name: 'Yearn Binance USD',
      address: '0x09bDCCe2593f0BEF0991188c25Fb744897B6572d',
      symbol: 'cyBUSD',
      underlyingAddress: '0x4Fabb145d64652a948d72533023f6E7A623C7C53',
      underlyingDecimal: 18,
      underlyingName: 'Binance USD',
      underlyingSymbol: 'BUSD',
      category: Category.legacy,
      zeroAllowance: false,
    },
    {
      name: 'Yearn Compound Dai',
      address: '0x4F12c9DABB5319A252463E6028CA833f1164d045',
      symbol: 'cyCDAI',
      underlyingAddress: '0x5d3a536E4D6DbD6114cc1Ead35777bAB948E3643',
      underlyingDecimal: 8,
      underlyingName: 'Compound Dai',
      underlyingSymbol: 'cDAI',
      category: Category.legacy,
      zeroAllowance: false,
    },
    {
      name: 'Yearn Compound USDT',
      address: '0xBB4B067cc612494914A902217CB6078aB4728E36',
      symbol: 'cyCUSDT',
      underlyingAddress: '0xf650C3d88D12dB855b8bf7D11Be6C55A4e07dCC9',
      underlyingDecimal: 8,
      underlyingName: 'Compound USDT',
      underlyingSymbol: 'cUSDT',
      category: Category.legacy,
      zeroAllowance: false,
    },
    {
      name: 'Yearn Compound USD Coin',
      address: '0x950027632FbD6aDAdFe82644BfB64647642B6C09',
      symbol: 'cyCUSDC',
      underlyingAddress: '0x39AA39c021dfbaE8faC545936693aC917d5E7563',
      underlyingDecimal: 8,
      underlyingName: 'Compound USD Coin',
      underlyingSymbol: 'cUSDC',
      category: Category.legacy,
      zeroAllowance: false,
    },
    {
      name: 'Yearn Synth sUSD',
      address: '0xa7c4054AFD3DbBbF5bFe80f41862b89ea05c9806',
      symbol: 'cySUSD',
      underlyingAddress: '0x57Ab1ec28D129707052df4dF418D58a2D46d5f51',
      underlyingDecimal: 18,
      underlyingName: 'Synth sUSD',
      underlyingSymbol: 'sUSD',
      category: Category.stable,
      zeroAllowance: false,
    },
    {
      name: 'Yearn USDP Stablecoin',
      address: '0xBdDEB563E90F6cBF168a7cDa4927806477e5B6c6',
      symbol: 'cyUSDP',
      underlyingAddress: '0x1456688345527bE1f37E9e627DA0837D6f08C925',
      underlyingDecimal: 18,
      underlyingName: 'USDP Stablecoin',
      underlyingSymbol: 'USDP',
      category: Category.stable,
      zeroAllowance: false,
    },
    {
      name: 'Yearn Uniswap',
      address: '0xFEEB92386A055E2eF7C2B598c872a4047a7dB59F',
      symbol: 'cyUNI',
      underlyingAddress: '0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984',
      underlyingDecimal: 18,
      underlyingName: 'Uniswap',
      underlyingSymbol: 'UNI',
      category: Category.gov,
      zeroAllowance: false,
    },
    {
      name: 'Yearn SushiToken',
      address: '0x226F3738238932BA0dB2319a8117D9555446102f',
      symbol: 'cySUSHI',
      underlyingAddress: '0x6B3595068778DD592e39A122f4f5a5cF09C90fE2',
      underlyingDecimal: 18,
      underlyingName: 'SushiToken',
      underlyingSymbol: 'SUSHI',
      category: Category.gov,
      zeroAllowance: false,
    },
  ]
;

export default IronBankMarkets;
