import { Category } from '../Category';
import { Market } from '../Type';

const GoerliMarkets: Market[] = [
  {
    name: 'Cream Ether',
    address: '0x5dFa416bdd6d42ea1759AfD3E08165691E49c004',
    symbol: 'crETH',
    underlyingAddress: '',
    underlyingDecimal: 18,
    underlyingName: 'Ethereum',
    underlyingSymbol: 'ETH',
    category: Category.major,
    zeroAllowance: false,
  },
  {
    name: 'Cream USD Coin',
    address: '0xD9b8c80d7fe4391B583916F1bFAa972446F72a1B',
    symbol: 'crUSDC',
    underlyingAddress: '0xD87Ba7A50B2E7E660f678A895E4B72E7CB4CCd9C',
    underlyingDecimal: 6,
    underlyingName: 'USD Coin',
    underlyingSymbol: 'USDC',
    category: Category.major,
    zeroAllowance: false,
  },
];

export default GoerliMarkets;
