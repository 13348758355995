import React from 'react';
import styled from 'styled-components';
import useTokenIcon from '../../hooks/useTokenIcon';

interface TokenIconSymbolProps {
  symbol: string;
}

const TokenIconSymbol: React.FC<TokenIconSymbolProps> = ({ symbol }) => {
  const icon = useTokenIcon(symbol);

  // this attr is required for react-data-table
  const attr = {
    'data-tag': 'allowRowEvents'
  };

  return (
    <Wrapper>
      {icon ? (
        <Icon {...attr} src={icon} alt={symbol} />
      ): (
        <MissingIcon {...attr}>😐</MissingIcon>
      )}
      <Symbol {...attr}>{symbol}</Symbol>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.9);
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: ${(props) => props.theme.spacing[3]};
`;

const MissingIcon = styled.div`
  margin-right: ${(props) => props.theme.spacing[3]};
  font-size: 24px;
`;

const Symbol = styled.span`
  white-space: nowrap;
`;

export default TokenIconSymbol;
