import { BigNumber } from "ethers";
import { TransactionResponse } from "@ethersproject/abstract-provider";
import { Signer } from "@ethersproject/abstract-signer";

abstract class AbstractCToken {
  abstract connect(signer: Signer): void;

  abstract decimals(): Promise<number>;

  abstract mint(mintAmount: BigNumber): Promise<TransactionResponse>;

  abstract redeem(redeemAmount: BigNumber): Promise<TransactionResponse>;

  abstract redeemUnderlying(redeemAmount: BigNumber): Promise<TransactionResponse>;

  abstract borrow(borrowAmount: BigNumber): Promise<TransactionResponse>;

  abstract repayBorrow(repayAmount: BigNumber): Promise<TransactionResponse>;

  abstract exchangeRateCurrent(): Promise<BigNumber>
}

export default AbstractCToken;
