import { configureStore } from '@reduxjs/toolkit';
import marketReducer from './market';

const store = configureStore({
  reducer: {
    market: marketReducer,
  }
})

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
