import React from 'react';
import styled, { useTheme } from 'styled-components';
import media from '../../media';
import Links from './components/Links';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/img/logo.svg';

const Footer: React.FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  const appLinks = [
    {
      text: 'C.R.E.A.M.',
      url: 'https://app.cream.finance/',
    },
    {
      text: t('Iron Bank'),
      url: 'https://yearn.fi/lend/',
    },
    {
      text: t('Flash Loans'),
      url: 'https://docs.cream.finance/flash-loans',
    },
    {
      text: t('Eth2 Staking'),
      url: 'https://classic.cream.finance/eth2',
    },
  ];

  const docLinks = [
    {
      text: t('Documents'),
      url: 'https://docs.cream.finance/',
    },
    {
      text: t('GitHub'),
      url: 'https://github.com/CreamFi/',
    },
    {
      text: t('Audit'),
      url: 'https://github.com/CreamFi/compound-protocol/tree/master/audits/',
    },
    {
      text: t('Bug Bounty'),
      url: 'https://immunefi.com/bounty/creamfinance/',
    }
  ];

  const communityLinks = [
    {
      text: t('Discord'),
      url: 'https://discord.gg/bUCwANsAp7',
    },
    {
      text: t('Telegram'),
      url: 'https://t.me/CreamdotFinance',
    },
    {
      text: t('Kakaotalk'),
      url: 'https://open.kakao.com/o/gQ0wpOUc',
    },
  ];

  const govLinks = [
    {
      text: t('Forum'),
      url: 'https://forum.cream.finance/',
    },
    {
      text: t('VOTING'),
      url: 'https://vote.cream.finance/',
    },
    {
      text: t('VOTING (Listing)'),
      url: 'https://listing.cream.finance/',
    },
  ];

  const legacyLinks = [
    {
      text: t('CREAM Classic'),
      url: 'https://classic.cream.finance',
    },
    {
      text: t('Swap Pools'),
      url: 'https://classic.cream.finance/pools',
    },
    {
      text: t('Inactive Reward Pools'),
      url: 'https://classic.cream.finance/reward',
    },
  ];

  const socialLinks = [
    {
      text: t('Medium'),
      url: 'https://medium.com/cream-finance',
    },
    {
      text: t('Twitter'),
      url: 'https://twitter.com/CreamdotFinance',
    },
    {
      text: t('Twitter (JP)'),
      url: 'https://twitter.com/CreamdotFi_JP',
    },
    {
      text: t('Naver Blog'),
      url: 'https://blog.naver.com/creamfi',
    },
  ];

  return (
    <StyledContainer>
      <Row className="justify-content-between gy-3">
        <Col md={12} lg={4}>
          <img src={logo} alt="cream logo" />
          <Slogan className="fw-bold">Crypto Rules Everything Around Me</Slogan>
          <FooterInfo>
            {t("CREAM FOOTNOTE")}
          </FooterInfo>
          <ContactUs href="mailto:mkt@cream.finance">{t("Contact us")}</ContactUs>
        </Col>
        <Col md={12} lg={8}>
          <Row className="gx-0 gy-4">
            <Links className="col-12 col-md-5" title={t("App")} titleColor={theme.colors.creamGreen} links={appLinks} />
            <Links className="col-12 col-md-4" title={t("Docs & Security")} titleColor={theme.colors.creamPink} links={docLinks} />
            <Links className="col-12 col-md-3" title={t("Community")} titleColor={theme.colors.creamYellow} links={communityLinks} />
            <Links className="col-12 col-md-5" title={t("GOVERNANCE")} titleColor={theme.colors.creamOrange} links={govLinks} />
            <Links className="col-12 col-md-4" title={t("LEGACY")} titleColor={theme.colors.creamPurple} links={legacyLinks} />
            <Links className="col-12 col-md-3" title={t("SOCIAL")} titleColor={theme.colors.creamGreen} links={socialLinks} />
          </Row>
        </Col>
      </Row>
    </StyledContainer>
  );
}

const StyledContainer = styled(Container)`
  padding-top: 22px;
  padding-bottom: 22px;

  ${media.up('sm')} {
    padding-top: 84px;
    padding-bottom: 84px;
  }
`

const Slogan = styled.div`
  margin-top: 32px;
  font-size: ${(props) => props.theme.textSize.big};
`

const FooterInfo = styled.div`
  margin-top: 13px;
`

const ContactUs = styled.a`
  margin-top: 18px;
  color: ${(props) => props.theme.colors.creamGreen} !important;
  cursor: pointer;
  display: block;
`;

export default Footer;
