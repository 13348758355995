import { createSlice } from '@reduxjs/toolkit';
import { MarketData } from '../pages/Markets/types';

interface marketState {
  protocol: string;
  data: MarketData[];
}

const marketsSlice = createSlice({
  name: 'market',
  initialState: {} as marketState,
  reducers: {
    updateMarket: (state, action) => {
      return {...action.payload};
    },
  }
})

export default marketsSlice.reducer;
export const { updateMarket } = marketsSlice.actions;
