import React, { createContext, useContext, useMemo } from 'react';
import { Configuration } from '../cream/Configuration';
import Cream from '../cream/Cream';
import { ConnectionContext } from './ConnectionProvider';
import { ProtocolContext } from './ProtocolProvider';

interface Context {
  cream: Cream | undefined;
}

export const CreamContext = createContext<Context>({
  cream: undefined,
});

const CreamProvider: React.FC = ({ children }) => {
  const { networkId, provider, signer } = useContext(ConnectionContext);
  const { protocol } = useContext(ProtocolContext);

  const config = useMemo(() => {
    const config: Configuration = {
      chainId: networkId,
      comptrollerAddress: protocol.comptrollerAddress,
      lensAddress: protocol.compoundLensAddress,
      multiCallAddress: protocol.multiCallAddress,
      protocol,
    }
    return config;
  }, [networkId, protocol])

  const cream = useMemo(() => {
    if (signer) {
      return new Cream(config, signer);
    } else {
      return new Cream(config, provider)
    }
  }, [config, signer, provider])

  const context = useMemo(() => {
    return { cream }
  }, [cream]);

  return (
    <CreamContext.Provider value={context}>
      {children}
    </CreamContext.Provider>
  )
}

export default CreamProvider
