import styled from 'styled-components';
import React from 'react';

interface MarketCardProps {
  backgroundColor?: string;
}

const MarketCard: React.FC<MarketCardProps> = ({ children, backgroundColor }) => (
  <Wrapper backgroundColor={backgroundColor}>
    { children }
  </Wrapper>
);

const Wrapper = styled.div<{backgroundColor?: string}>`
  padding: ${(props) => props.theme.spacing.normal};
  background: ${(props) => props.backgroundColor};
  border-radius: 8px;
`;

export default MarketCard;
