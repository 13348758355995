import React, { createContext, useMemo, useState } from 'react';
import { DevProtocols, EthereumProtocol, GoerliProtocol, ProductionProtocols, Protocol } from '../cream/Protocols';

interface Context {
  protocol: Protocol;
  protocols: Protocol[];
  changeProtocol(protocol: Protocol): void;
}

export const ProtocolContext = createContext<Context>({
  protocol: GoerliProtocol,
  protocols: [],
  changeProtocol: () => {},
});

const ProtocolProvider: React.FC = ({ children }) => {
  const protocols = useMemo(() => {
    if (process.env.REACT_APP_TESTNET) {
      return DevProtocols;
    }
    return ProductionProtocols;
  }, []);

  const cachedProtocol = useMemo(() => {
    let protocol = GoerliProtocol;
    const previouslySelectedProtocol = window.localStorage.getItem('selectedProtocol');
    for (const p of protocols) {
      if (p.name === previouslySelectedProtocol) {
        protocol = p;
      }
    }
    return protocol;
  }, [protocols]);

  const [protocol, setProtocol] = useState<Protocol>(cachedProtocol);

  const changeProtocol = (newProtocol: Protocol) => {
    if (newProtocol === protocol) {
      return;
    }
    setProtocol(newProtocol);
    window.localStorage.setItem('selectedProtocol', newProtocol.name);
  }

  const context = {
    protocol,
    protocols,
    changeProtocol,
  }

  return (
    <ProtocolContext.Provider value={context}>
      { children }
    </ProtocolContext.Provider>
  )
}

export default ProtocolProvider;
