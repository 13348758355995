import { BigNumber } from 'ethers';
import React, { useContext, useMemo, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import BorrowRepayModal from '../../../components/Modal/BorrowRepayModal';
import TokenIconSymbol from '../../../components/TokenIconSymbol';
import { Category } from '../../../cream/Category';
import { displayBalance, rateToApy, uncommify, totalBorrowsInUsd, distributionApy } from '../../../cream/utils';
import useModal from '../../../hooks/useModal';
import { MarketContext } from '../../../providers/MarketProvider';
import { ProtocolContext } from '../../../providers/ProtocolProvider';
import MarketCard from './MarketCard';
import MarketSearchBar from './MarketSearchBar';
import { IDataTableColumn } from 'react-data-table-component';
import MarketTable from './MarketTable';
import { useTranslation } from "react-i18next";
import { abbreviateNumber } from "js-abbreviation-number";

type BorrowMarketData = {
  asset: string;
  address: string;
  apy: string;
  distributionApy: string;
  borrowRate: BigNumber;
  liquidity: string;
  category: Category;
  balance: string;
}

const useBorrowMarketData = (): BorrowMarketData[] => {
  const { markets, allMarketStats, allUserTokenStats, lmRewardsStats, basePrice } = useContext(MarketContext);
  const { protocol } = useContext(ProtocolContext);

  const data = useMemo(() => {
    const data: BorrowMarketData[] = [];
    for (const i in allMarketStats) {
      const userTokenStats = allUserTokenStats[i];
      const marketStats = allMarketStats[i];
      const market = markets[i];

      const speeds = lmRewardsStats[i].rewardSpeeds;
      const totalBorrows = totalBorrowsInUsd(marketStats.borrow, marketStats.underlyingPrice, market.underlyingDecimal, basePrice);

      data.push({
        address: market.address,
        apy: rateToApy(marketStats.borrowRate, protocol.blocksPerYear),
        distributionApy: distributionApy(speeds, totalBorrows, false),
        borrowRate: marketStats.borrowRate,
        asset: market.underlyingSymbol,
        liquidity: displayBalance(marketStats.cash, market.underlyingDecimal, 2),
        category: market.category,
        balance: displayBalance(userTokenStats.borrowBalance, market.underlyingDecimal, 2),
      })
    }
    return data;
  }, [allMarketStats, allUserTokenStats, markets, lmRewardsStats, basePrice, protocol])

  return data;
}

const BorrowMarket: React.FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [category, setCategory] = useState<Category | null>(null);

  const shortLiquidity = (liquidity: string) => {
    const l = Number(uncommify(liquidity));
    return abbreviateNumber(l, 2, { padding: false })
  }

  const columns: IDataTableColumn<BorrowMarketData>[] = [
    {
      name: t('ASSET'),
      selector: 'asset',
      sortable: true,
      cell: (row) => <TokenIconSymbol data-tag="allowRowEvents" symbol={row.asset} />,
    },
    {
      name: t('BORROW APY'),
      selector: 'apy',
      sortable: true,
      sortFunction: (a, b) => { return a.borrowRate.toNumber() - b.borrowRate.toNumber() },
      right: true,
    },
    {
      name: t('LIQUIDITY'),
      selector: 'liquidity',
      cell: (row) => <div data-tag="allowRowEvents" style={{ whiteSpace: 'nowrap' }}>{shortLiquidity(row.liquidity)} {row.asset}</div>,
      right: true,
    },
  ];

  const data = useBorrowMarketData();

  const [filterText, setFilterText] = useState('');
  // Note: cannot put `filterLegacy` in `filter` because `filter` will be used to hide "Show More".
  const filterLegacy = (d: BorrowMarketData) => {
    if (d.category === Category.legacy) {
      // Hide the asset if the user borrow balance is zero.
      return d.balance !== '0';
    }
    return true;
  }
  const filter = (data: BorrowMarketData[]) => {
    if (!filterText && !category) {
      return data;
    }

    return data
      .filter((d) => d.asset.toLowerCase().includes(filterText.toLowerCase()))
      .filter((d) => category ? d.category === category : true);
  }

  const { presentModal } = useModal();
  const onRowClicked = (row: BorrowMarketData) => {
    presentModal(<BorrowRepayModal marketAddress={row.address} />)
  }

  return (
    <MarketCard backgroundColor={theme.colors.borrowCardBackground}>
      <MarketSearchBar
        placeholder={t('SEARCH Borrow assets')}
        themeColor={theme.colors.secondary}
        onFilter={(text) => setFilterText(text)}
        onSelect={setCategory}
      />
      <TableWrapper className="d-flex flex-column">
        <MarketTable columns={columns} data={data.filter(d => filterLegacy(d))} onRowClicked={onRowClicked} keyField="asset"
                     filter={filter}
                     displayItems={10}
        />
      </TableWrapper>
    </MarketCard>
  )
};

const TableWrapper = styled.div`
  margin: 0 -16px;
  border-radius: 8px;
`;

export default BorrowMarket;
