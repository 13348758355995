import React, { useState } from 'react';
import DataTable, { IDataTableProps } from 'react-data-table-component';
import './MarketTable.css';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface MarketTableProps<T = any> extends IDataTableProps {
  // if present, will filter data and hides "Show More" text if the returned data length
  // is not equal to `data`
  filter?: (data: T[]) => T[];
  // if present, will display only these items, and hide others in "Show More"
  displayItems?: number;
  defaultCursorOnHover?: boolean;
}

// A reusable themed table that is used for lending page.
const MarketTable: React.FC<MarketTableProps> = ({ filter, displayItems, defaultCursorOnHover, data, ...props }) => {
  const { t } = useTranslation();
  const [showMore, setShowMore] = useState(false);

  const newProps = {
    ...props,
    theme: 'market',
    noHeader: true,
    pointerOnHover: !defaultCursorOnHover,
    highlightOnHover: true,
    persistTableHead: true,
    customStyles: {
      headRow: {
        style: {
          minHeight: '40px',
          borderBottomWidth: '0',
        },
      },
      headCells: {
        style: {
          fontSize: '10px',
          color: 'rgba(255, 255, 255, 0.5)'
        },
        activeSortStyle: {
          color: 'white',
        },
      },
      cells: {
        style: {
          fontWeight: 500,
        },
      },
      rows: {
        style: {
          fontSize: '14px',
          minHeight: '40px',
          color: 'rgba(255, 255, 255, 0.9)',
          '&:not(:last-of-type)': {
            borderBottomWidth: '0'
          },
        },
        highlightOnHoverStyle: {
          color: 'white',
          backgroundColor: '#151B28',
          outlineStyle: 'none',
        }
      }
    },
  };

  let filteredData = data.slice();
  if (filter) {
    filteredData = filter(data);
  }

  const hasFilter = filteredData.length !== data.length;

  // pagination here is used for "hiding" data.
  return (
    <>
      <DataTable {...newProps}
                 data={filteredData}
                 style={{ padding: '16px' }}
                 className="market-table"
                 pagination={!!displayItems && !hasFilter && !showMore}
                 paginationPerPage={displayItems || filteredData.length}
      />
      {!hasFilter && !!displayItems && displayItems < data.length && (
        <ShowMore onClick={() => setShowMore(!showMore)}>
          {showMore ? t('SHOW LESS') : t('SHOW MORE')}
        </ShowMore>
      )}
    </>
  )
}

const ShowMore = styled.div`
  text-align: center;
  cursor: pointer;
  color: ${(props) => props.theme.colors.creamGreen};
  padding-bottom: 8px;
`

export default MarketTable;
