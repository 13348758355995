import styled from 'styled-components';

const ButtonGroup = styled.div`
  display: flex;
  
  & > * {
    margin-top: 6px;
  }

  & > *:last-child {
    margin-left: 24px;
  }
  
  & > *:only-child {
    margin-left: 0;
  }

`

export default ButtonGroup;
