import React, { useContext, useState } from 'react';
import { slide as Menu } from 'react-burger-menu';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { ProtocolContext } from '../../providers/ProtocolProvider';
import { SiteProtocol } from '../Page/Page';
import Dropdown from '../TopBar/components/Dropdown';

interface MobileMenuProps {
  isMenuOpen: boolean;
  onMenuToggle: (value: boolean) => void;
  protocols: SiteProtocol[];
}

const MobileMenu: React.FC<MobileMenuProps> = ({ isMenuOpen, onMenuToggle, protocols }) => {
  const { t, i18n } = useTranslation();
  const { creamPink, creamOrange } = useTheme().colors;
  const { protocol: currentProtocol, changeProtocol } = useContext(ProtocolContext);

  const initialOptions = [
    { name: t('LENDING NAV'), color: creamPink, to: '', subOptions: [
        { name: 'Supply', to: '/'},
        { name: 'Borrow', to: '/'},
      ], isExpanded: false },
  ]
  const [options, setOptions] = useState(initialOptions);
  const languages = [
    { title: 'EN', value: 'en' },
    { title: '中文', value: 'zh-Hant' },
    { title: '日本語', value: 'jp' },
    { title: '한국어', value: 'kr' },
    { title: 'Español', value: 'es' },
  ];

  return (
    <Menu
      noOverlay
      isOpen={isMenuOpen}
      onOpen={() => {}}
      disableOverlayClick
      itemListElement="div"
      disableAutoFocus
      styles={{
        bmMenuWrap: {
          position: 'fixed',
          height: 'calc(100% - 53px)'
        },
        bmMenu: {
          background: '#151B28',
          padding: '20px'
        }
      }}
      customBurgerIcon={false}
      customCrossIcon={false}
      width={'100%'}
    >
      {options.map((option, index) => {
        return (
          <OptionContainer key={index}>
            <Option onClick={() => {
              let newOptions = [...options]
              newOptions[index].isExpanded = !newOptions[index].isExpanded
              setOptions(newOptions)
            }}>
              {option.subOptions.length > 0 ? (
                <>
                  <span>{option.name}</span>
                  <span style={{ color: option.color }}>{option.isExpanded ? '-' : '+'}</span>
                </>
              ) : (
                <StyledLink to={option.to} onClick={() => onMenuToggle(false)}>{option.name}</StyledLink>
              )}
            </Option>
            {option.isExpanded && (
              option.subOptions.map((subOption: any) => {
                return (
                  <SubOption key={subOption.name}>
                    <StyledLink to={subOption.to} onClick={() => onMenuToggle(false)}>{subOption.name}</StyledLink>
                  </SubOption>
                )
              })
            )}
          </OptionContainer>
        )
      })
      }
      <BottomBar>
        <BottomBarItem>
          Language
          <Dropdown
            options={languages}
            selectedValue={i18n.language}
            alignTop={false}
            onSelect={(lang) => { i18n.changeLanguage(lang) }}
            style={{ margin: '0',  padding: '0' }}
          />
        </BottomBarItem>
        <BottomBarItem>
          MARKET
          <Dropdown
            options={protocols}
            selectedValue={currentProtocol}
            alignTop={false}
            onSelect={(protocol) => { changeProtocol(protocol) }}
            style={{ margin: '0',  padding: '0' }}
          />
        </BottomBarItem>
      </BottomBar>
    </Menu>
  )
}

const OptionContainer = styled.div`
  font-weight: 500;
  font-size: 18px;
  color: #E6E6E6;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  outline: none;
  border-bottom: 1px solid #1E2738;
`;

const Option = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 20px;
  padding: 0 20px;
`;

const SubOption = styled.div`
  width: 100%;
  font-size: 16px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 20px;
  padding: 0 40px;
  :last-child {
    margin: 0 20px 10px 20px;
  }
`;

const StyledLink = styled(NavLink)`
  &:hover {
    text-decoration: none !important;
  }
`;

const BottomBar = styled.div`
  position: absolute;
  top: calc(85vh);
  display: flex !important;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content:space-between;
  align-items: center;
  width: calc(100% - 40px);
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: rgba(255, 255, 255, 0.5);
  padding: 0 30px;
`;

const BottomBarItem = styled.span`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export default MobileMenu;
