const longTermPoolABI = [
  {
    "inputs":[
      {
        "internalType":"address",
        "name":"_cream",
        "type":"address"
      }
    ],
    "payable":false,
    "stateMutability":"nonpayable",
    "type":"constructor"
  },
  {
    "anonymous":false,
    "inputs":[
      {
        "indexed":true,
        "internalType":"address",
        "name":"previousOwner",
        "type":"address"
      },
      {
        "indexed":true,
        "internalType":"address",
        "name":"newOwner",
        "type":"address"
      }
    ],
    "name":"OwnershipTransferred",
    "type":"event"
  },
  {
    "anonymous":false,
    "inputs":[
      {
        "indexed":false,
        "internalType":"uint256",
        "name":"reward",
        "type":"uint256"
      }
    ],
    "name":"RewardAdded",
    "type":"event"
  },
  {
    "anonymous":false,
    "inputs":[
      {
        "indexed":true,
        "internalType":"address",
        "name":"user",
        "type":"address"
      },
      {
        "indexed":false,
        "internalType":"uint256",
        "name":"reward",
        "type":"uint256"
      }
    ],
    "name":"RewardPaid",
    "type":"event"
  },
  {
    "anonymous":false,
    "inputs":[
      {
        "indexed":true,
        "internalType":"address",
        "name":"user",
        "type":"address"
      },
      {
        "indexed":false,
        "internalType":"uint256",
        "name":"amount",
        "type":"uint256"
      }
    ],
    "name":"Staked",
    "type":"event"
  },
  {
    "anonymous":false,
    "inputs":[
      {
        "indexed":true,
        "internalType":"address",
        "name":"user",
        "type":"address"
      },
      {
        "indexed":false,
        "internalType":"uint256",
        "name":"amount",
        "type":"uint256"
      }
    ],
    "name":"Withdrawn",
    "type":"event"
  },
  {
    "constant":true,
    "inputs":[

    ],
    "name":"admin",
    "outputs":[
      {
        "internalType":"address",
        "name":"",
        "type":"address"
      }
    ],
    "payable":false,
    "stateMutability":"view",
    "type":"function"
  },
  {
    "constant":true,
    "inputs":[
      {
        "internalType":"address",
        "name":"account",
        "type":"address"
      }
    ],
    "name":"balanceOf",
    "outputs":[
      {
        "internalType":"uint256",
        "name":"",
        "type":"uint256"
      }
    ],
    "payable":false,
    "stateMutability":"view",
    "type":"function"
  },
  {
    "constant":true,
    "inputs":[

    ],
    "name":"cream",
    "outputs":[
      {
        "internalType":"contract IERC20",
        "name":"",
        "type":"address"
      }
    ],
    "payable":false,
    "stateMutability":"view",
    "type":"function"
  },
  {
    "constant":true,
    "inputs":[
      {
        "internalType":"address",
        "name":"account",
        "type":"address"
      }
    ],
    "name":"earned",
    "outputs":[
      {
        "internalType":"uint256",
        "name":"",
        "type":"uint256"
      }
    ],
    "payable":false,
    "stateMutability":"view",
    "type":"function"
  },
  {
    "constant":false,
    "inputs":[

    ],
    "name":"exit",
    "outputs":[

    ],
    "payable":false,
    "stateMutability":"nonpayable",
    "type":"function"
  },
  {
    "constant":false,
    "inputs":[

    ],
    "name":"getReward",
    "outputs":[

    ],
    "payable":false,
    "stateMutability":"nonpayable",
    "type":"function"
  },
  {
    "constant":true,
    "inputs":[

    ],
    "name":"isOwner",
    "outputs":[
      {
        "internalType":"bool",
        "name":"",
        "type":"bool"
      }
    ],
    "payable":false,
    "stateMutability":"view",
    "type":"function"
  },
  {
    "constant":true,
    "inputs":[

    ],
    "name":"lastTimeRewardApplicable",
    "outputs":[
      {
        "internalType":"uint256",
        "name":"",
        "type":"uint256"
      }
    ],
    "payable":false,
    "stateMutability":"view",
    "type":"function"
  },
  {
    "constant":true,
    "inputs":[

    ],
    "name":"lastUpdateTime",
    "outputs":[
      {
        "internalType":"uint256",
        "name":"",
        "type":"uint256"
      }
    ],
    "payable":false,
    "stateMutability":"view",
    "type":"function"
  },
  {
    "constant":true,
    "inputs":[

    ],
    "name":"lpToken",
    "outputs":[
      {
        "internalType":"contract IERC20",
        "name":"",
        "type":"address"
      }
    ],
    "payable":false,
    "stateMutability":"view",
    "type":"function"
  },
  {
    "constant":false,
    "inputs":[
      {
        "internalType":"uint256",
        "name":"reward",
        "type":"uint256"
      },
      {
        "internalType":"uint256",
        "name":"dayAmount",
        "type":"uint256"
      }
    ],
    "name":"notifyRewardAmount",
    "outputs":[

    ],
    "payable":false,
    "stateMutability":"nonpayable",
    "type":"function"
  },
  {
    "constant":true,
    "inputs":[

    ],
    "name":"owner",
    "outputs":[
      {
        "internalType":"address",
        "name":"",
        "type":"address"
      }
    ],
    "payable":false,
    "stateMutability":"view",
    "type":"function"
  },
  {
    "constant":true,
    "inputs":[

    ],
    "name":"releaseTime",
    "outputs":[
      {
        "internalType":"uint256",
        "name":"",
        "type":"uint256"
      }
    ],
    "payable":false,
    "stateMutability":"view",
    "type":"function"
  },
  {
    "constant":false,
    "inputs":[

    ],
    "name":"renounceOwnership",
    "outputs":[

    ],
    "payable":false,
    "stateMutability":"nonpayable",
    "type":"function"
  },
  {
    "constant":true,
    "inputs":[

    ],
    "name":"rewardPerToken",
    "outputs":[
      {
        "internalType":"uint256",
        "name":"",
        "type":"uint256"
      }
    ],
    "payable":false,
    "stateMutability":"view",
    "type":"function"
  },
  {
    "constant":true,
    "inputs":[

    ],
    "name":"rewardPerTokenStored",
    "outputs":[
      {
        "internalType":"uint256",
        "name":"",
        "type":"uint256"
      }
    ],
    "payable":false,
    "stateMutability":"view",
    "type":"function"
  },
  {
    "constant":true,
    "inputs":[

    ],
    "name":"rewardRate",
    "outputs":[
      {
        "internalType":"uint256",
        "name":"",
        "type":"uint256"
      }
    ],
    "payable":false,
    "stateMutability":"view",
    "type":"function"
  },
  {
    "constant":true,
    "inputs":[
      {
        "internalType":"address",
        "name":"",
        "type":"address"
      }
    ],
    "name":"rewards",
    "outputs":[
      {
        "internalType":"uint256",
        "name":"",
        "type":"uint256"
      }
    ],
    "payable":false,
    "stateMutability":"view",
    "type":"function"
  },
  {
    "constant":false,
    "inputs":[
      {
        "internalType":"contract IERC20",
        "name":"_token",
        "type":"address"
      },
      {
        "internalType":"uint256",
        "name":"amount",
        "type":"uint256"
      }
    ],
    "name":"seize",
    "outputs":[

    ],
    "payable":false,
    "stateMutability":"nonpayable",
    "type":"function"
  },
  {
    "constant":false,
    "inputs":[
      {
        "internalType":"address",
        "name":"_rewardDistribution",
        "type":"address"
      }
    ],
    "name":"setRewardDistribution",
    "outputs":[

    ],
    "payable":false,
    "stateMutability":"nonpayable",
    "type":"function"
  },
  {
    "constant":false,
    "inputs":[
      {
        "internalType":"uint256",
        "name":"amount",
        "type":"uint256"
      }
    ],
    "name":"stake",
    "outputs":[

    ],
    "payable":false,
    "stateMutability":"nonpayable",
    "type":"function"
  },
  {
    "constant":true,
    "inputs":[

    ],
    "name":"totalSupply",
    "outputs":[
      {
        "internalType":"uint256",
        "name":"",
        "type":"uint256"
      }
    ],
    "payable":false,
    "stateMutability":"view",
    "type":"function"
  },
  {
    "constant":false,
    "inputs":[
      {
        "internalType":"address",
        "name":"newOwner",
        "type":"address"
      }
    ],
    "name":"transferOwnership",
    "outputs":[

    ],
    "payable":false,
    "stateMutability":"nonpayable",
    "type":"function"
  },
  {
    "constant":true,
    "inputs":[
      {
        "internalType":"address",
        "name":"",
        "type":"address"
      }
    ],
    "name":"userRewardPerTokenPaid",
    "outputs":[
      {
        "internalType":"uint256",
        "name":"",
        "type":"uint256"
      }
    ],
    "payable":false,
    "stateMutability":"view",
    "type":"function"
  },
  {
    "constant":false,
    "inputs":[
      {
        "internalType":"uint256",
        "name":"amount",
        "type":"uint256"
      }
    ],
    "name":"withdraw",
    "outputs":[

    ],
    "payable":false,
    "stateMutability":"nonpayable",
    "type":"function"
  }
];

export default longTermPoolABI;
