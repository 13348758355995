import { DefaultTheme } from 'styled-components';

export const gold = '#f2d477';

const theme: DefaultTheme = {
  colors: {
    gold,
    white: '#e6e6e6',
    grey: '#8c8c8c',
    offGrey: '#313a49',
    background: '#000',
    modalBackground: '#1e2138',
    cardBackground: '#1a1a1a',
    creamGreen: '#69E2DB',
    creamOrange: '#FFC78A',
    creamPink: '#FFB8D2',
    creamPurple: '#CDC5FF',
    creamYellow: '#F4DB62',
    borrowCardBackground: '#221e38',
    supplyCardBackground: '#1e2738',
    primary: '#69e2db',
    secondary: '#cdc5ff',
    safe: '#69e277',
    warning: '#e26969',
  },
  spacing: {
    1: '4px',
    2: '8px',
    3: '12px',
    4: '16px',
    5: '24px',
    6: '32px',
    7: '40px',
    8: '48px',
    normal: '16px',
  },
  textSize: {
    small: '11px',
    normal: '14px',
    big: '16px',
    bigger: '20px',
    large: '24px',
  },
  letterSpacing: {
    tight: '-0.75px',
    small: '1.833px',
    big: '2px',
  },
  topBarSize: 65,
  footerSize: 447,
};

export default theme;
