import RCSlider, { SliderTooltip } from 'rc-slider';
import React from 'react';
import 'rc-slider/assets/index.css';

interface SliderProps {
  pctValue: number;
  // onChange returns values from [0, 100] when slider value changes
  onChange: (pct: number) => void;
  theme: string;
}

const Slider: React.FC<SliderProps> = ({ onChange, pctValue, theme }) => {
  const handle = (props: any) => {
    const { value, dragging, index, ...restProps } = props;
    return (
      <SliderTooltip
        prefixCls="rc-slider-tooltip"
        overlay={`${value} %`}
        placement="bottom"
        key={index}
        visible={dragging}
        overlayInnerStyle={{
          backgroundColor: '#151B28',
          boxShadow: 'none',
          borderRadius: '4px',
          padding: '4px 10px',
          color: theme,
          fontSize: '13px',
          fontWeight: 500,
          lineHeight: '15px'
        }}
      >
        <RCSlider.Handle value={value} {...restProps} />
      </SliderTooltip>
    );
  };

  const markStyle = { fontSize: '13px', fontWeight: '500', color: '#E6E6E6' }
  const marks = {
    0:  { label: '0%', style: { ...markStyle, transform: 'translateX(-20%)' } },
    25: { label: '25%', style: markStyle },
    50: { label: '50%', style: markStyle },
    75: { label: '75%', style: markStyle },
    100: { label: '100%', style: { ...markStyle, transform: 'translateX(-80%)' } },
  };

  return (
    <RCSlider
      value={pctValue * 100}
      min={0}
      max={100}
      defaultValue={0}
      marks={marks}
      handle={handle}
      handleStyle={{ backgroundColor: '#151B28', borderColor: theme, borderWidth: '3px', width: '16px', height: '16px'}}
      trackStyle={{ backgroundColor: theme }}
      railStyle={{ backgroundColor: '#151B28' }}
      dotStyle={{ width: '8px', height: '8px', borderColor: theme, borderWidth: '2px', backgroundColor: '#1E2738'}}
      activeDotStyle={{
        width: '12px', height: '12px', bottom: '-4px',
        marginLeft: '-6px', borderColor: '#151B28',
        borderWidth: '3px', backgroundColor: theme,
      }}
      onChange={(value) => {
        onChange(value);
      }}
    />
  )
}

export default Slider;
