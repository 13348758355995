import { Category } from '../Category';
import { Market } from '../Type';

const FantomMarkets: Market[] = [
  {
    name: 'Cream Wrapped Fantom',
    address: '0xd528697008aC67A21818751A5e3c58C8daE54696',
    symbol: 'crWFTM',
    underlyingAddress: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
    underlyingDecimal: 18,
    underlyingName: 'Fantom',
    underlyingSymbol: 'WFTM',
    category: Category.major,
    zeroAllowance: false,
  },
  {
    name: 'Cream Cream',
    address: '0xbadaC56c9aca307079e8B8FC699987AAc89813ee',
    symbol: 'crCREAM',
    underlyingAddress: '0x657A1861c15A3deD9AF0B6799a195a249ebdCbc6',
    underlyingDecimal: 18,
    underlyingName: 'Cream',
    underlyingSymbol: 'CREAM',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream Ethereum',
    address: '0xcc3E89fBc10e155F1164f8c9Cf0703aCDe53f6Fd',
    symbol: 'crETH',
    underlyingAddress: '0x74b23882a30290451A17c44f4F05243b6b58C76d',
    underlyingDecimal: 18,
    underlyingName: 'Ethereum',
    underlyingSymbol: 'ETH',
    category: Category.major,
    zeroAllowance: false,
  },
  {
    name: 'Cream Bitcoin',
    address: '0x20CA53E2395FA571798623F1cFBD11Fe2C114c24',
    symbol: 'crBTC',
    underlyingAddress: '0x321162Cd933E2Be498Cd2267a90534A804051b11',
    underlyingDecimal: 8,
    underlyingName: 'Bitcoin',
    underlyingSymbol: 'BTC',
    category: Category.major,
    zeroAllowance: false,
  },
  {
    name: 'Cream Dai Stablecoin',
    address: '0x04c762a5dF2Fa02FE868F25359E0C259fB811CfE',
    symbol: 'crDAI',
    underlyingAddress: '0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E',
    underlyingDecimal: 18,
    underlyingName: 'Dai Stablecoin',
    underlyingSymbol: 'DAI',
    category: Category.stable,
    zeroAllowance: false,
  },
  {
    name: 'Cream USD Coin',
    address: '0x328A7b4d538A2b3942653a9983fdA3C12c571141',
    symbol: 'crUSDC',
    underlyingAddress: '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
    underlyingDecimal: 6,
    underlyingName: 'USD Coin',
    underlyingSymbol: 'USDC',
    category: Category.stable,
    zeroAllowance: false,
  },
  {
    name: 'Cream yearn.finance',
    address: '0x0980f2F0D2af35eF2c4521b2342D59db575303F7',
    symbol: 'crYFI',
    underlyingAddress: '0x29b0Da86e484E1C0029B56e817912d778aC0EC69',
    underlyingDecimal: 18,
    underlyingName: 'yearn.finance',
    underlyingSymbol: 'YFI',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream Sushi',
    address: '0xB1FD648D8CA4bE22445963554b85AbbFC210BC83',
    symbol: 'crSUSHI',
    underlyingAddress: '0xae75A438b2E0cB8Bb01Ec1E1e376De11D44477CC',
    underlyingDecimal: 18,
    underlyingName: 'Sushi',
    underlyingSymbol: 'SUSHI',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream Aave',
    address: '0x79EA17bEE0a8dcb900737E8CAa247c8358A5dfa1',
    symbol: 'crAAVE',
    underlyingAddress: '0x6a07A792ab2965C72a5B8088d3a069A7aC3a993B',
    underlyingDecimal: 18,
    underlyingName: 'Aave',
    underlyingSymbol: 'AAVE',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream Band',
    address: '0x379555965fcdbA7A40e8B5b5eF4786f51ADeeF31',
    symbol: 'crBAND',
    underlyingAddress: '0x46E7628E8b4350b2716ab470eE0bA1fa9e76c6C5',
    underlyingDecimal: 18,
    underlyingName: 'Band',
    underlyingSymbol: 'BAND',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream Cover Protocol Governance',
    address: '0x5b4058A9000e86fe136Ac896352C4DFD539E32a1',
    symbol: 'crCOVER',
    underlyingAddress: '0xB01E8419d842beebf1b70A7b5f7142abbaf7159D',
    underlyingDecimal: 18,
    underlyingName: 'Cover Protocol Governance',
    underlyingSymbol: 'COVER',
    category: Category.legacy,
    zeroAllowance: false,
  },
  {
    name: 'Cream Hegic',
    address: '0x139Dd8Bb6355d20342e08ff013150b1aE5040a42',
    symbol: 'crHEGIC',
    underlyingAddress: '0x44B26E839eB3572c5E959F994804A5De66600349',
    underlyingDecimal: 18,
    underlyingName: 'Hegic',
    underlyingSymbol: 'HEGIC',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream ChainLink',
    address: '0x4eCEDdF62277eD78623f9A94995c680f8fd6C00e',
    symbol: 'crLINK',
    underlyingAddress: '0xb3654dc3D10Ea7645f8319668E8F54d2574FBdC8',
    underlyingDecimal: 18,
    underlyingName: 'ChainLink',
    underlyingSymbol: 'LINK',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream Synthetix Network',
    address: '0x1cc6Cf8455f7783980B1ee06ecD4ED9acd94e1c7',
    symbol: 'crSNX',
    underlyingAddress: '0x56ee926bD8c72B2d5fa1aF4d9E4Cbb515a1E3Adc',
    underlyingDecimal: 18,
    underlyingName: 'Synthetix Network',
    underlyingSymbol: 'SNX',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream Frapped USDT',
    address: '0x70faC71debfD67394D1278D98A29dea79DC6E57A',
    symbol: 'crFUSDT',
    underlyingAddress: '0x049d68029688eAbF473097a2fC38ef61633A3C7A',
    underlyingDecimal: 6,
    underlyingName: 'Frapped USDT',
    underlyingSymbol: 'FUSDT',
    category: Category.stable,
    zeroAllowance: false,
  },
  {
    name: 'Cream Magic Internet Money',
    address: '0x46F298D5bB6389ccb6C1366bB0C8a30892CA2f7C',
    symbol: 'crMIM',
    underlyingAddress: '0x82f0B8B456c1A451378467398982d4834b6829c1',
    underlyingDecimal: 18,
    underlyingName: 'Magic Internet Money',
    underlyingSymbol: 'MIM',
    category: Category.stable,
    zeroAllowance: false,
  },
];

export default FantomMarkets;
