import { faCheck, faCheckCircle, faCopy, faExternalLinkAlt, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import styled, { useTheme } from 'styled-components';
import useExplorer from '../../hooks/useExplorer';
import { MediaType } from '../../media';
import { Tx, TxContext } from '../../providers/TxProvider';
import Loader from '../Loader';
import ModalInfo from './components/ModalInfo';

interface WalletModalProps {
  address: string;
  ensName: string;
  disconnectWallet: () => void;
  dismissModal: () => void;
}

const WalletModal: React.FC<WalletModalProps> = ({ address, ensName, disconnectWallet, dismissModal }) => {
  const theme = useTheme();
  const [copied, setCopied] = useState(false);
  const { openAddress } = useExplorer();
  const { txs } = useContext(TxContext);
  const { t } = useTranslation();

  const isSm = useMediaQuery({ maxWidth: MediaType.sm });

  const displayAddress = useMemo(() => {
    if (ensName !== '') {
      return ensName;
    } else if (isSm) {
      return <>{address.slice(0, 22)}<br />{address.slice(22)}</>
    } else {
      return address
    }
  }, [address, ensName, isSm])

  const addressComponent = (
    <div style={{ fontWeight: 'normal' }}>
      <div>{displayAddress}</div>
      <AddressAction>
        <div onClick={() => openAddress(address)}>
          {t('View on explorer')}
          <FontAwesomeIcon
            icon={faExternalLinkAlt} color={theme.colors.creamGreen} style={{ marginLeft: theme.spacing[2] }}
          />
        </div>
        <div onClick={() => {copyToClipboard(address); setCopied(true);}}>
          {t('Copy Address')}
          <FontAwesomeIcon
            icon={copied ? faCheck : faCopy} color={theme.colors.creamGreen} style={{ marginLeft: theme.spacing[2] }}
          />
        </div>
      </AddressAction>
    </div>
  )

  const pendingTxInfos = useMemo(() => {
    return txs.filter((tx) => !tx.confirmed)
      .map((tx) => ({
        title: <TxSummary tx={tx} />,
        value: <TxLink tx={tx.hash} />,
      }))
  }, [txs]);

  const recentTxInfos = useMemo(() => {
    return txs.filter((tx) => tx.confirmed)
      .map((tx) => ({
        title: <TxSummary tx={tx} />,
        value: <TxLink tx={tx.hash} />,
      }))
      .slice(0, 5)
  }, [txs]);

  return (
    <ModalWrapper>
      <ModalHeader>
        {t('Account')}
        <LogoutBtn onClick={() => {
          disconnectWallet();
          dismissModal();
        }}>
          {t('Log out')}
        </LogoutBtn>
      </ModalHeader>
      <div>
        <ModalInfo title={t('CONNECTED WALLET ADDRESS')} noMarginTop infos={[
          {
            title: addressComponent,
            value: undefined,
          }
        ]} />
        {pendingTxInfos.length > 0 && <ModalInfo title={<PendingTxTitle />} infos={pendingTxInfos} />}
        {recentTxInfos.length > 0 && <ModalInfo title={t('RECENT TRANSACTIONS')} infos={recentTxInfos} />}
      </div>
    </ModalWrapper>
  )
}

interface TxLinkProps {
  tx: string;
}

const TxLink: React.FC<TxLinkProps> = ({ tx }) => {
  const theme = useTheme();
  const { openTx } = useExplorer();

  return (
    <StyledLink onClick={() => openTx(tx)}>
      Explorer
      <FontAwesomeIcon
        icon={faExternalLinkAlt} color={theme.colors.creamGreen} style={{ marginLeft: theme.spacing[2] }}
      />
    </StyledLink>
  )
}

interface TxSummaryProps {
  tx: Tx
}

const TxSummary: React.FC<TxSummaryProps> = ({ tx }) => {
  const theme = useTheme();
  const fontIconStyle = {
    width: '16px',
    height: '16px',
  }

  let icon: React.ReactNode;
  if (!tx.confirmed) {
    icon = <Loader />
  } else if (!tx.success) {
    icon = <FontAwesomeIcon icon={faTimesCircle} style={fontIconStyle} color={theme.colors.warning} />
  } else {
    icon = <FontAwesomeIcon icon={faCheckCircle} style={fontIconStyle} color={theme.colors.safe} />
  }

  return (
    <TxSummaryWrapper>
      {icon}
      <span style={{marginLeft: '8px'}}>{tx.summary}</span>
    </TxSummaryWrapper>
  )
}

const copyToClipboard = (text: string) => {
  const textArea = document.createElement("textarea");
  textArea.value = text;
  textArea.style.position = 'fixed';
  textArea.style.top = '0';
  textArea.style.left = '0';
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  document.execCommand('copy');
  document.body.removeChild(textArea);
}

const PendingTxTitle: React.FC = () => {
  const { t } = useTranslation();
  const { clearPendingTx } = useContext(TxContext);
  return (
    <>
      {t('PENDING TRANSACTIONS')}
      <ClearPendingTransaction onClick={() => clearPendingTx()}>(Clear)</ClearPendingTransaction>
    </>
  )
}

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => props.theme.colors.supplyCardBackground};
  border-radius: 4px;

  & > * {
    width: 100%;
    padding: 20px 40px;
  }
`;

const ModalHeader = styled.div`
  font-size: ${(props) => props.theme.textSize.large};
  font-weight: bold;
  border-bottom: 1px solid ${(props) => props.theme.colors.offGrey};
`

const LogoutBtn = styled.button`
  border: none;
  background-color: unset;
  color: ${(props) => props.theme.colors.warning};
  font-size: ${(props) => props.theme.textSize.big};
  font-weight: bold;
  text-transform: uppercase;
  margin-left: ${(props) => props.theme.spacing[4]};
`

const AddressAction = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin-top: 8px;
  white-space: nowrap;

  & > * {
    margin-left: 16px;
    cursor: pointer;
    padding: 4px 0;
    color: ${(props) => props.theme.colors.creamGreen};

    &:first-child {
      margin-left: 0;
    }
  }
`

const StyledLink = styled.div`
  cursor: pointer;
  font-weight: normal;
  color: ${(props) => props.theme.colors.creamGreen};
`

const TxSummaryWrapper = styled.div`
  display: flex;
  align-items: center;
`

const ClearPendingTransaction = styled.span`
  cursor: pointer;
  margin-left: 12px;
  color: ${(props) => props.theme.colors.warning};
  opacity: 1;
`;

export default WalletModal;
