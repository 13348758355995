import { Category } from '../Category';
import { Market } from '../Type';

const ArbitrumMarkets: Market[] = [
  {
    name: 'Cream Ether',
    address: '0x5441090C0401EE256b09DEb35679Ad175d1a0c97',
    symbol: 'crETH',
    underlyingAddress: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
    underlyingDecimal: 18,
    underlyingName: 'Wrapped Ether',
    underlyingSymbol: 'WETH',
    category: Category.major,
    zeroAllowance: false,
  },
  {
    name: 'Cream USD Coin',
    address: '0xd5794ea7b269dB3a0CCB396774Cc2D0936FFBD86',
    symbol: 'USDC',
    underlyingAddress: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
    underlyingDecimal: 6,
    underlyingName: 'USD Coin',
    underlyingSymbol: 'USDC',
    category: Category.stable,
    zeroAllowance: false,
  },
  {
    name: 'Cream Tether USD',
    address: '0x5eb35dAF9EF97E9e8cc33C486Bad884a62CAe9Ce',
    symbol: 'USDT',
    underlyingAddress: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
    underlyingDecimal: 6,
    underlyingName: 'Tether USD',
    underlyingSymbol: 'USDT',
    category: Category.stable,
    zeroAllowance: false,
  }
];

export default ArbitrumMarkets;
