import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import React, { createContext, useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import './Modal.css';
import media from '../../media';

interface ModalContext {
  // eslint-disable-next-line no-unused-vars
  onPresent: (content: React.ReactNode) => void,
  onDismiss: () => void,
}

export const Context = createContext<ModalContext>({
  onPresent: () => {},
  onDismiss: () => {},
});

const ModalProvider: React.FC = ({ children }) => {
  const [content, setContent] = useState<React.ReactNode>();
  const [isOpen, setIsOpen] = useState(false);

  const handlePresent = (contentNode: React.ReactNode) => {
    setContent(contentNode);
    setIsOpen(true);
  };
  const handleDismiss = () => {
    setContent(undefined);
    setIsOpen(false);
  };
  const [context] = useState<ModalContext>({
    onPresent: handlePresent,
    onDismiss: handleDismiss,
  });

  useEffect(() => {
    const body = document.querySelector('body');
    body!.style.overflow = isOpen ? 'hidden' : 'auto';
  }, [isOpen]);

  useEffect(() => {
    const close = (e: any) => {
      if (e.keyCode === 27) {
        handleDismiss();
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  })

  return (
    <Context.Provider value={context}>
      <ContentWrapper blurred={isOpen}>
        {children}
      </ContentWrapper>
      {/*<CSSTransition in={isOpen} mountOnEnter unmountOnExit appear timeout={50} classNames="modal-backdrop">*/}
        <ModalBackdrop
          show={isOpen}
          onClick={(event) => {
            if (event.target !== event.currentTarget) return;
            handleDismiss();
          }}
        >
          <CSSTransition in={isOpen} mountOnEnter unmountOnExit appear timeout={200} classNames="modal">
            <Modal>
              <ModalClose onClick={() => handleDismiss()}>
                <FontAwesomeIcon icon={faTimes} color="rgba(255, 255, 255, 0.5)" size={'lg'} />
              </ModalClose>
              {content}
            </Modal>
          </CSSTransition>
        </ModalBackdrop>
      {/*</CSSTransition>*/}
    </Context.Provider>
  );
};

interface ModalBackdropProps {
  show: boolean;
}

const ModalBackdrop = styled.div<ModalBackdropProps>`
  display: ${(props) => props.show ? 'flex' : 'none'};
  position: fixed;
  background-color: #00000088;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  overflow-y: auto;
`;

const Modal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  width: fit-content;
  max-width: 335px;
  
  ${media.up('sm')} {
    max-width: 570px;
  }
`

const ModalClose = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  padding: 20px;
  background-color: black;
  border-bottom-left-radius: 4px;
  cursor: pointer;
  
  ${media.up('sm')} {
    display: none;
  }
`

const ContentWrapper = styled.div<{ blurred: boolean }>`
  filter: blur(${(props) => props.blurred ? '8px' : '0px'}); 
  transition: filter ${(props) => props.blurred ? '200ms' : '0'};
  
  ${media.up('sm')} {
    filter: blur(${(props) => props.blurred ? '4px' : '0px'});
  }
`

export default ModalProvider;
