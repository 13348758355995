const CreamETH2ABI = [
  {
    "inputs":[
      {
        "internalType":"address",
        "name":"_oracle",
        "type":"address"
      },
      {
        "internalType":"uint256",
        "name":"_cap",
        "type":"uint256"
      }
    ],
    "stateMutability":"nonpayable",
    "type":"constructor"
  },
  {
    "anonymous":false,
    "inputs":[
      {
        "indexed":true,
        "internalType":"address",
        "name":"owner",
        "type":"address"
      },
      {
        "indexed":true,
        "internalType":"address",
        "name":"spender",
        "type":"address"
      },
      {
        "indexed":false,
        "internalType":"uint256",
        "name":"value",
        "type":"uint256"
      }
    ],
    "name":"Approval",
    "type":"event"
  },
  {
    "anonymous":false,
    "inputs":[
      {
        "indexed":false,
        "internalType":"address",
        "name":"account",
        "type":"address"
      },
      {
        "indexed":false,
        "internalType":"uint256",
        "name":"ethAmount",
        "type":"uint256"
      },
      {
        "indexed":false,
        "internalType":"uint256",
        "name":"creth2Amount",
        "type":"uint256"
      }
    ],
    "name":"DepositEvent",
    "type":"event"
  },
  {
    "anonymous":false,
    "inputs":[
      {
        "indexed":false,
        "internalType":"bytes",
        "name":"pubkey",
        "type":"bytes"
      },
      {
        "indexed":false,
        "internalType":"bytes",
        "name":"withdrawal_credentials",
        "type":"bytes"
      },
      {
        "indexed":false,
        "internalType":"bytes",
        "name":"signature",
        "type":"bytes"
      }
    ],
    "name":"StakeEvent",
    "type":"event"
  },
  {
    "anonymous":false,
    "inputs":[
      {
        "indexed":true,
        "internalType":"address",
        "name":"from",
        "type":"address"
      },
      {
        "indexed":true,
        "internalType":"address",
        "name":"to",
        "type":"address"
      },
      {
        "indexed":false,
        "internalType":"uint256",
        "name":"value",
        "type":"uint256"
      }
    ],
    "name":"Transfer",
    "type":"event"
  },
  {
    "anonymous":false,
    "inputs":[
      {
        "indexed":false,
        "internalType":"address",
        "name":"account",
        "type":"address"
      },
      {
        "indexed":false,
        "internalType":"uint256",
        "name":"ethAmount",
        "type":"uint256"
      },
      {
        "indexed":false,
        "internalType":"uint256",
        "name":"creth2Amount",
        "type":"uint256"
      }
    ],
    "name":"WithdrawEvent",
    "type":"event"
  },
  {
    "inputs":[

    ],
    "name":"VALIDATOR_AMOUNT",
    "outputs":[
      {
        "internalType":"uint256",
        "name":"",
        "type":"uint256"
      }
    ],
    "stateMutability":"view",
    "type":"function"
  },
  {
    "inputs":[

    ],
    "name":"accumulated",
    "outputs":[
      {
        "internalType":"uint256",
        "name":"",
        "type":"uint256"
      }
    ],
    "stateMutability":"view",
    "type":"function"
  },
  {
    "inputs":[

    ],
    "name":"admin",
    "outputs":[
      {
        "internalType":"address",
        "name":"",
        "type":"address"
      }
    ],
    "stateMutability":"view",
    "type":"function"
  },
  {
    "inputs":[
      {
        "internalType":"address",
        "name":"owner",
        "type":"address"
      },
      {
        "internalType":"address",
        "name":"spender",
        "type":"address"
      }
    ],
    "name":"allowance",
    "outputs":[
      {
        "internalType":"uint256",
        "name":"",
        "type":"uint256"
      }
    ],
    "stateMutability":"view",
    "type":"function"
  },
  {
    "inputs":[
      {
        "internalType":"address",
        "name":"spender",
        "type":"address"
      },
      {
        "internalType":"uint256",
        "name":"amount",
        "type":"uint256"
      }
    ],
    "name":"approve",
    "outputs":[
      {
        "internalType":"bool",
        "name":"",
        "type":"bool"
      }
    ],
    "stateMutability":"nonpayable",
    "type":"function"
  },
  {
    "inputs":[
      {
        "internalType":"address",
        "name":"account",
        "type":"address"
      }
    ],
    "name":"balanceOf",
    "outputs":[
      {
        "internalType":"uint256",
        "name":"",
        "type":"uint256"
      }
    ],
    "stateMutability":"view",
    "type":"function"
  },
  {
    "inputs":[

    ],
    "name":"breaker",
    "outputs":[
      {
        "internalType":"bool",
        "name":"",
        "type":"bool"
      }
    ],
    "stateMutability":"view",
    "type":"function"
  },
  {
    "inputs":[

    ],
    "name":"cap",
    "outputs":[
      {
        "internalType":"uint256",
        "name":"",
        "type":"uint256"
      }
    ],
    "stateMutability":"view",
    "type":"function"
  },
  {
    "inputs":[

    ],
    "name":"decimals",
    "outputs":[
      {
        "internalType":"uint8",
        "name":"",
        "type":"uint8"
      }
    ],
    "stateMutability":"view",
    "type":"function"
  },
  {
    "inputs":[
      {
        "internalType":"address",
        "name":"spender",
        "type":"address"
      },
      {
        "internalType":"uint256",
        "name":"subtractedValue",
        "type":"uint256"
      }
    ],
    "name":"decreaseAllowance",
    "outputs":[
      {
        "internalType":"bool",
        "name":"",
        "type":"bool"
      }
    ],
    "stateMutability":"nonpayable",
    "type":"function"
  },
  {
    "inputs":[

    ],
    "name":"deposit",
    "outputs":[

    ],
    "stateMutability":"payable",
    "type":"function"
  },
  {
    "inputs":[

    ],
    "name":"eth2DepositContract",
    "outputs":[
      {
        "internalType":"contract IDepositContract",
        "name":"",
        "type":"address"
      }
    ],
    "stateMutability":"view",
    "type":"function"
  },
  {
    "inputs":[
      {
        "internalType":"address",
        "name":"spender",
        "type":"address"
      },
      {
        "internalType":"uint256",
        "name":"addedValue",
        "type":"uint256"
      }
    ],
    "name":"increaseAllowance",
    "outputs":[
      {
        "internalType":"bool",
        "name":"",
        "type":"bool"
      }
    ],
    "stateMutability":"nonpayable",
    "type":"function"
  },
  {
    "inputs":[
      {
        "internalType":"uint256",
        "name":"_cap",
        "type":"uint256"
      }
    ],
    "name":"increaseCap",
    "outputs":[

    ],
    "stateMutability":"nonpayable",
    "type":"function"
  },
  {
    "inputs":[

    ],
    "name":"name",
    "outputs":[
      {
        "internalType":"string",
        "name":"",
        "type":"string"
      }
    ],
    "stateMutability":"view",
    "type":"function"
  },
  {
    "inputs":[

    ],
    "name":"oracle",
    "outputs":[
      {
        "internalType":"contract IOracle",
        "name":"",
        "type":"address"
      }
    ],
    "stateMutability":"view",
    "type":"function"
  },
  {
    "inputs":[
      {
        "internalType":"address",
        "name":"_admin",
        "type":"address"
      }
    ],
    "name":"setAdmin",
    "outputs":[

    ],
    "stateMutability":"nonpayable",
    "type":"function"
  },
  {
    "inputs":[
      {
        "internalType":"bool",
        "name":"_breaker",
        "type":"bool"
      }
    ],
    "name":"setBreaker",
    "outputs":[

    ],
    "stateMutability":"nonpayable",
    "type":"function"
  },
  {
    "inputs":[
      {
        "internalType":"address",
        "name":"_oracle",
        "type":"address"
      }
    ],
    "name":"setOracle",
    "outputs":[

    ],
    "stateMutability":"nonpayable",
    "type":"function"
  },
  {
    "inputs":[
      {
        "internalType":"bytes[]",
        "name":"pubkeys",
        "type":"bytes[]"
      },
      {
        "internalType":"bytes[]",
        "name":"withdrawal_credentials",
        "type":"bytes[]"
      },
      {
        "internalType":"bytes[]",
        "name":"signatures",
        "type":"bytes[]"
      },
      {
        "internalType":"bytes32[]",
        "name":"deposit_data_roots",
        "type":"bytes32[]"
      }
    ],
    "name":"stake",
    "outputs":[

    ],
    "stateMutability":"payable",
    "type":"function"
  },
  {
    "inputs":[

    ],
    "name":"symbol",
    "outputs":[
      {
        "internalType":"string",
        "name":"",
        "type":"string"
      }
    ],
    "stateMutability":"view",
    "type":"function"
  },
  {
    "inputs":[

    ],
    "name":"totalSupply",
    "outputs":[
      {
        "internalType":"uint256",
        "name":"",
        "type":"uint256"
      }
    ],
    "stateMutability":"view",
    "type":"function"
  },
  {
    "inputs":[
      {
        "internalType":"address",
        "name":"recipient",
        "type":"address"
      },
      {
        "internalType":"uint256",
        "name":"amount",
        "type":"uint256"
      }
    ],
    "name":"transfer",
    "outputs":[
      {
        "internalType":"bool",
        "name":"",
        "type":"bool"
      }
    ],
    "stateMutability":"nonpayable",
    "type":"function"
  },
  {
    "inputs":[
      {
        "internalType":"address",
        "name":"sender",
        "type":"address"
      },
      {
        "internalType":"address",
        "name":"recipient",
        "type":"address"
      },
      {
        "internalType":"uint256",
        "name":"amount",
        "type":"uint256"
      }
    ],
    "name":"transferFrom",
    "outputs":[
      {
        "internalType":"bool",
        "name":"",
        "type":"bool"
      }
    ],
    "stateMutability":"nonpayable",
    "type":"function"
  },
  {
    "inputs":[
      {
        "internalType":"uint256",
        "name":"creth2Amount",
        "type":"uint256"
      }
    ],
    "name":"withdraw",
    "outputs":[

    ],
    "stateMutability":"nonpayable",
    "type":"function"
  }
];

export default CreamETH2ABI;
