import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ethers } from 'ethers';
import React, { useCallback, useContext, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { arbitrumEthAddress, avaxAddress, maticAddress } from '../../cream/constants';
import { BscProtocol } from '../../cream/Protocols';
import {
  commify,
  displayFactor,
  rateToApy,
  tokenPrice,
  displayChange,
  displayRewards,
  displayBalance,
  underlyingBalance,
  nativeBalanceToUsd,
  getExpectedBorrowLimit, sameAddress, distributionApy, totalSupplyInUsd
} from '../../cream/utils';
import useApprove, { ApproveState } from '../../hooks/useApprove';
import useCream from '../../hooks/useCream';
import useIsNativeContract from '../../hooks/useIsNativeContract';
import useMarketData from '../../hooks/useMarketData';
import useModal from '../../hooks/useModal';
import useUserBorrowSummary from '../../hooks/useUserBorrowSummary';
import { MarketContext } from '../../providers/MarketProvider';
import { ProtocolContext } from '../../providers/ProtocolProvider';
import { ConnectionContext } from '../../providers/ConnectionProvider';
import { TxContext } from '../../providers/TxProvider';
import BalanceInput from '../BalanceInput';
import { Button, ClaimButton, FullWidthButton, NumberedButton } from '../Button/Button';
import ModalInfo from './components/ModalInfo';
import ActionModal, { Action } from './components/ActionModal';
import ButtonGroup from './components/ButtonGroup';
import WarningText from './components/WarningText';
import { useTranslation } from "react-i18next";
import InfoIcon from '../InfoIcon';

interface SupplyWithdrawModalProps {
  marketAddress: string;
}

const SupplyWithdrawModal: React.FC<SupplyWithdrawModalProps> = ({ marketAddress }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const actions: [Action, Action] = [
    {
      title: t('Supply'),
      component: <Supply marketAddress={marketAddress} />,
      themeColor: theme.colors.creamGreen,
    },
    {
      title: t('Withdraw'),
      component: <Withdraw marketAddress={marketAddress} />,
      themeColor: theme.colors.creamPink,
    }
  ];

  return (
    <ActionModal bgColor={theme.colors.supplyCardBackground} actions={actions} marketAddress={marketAddress} />
  )
}

interface SupplyProps {
  marketAddress: string;
}

const Supply: React.FC<SupplyProps> = ({ marketAddress }) => {
  const { creamGreen } = useTheme().colors;
  const { basePrice } = useContext(MarketContext);
  const { t } = useTranslation();
  const { protocol } = useContext(ProtocolContext);
  const { addTx } = useContext(TxContext);

  const cream = useCream();
  const [market, marketStats, userTokenStats, userLiquidityRewards, lmRewardStats] = useMarketData(marketAddress);
  const { approveState, approveAll, allowance } = useApprove(market);
  const isNative = useIsNativeContract(market.address);
  const userBorrowSummary = useUserBorrowSummary();
  const { connected, walletAddress } = useContext(ConnectionContext);
  const borrowLimitInUsd = nativeBalanceToUsd(userBorrowSummary.borrowLimitInNative, userBorrowSummary.basePrice, 2);

  const [amount, setAmount] = useState('0');
  const supplyAmount = ethers.utils.parseUnits(amount, market.underlyingDecimal);
  const newBorrowLimit = getExpectedBorrowLimit(userBorrowSummary, marketStats, userTokenStats, supplyAmount, true);
  const liquidityRewardsToken = protocol === BscProtocol ? 'CAKE' : 'SUSHI';
  const collateralBalance = underlyingBalance(userTokenStats.collateralBalance, marketStats.exchangeRate);
  const registerCollateralReady = !userTokenStats.collateralBalance.eq(userTokenStats.crTokenBalance);
  const totalSupply = totalSupplyInUsd(marketStats.supply, marketStats.underlyingPrice, marketStats.exchangeRate, basePrice);

  const { dismissModal } = useModal();

  const supply = useCallback(async () => {
    const response = await cream?.supply(market, supplyAmount, isNative);
    if (response) {
      addTx(response.hash, `Supply ${commify(amount)} ${market.underlyingSymbol}`);
      dismissModal();
    }
  }, [supplyAmount, market, isNative, cream, amount, addTx, dismissModal]);

  const claimRewards = useCallback(async () => {
    if (walletAddress) {
      const response = await cream?.claimLiquidityRewards(market, walletAddress);
      if (response) {
        addTx(response.hash, `Claim ${liquidityRewardsToken} Rewards`);
        dismissModal();
      }
    }
  }, [market, walletAddress, cream, liquidityRewardsToken, addTx, dismissModal]);

  const registerCollateral = useCallback(async () => {
    const response = await cream?.enableCollateral(marketAddress);
    if (response) {
      addTx(response.hash, `Register collateral`);
      dismissModal();
    }
  }, [cream, addTx, dismissModal, marketAddress]);

  let errorMessage = '';
  let supplyReady = true;
  if (supplyAmount.lte(0)) {
    if (supplyAmount.lt(0)) {
      // Invalid supply amount.
      errorMessage = t('Invalid Amount');
    }
    supplyReady = false;
  } else if (userTokenStats.walletBalance.lt(supplyAmount)) {
    // Wallet balance is less than supply amount.
    errorMessage = t('Insufficient Balance');
    supplyReady = false;
  } else if (allowance.lt(supplyAmount)) {
    // Insufficient allowance.
    errorMessage = t('Insufficient Allowance');
    supplyReady = false;
  } else if (marketStats.supplyCap.gt(0)) {
    const totalSupply = marketStats.cash.add(marketStats.borrow).sub(marketStats.reserves);
    const supplyQuota = marketStats.supplyCap.sub(totalSupply);
    if (supplyQuota.lt(supplyAmount)) {
      // Supply cap reached.
      errorMessage = t('Supply Cap Exceeded');
      supplyReady = false;
    }
  }

  const infos = [
    {
      title: (
        <div>
          {t('Collateral Factor')}
        </div>
      ),
      value: (
        <div>
          {displayFactor(marketStats.collateralFactor)}
        </div>
      ),
    },
    {
      title: t('Used as Collateral'),
      value: userTokenStats.collateralEnabled ? 'Yes' : 'No',
    }
  ];
  if (marketStats.version === 1 && marketStats.collateralCap.gt(0)) {
    const collateralRemainBalance = underlyingBalance(
      marketStats.collateralCap.sub(marketStats.totalCollateralTokens),
      marketStats.exchangeRate
    );

    infos.push(
      {
        title: (<div>{t('Collateral Balance')}</div>),
        value: (
          <div>
            {displayBalance(collateralBalance, market.underlyingDecimal, 6)} {market.underlyingSymbol}
          </div>
        ),
      },
      {
        title: (
          <InfoIcon
            text={t('Collateral Remain')}
            url='https://forum.cream.finance/t/lending-faq-collateral/1687'
            color={creamGreen}
          />
        ),
        value: (
          <div>
            {collateralRemainBalance.gt(0) ? (
              <div>
                ~ {displayBalance(collateralRemainBalance, market.underlyingDecimal, 2)} {market.underlyingSymbol}
                <ClaimButton disabled={!registerCollateralReady}
                              onClick={() => registerCollateral()}>
                  {t('Register')}
                </ClaimButton>
              </div>
            ) : (
              <div>{t('No Buffer')}</div>
            )}
          </div>
        ),
      },
    );
  }

  return (
    <>
      <BalanceInput title={t('SUPPLY AMOUNT ALL CAP')}
                    subtitle={t('Wallet Balance')}
                    maxBalance={userTokenStats.walletBalance}
                    symbol={market.underlyingSymbol}
                    decimals={market.underlyingDecimal}
                    usdRate={tokenPrice(marketStats.underlyingPrice, market.underlyingDecimal, userBorrowSummary.basePrice)}
                    themeColor={creamGreen}
                    disabled={approveState !== ApproveState.APPROVED || !connected}
                    onChange={setAmount}
                    errorMessage={errorMessage}
      />
      {sameAddress(market.address, maticAddress) && (
        <ModalInfo title={t('INFO')} indent={false} infos={[
          {
            title: <WrappedTokenExchange symbol={'MATIC'} wrappedSymbol={'WMATIC'} />,
            value: (
              <Button onClick={() => {
                window.open(`https://quickswap.exchange/#/swap?outputCurrency=0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270`, '_blank')?.focus();
              }}>
                {t('Convert')}
              </Button>
            )
          }
        ]} />
      )}
      {sameAddress(market.address, arbitrumEthAddress) && (
        <ModalInfo title={t('INFO')} indent={false} infos={[
          {
            title: <WrappedTokenExchange symbol={'ETH'} wrappedSymbol={'WETH'} />,
            value: (
              <Button onClick={() => {
                window.open(`https://app.uniswap.org/#/swap?outputCurrency=0x82aF49447D8a07e3bd95BD0d56f35241523fBab1&inputCurrency=ETH`, '_blank')?.focus();
              }}>
                {t('Convert')}
              </Button>
            )
          }
        ]} />
      )}
      {sameAddress(market.address, avaxAddress) && (
        <ModalInfo title={t('INFO')} indent={false} infos={[
          {
            title: <WrappedTokenExchange symbol={'AVAX'} wrappedSymbol={'WAVAX'} />,
            value: (
              <Button onClick={() => {
                window.open(`https://app.pangolin.exchange/#/swap?outputCurrency=0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7`, '_blank')?.focus();
              }}>
                {t('Convert')}
              </Button>
            )
          }
        ]} />
      )}
      <ModalInfo title={t('SUPPLY STATS')} indent={false} infos={[
        {
          title: t('SUPPLY APY'),
          value: rateToApy(marketStats.supplyRate, protocol.blocksPerYear),
        },
        {
          title: t('Supply Balance'),
          value: (
            <div>
              {displayBalance(userTokenStats.underlyingBalance, market.underlyingDecimal, 6)} {market.underlyingSymbol}
            </div>
          ),
        },
        {
          title: t('Reward APY'),
          value: (
            <div>
              {distributionApy(lmRewardStats.rewardSpeeds, totalSupply, true)}
            </div>
          ),
        },
      ]} />
      <ModalInfo title={t('COLLATERAL')} indent={false} infos={infos} />
      {approveState === ApproveState.APPROVED && <ModalInfo title={t('BORROW LIMIT')} indent={true} infos={[
        {
          title: t('Your Borrow Limit'),
          value: displayChange(borrowLimitInUsd, newBorrowLimit.newBorrowLimitInUsd),
        },
        {
          title: t('Borrow Limit Used'),
          value: displayChange(userBorrowSummary.borrowLimitPct, newBorrowLimit.newBorrowLimitPct),
        },
      ]} />}
      {userLiquidityRewards.reward.gt(0) && <ModalInfo title={t('REWARDS')} indent={false} infos={[
        {
          title: t('Liquidity Rewards'),
          value: (
            <div>
              {displayRewards(userLiquidityRewards.reward, liquidityRewardsToken)}
              <ClaimButton onClick={() => claimRewards()}>{t('Claim')}</ClaimButton>
            </div>
          ),
        },
      ]} />}
      {marketStats.supplyPaused ? (
        <WarningText className="fw-bold">Supplying for {market.underlyingSymbol} is currently disabled.</WarningText>
      ) : (
        <StyledButtonGroup>
          {isNative ? (
            <FullWidthButton onClick={() => supply()}
                             bgColor={creamGreen}
                             textColor="black"
                             disabled={approveState !== ApproveState.APPROVED || !supplyReady || !connected}>
              {t('Supply')}
            </FullWidthButton>
          ) : (
            <>
              <NumberedButton
                bgColor={creamGreen}
                textColor="black"
                disabled={approveState === ApproveState.APPROVED || !connected}
                onClick={() => approveAll()}
                number={1}
              >
                {t('Approve')}
              </NumberedButton>
              <NumberedButton
                bgColor={creamGreen}
                textColor="black"
                disabled={approveState !== ApproveState.APPROVED || !supplyReady || !connected}
                onClick={() => supply()}
                number={2}
              >
                {t('Supply')}
              </NumberedButton>
            </>
          )}
        </StyledButtonGroup>
      )}
    </>
  )
};

interface BorrowProps {
  marketAddress: string;
}

const Withdraw: React.FC<BorrowProps> = ({ marketAddress }) => {
  const { creamPink } = useTheme().colors;
  const { protocol } = useContext(ProtocolContext);
  const { connected } = useContext(ConnectionContext);
  const { t } = useTranslation();
  const { addTx } = useContext(TxContext);
  const { dismissModal } = useModal();

  const cream = useCream();
  const [market, marketStats, userTokenStats] = useMarketData(marketAddress);
  const isNative = useIsNativeContract(market.address);

  const [amount, setAmount] = useState('0');
  const withdrawAmount = ethers.utils.parseUnits(amount, market.underlyingDecimal);

  const userBorrowSummary = useUserBorrowSummary();
  const borrowLimitInUsd = nativeBalanceToUsd(userBorrowSummary.borrowLimitInNative, userBorrowSummary.basePrice, 2);
  const newBorrowLimit = getExpectedBorrowLimit(userBorrowSummary, marketStats, userTokenStats, withdrawAmount, false);

  const withdraw = useCallback(async () => {
    let response;
    if (withdrawAmount.eq(userTokenStats.underlyingBalance)) {
      // Withdraw full amount.
      response = await cream?.redeem(market, userTokenStats.crTokenBalance, isNative);
    } else {
      response = await cream?.redeemUnderlying(market, withdrawAmount, isNative);
    }
    if (response) {
      addTx(response.hash, `Withdraw ~${commify(amount)} ${market.underlyingSymbol}`);
      dismissModal();
    }
  }, [userTokenStats, withdrawAmount, amount, market, isNative, cream, addTx, dismissModal]);

  let errorMessage = '';
  let withdrawReady = true;
  if (withdrawAmount.lte(0)) {
    if (withdrawAmount.lt(0)) {
      // Invalid withdraw amount.
      errorMessage = t('Invalid Amount');
    }
    withdrawReady = false;
  } else if (userTokenStats.underlyingBalance.lt(withdrawAmount)) {
    // Protocol balance is less than withdraw amount.
    errorMessage = t('Insufficient Balance');
    withdrawReady = false;
  } else if (newBorrowLimit.newBorrowBalanceInNative.gt(newBorrowLimit.newBorrowLimitInNative)) {
    // Borrow limit is fully used.
    errorMessage = t('Insufficient Collateral');
    withdrawReady = false;
  } else if (marketStats.cash.lt(withdrawAmount)) {
    // Insufficient cash.
    errorMessage = t('Insufficient Liquidity');
    withdrawReady = false;
  }

  return (
    <>
      <BalanceInput title={t('WITHDRAW AMOUNT')}
                    subtitle={t('Supply Balance')}
                    maxBalance={userTokenStats.underlyingBalance}
                    symbol={market.underlyingSymbol}
                    decimals={market.underlyingDecimal}
                    usdRate={tokenPrice(marketStats.underlyingPrice, market.underlyingDecimal, userBorrowSummary.basePrice)}
                    themeColor={creamPink}
                    disabled={false}
                    onChange={setAmount}
                    errorMessage={errorMessage}
      />
      <ModalInfo title={t('SUPPLY STATS')} indent={false} infos={[
        {
          title: t('SUPPLY APY'),
          value: rateToApy(marketStats.supplyRate, protocol.blocksPerYear),
        },
        {
          title: t('Supply Balance'),
          value: (
            <div>
              {displayBalance(userTokenStats.underlyingBalance, market.underlyingDecimal, 6)} {market.underlyingSymbol}
            </div>
          ),
        },
      ]} />
      <ModalInfo title={t('BORROW LIMIT')} indent={true} infos={[
        {
          title: t('Your Borrow Limit'),
          value: displayChange(borrowLimitInUsd, newBorrowLimit.newBorrowLimitInUsd),
        },
        {
          title: t('Borrow Limit Used'),
          value: displayChange(userBorrowSummary.borrowLimitPct, newBorrowLimit.newBorrowLimitPct),
        },
      ]} />
      <StyledButtonGroup>
        <FullWidthButton bgColor={creamPink}
                         textColor="black"
                         disabled={!withdrawReady || !connected}
                         onClick={() => withdraw()}>
          {t('Withdraw')}
        </FullWidthButton>
      </StyledButtonGroup>
    </>
  )
}

const StyledButtonGroup = styled(ButtonGroup)`
  margin-top: 100px;
`

interface WrappedTokenExchangeProps {
  symbol: string;
  wrappedSymbol: string;
}

const WrappedTokenExchange: React.FC<WrappedTokenExchangeProps> = ({ symbol, wrappedSymbol }) => {
  return (
    <>
      {symbol}
      <FontAwesomeIcon icon={faExchangeAlt} color="white" style={{ margin: '0 8px' }} />
      {wrappedSymbol}
    </>
  )
}

export default SupplyWithdrawModal;
