import React, { useContext } from 'react';
import styled, { useTheme } from 'styled-components';
import { ConnectionContext } from '../../providers/ConnectionProvider';
import EYNTK from './components/EYNTK';
import SupplyMarket from './components/SupplyMarket';
import UserSupplyMarket from './components/UserSupplyMarket';
import UserBorrowMarket from './components/UserBorrowMarket';
import { Col, Container, Row } from 'react-bootstrap';
import BorrowMarket from './components/BorrowMarket';
import { useTranslation } from 'react-i18next';
import { NavLink, useParams } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import { MediaType } from '../../media';
import media from '../../media';

const Lending: React.FC = () => {
  if (useMediaQuery({ maxWidth: MediaType.md })) {
    return <MobileLending />
  } else {
    return <PcLending />
  }
};

const PcLending: React.FC = () => {
  const { connected } = useContext(ConnectionContext);
  return (
    <Container>
      {connected && (
        <Row className="gy-3">
          <Col sm="12" lg="6">
            <UserSupplyMarket />
          </Col>
          <Col sm="12" lg="6">
            <UserBorrowMarket />
          </Col>
        </Row>
      )}
      <MarketsRow className="gy-3">
        <Col sm="12" lg="6">
          <EYNTK side="supply" />
          <SupplyMarket />
        </Col>
        <Col sm="12" lg="6">
          <EYNTK side="borrow" />
          <BorrowMarket />
        </Col>
      </MarketsRow>
    </Container>
  )
}

const MobileLending: React.FC = () => {
  const { connected } = useContext(ConnectionContext);
  const theme = useTheme();
  const { t } = useTranslation();
  const { type } = useParams() as any;
  const isSupply = type !== 'borrow'

  return (
    <Container>
      <Tabs>
        <Tab active={isSupply} backgroundColor={theme.colors.primary}>
          <NavLink exact to="/lending/supply">{t('Supply')}</NavLink>
        </Tab>
        <Tab active={!isSupply} backgroundColor={theme.colors.secondary}>
          <NavLink exact to="/lending/borrow">{t('Borrow')}</NavLink>
        </Tab>
      </Tabs>
      {connected && (
        <MarketsRow className="gy-3">
          <Col sm="12">
            {isSupply ? (
              <UserSupplyMarket />
            ) : (
              <UserBorrowMarket />
            )}
          </Col>
        </MarketsRow>
      )}
      <MarketsRow className="gy-3">
        <Col sm="12">
          <EYNTK side={isSupply ? 'supply' : 'borrow'} />
          {isSupply ? (
            <SupplyMarket />
          ) : (
            <BorrowMarket />
          )}
        </Col>
      </MarketsRow>
    </Container>
  )
}

const MarketsRow = styled(Row)`
  margin-top: 26px;
  margin-bottom: 60px;
  ${media.down('sm')} {
    margin: 0;
    &:last-child {
      margin-bottom: 40px;
    }
  }
`

const Tabs = styled.div`
  display: flex;
  height: 36px;
  border-radius: 4px;
  overflow: hidden;
  margin: 12px;
`;

const Tab = styled.div<{active: boolean, backgroundColor: string}>`
  background: ${(props) => props.backgroundColor + (props.active ? 'FF' : '33')};
  font-weight: 500;
  font-size: 14px;
  height: 100%;
  width: 50%;
  a {
    color: ${(props) => props.active ? '#000000' : '#E5E5E5'};
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }
`;

export default Lending;
