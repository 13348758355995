const mediaHelper = require('styled-media-helper');

export enum MediaType {
  sm = 576,
  md = 768,
  lg = 992,
  xl = 1200,
  xxl = 1400
}

const media = mediaHelper({
  sm: MediaType.sm,
  md: MediaType.md,
  lg: MediaType.lg,
  xl: MediaType.xl,
  xxl: MediaType.xxl,
})

export default media;
