import React, { useContext } from 'react';
import styled from 'styled-components';
import { ConnectionContext } from '../../../providers/ConnectionProvider';
import { Button } from '../../Button/Button';
import TxButton from './TxButton';

const Wallet: React.FC = () => {
  const { connected, connectWallet } = useContext(ConnectionContext);

  const button = connected ? (
    <TxButton />
  ) : (
    <ConnectButton onClick={() => connectWallet()}>Connect</ConnectButton>
  )

  return button;
};

const ConnectButton = styled(Button)`
  color: ${(props) => props.theme.colors.creamGreen};
  background-color: ${(props) => props.theme.colors.supplyCardBackground};
`

export default Wallet;
