import React, { useContext } from 'react';
import { Context } from '../components/Modal';

const useModal = () => {
  const { onPresent, onDismiss } = useContext(Context);

  const presentModal = (modal: React.ReactNode) => {
    onPresent(modal);
  };

  const dismissModal = () => {
    onDismiss();
  }

  return {
    presentModal,
    dismissModal,
  }
};

export default useModal;
