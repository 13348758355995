import { BigNumber } from 'ethers';
import React, { useCallback, useContext, useMemo } from 'react';
import { useTheme } from 'styled-components';
import SupplyWithdrawModal from '../../../components/Modal/SupplyWithdrawModal';
import {
  displayBalance,
  rateToApy,
  nativeBalanceToUsd,
  getExpectedBorrowLimit,
  underlyingBalance, distributionApy, totalSupplyInUsd
} from '../../../cream/utils';
import useCream from '../../../hooks/useCream';
import useModal from '../../../hooks/useModal';
import useUserSupplySummary from '../../../hooks/useUserSupplySummary';
import useUserBorrowSummary from '../../../hooks/useUserBorrowSummary';
import { MarketContext } from '../../../providers/MarketProvider';
import { ProtocolContext } from '../../../providers/ProtocolProvider';
import { TxContext } from '../../../providers/TxProvider';
import Header from './Header';
import MarketTable from './MarketTable';
import UserMarket from './UserMarket';
import TokenIconSymbol from '../../../components/TokenIconSymbol';
import { IDataTableColumn } from 'react-data-table-component';
import Switch from 'react-switch';
import { useTranslation } from "react-i18next";

type UserSupplyMarketData = {
  asset: string;
  address: string;
  apy: string;
  distributionApy: string;
  supplyRate: BigNumber;
  balance: string;
  collateral: boolean;
  collateralBalance: string;
  disabled: boolean;
}

const useUserSupplyMarketData = (): UserSupplyMarketData[] => {
  const { markets, allMarketStats, allUserTokenStats, lmRewardsStats, basePrice } = useContext(MarketContext);
  const { protocol } = useContext(ProtocolContext);
  const userBorrowSummary = useUserBorrowSummary();

  const data: UserSupplyMarketData[] = useMemo(() => {
    const data = [];
    for (const i in allUserTokenStats) {
      const userTokenStats = allUserTokenStats[i];
      const marketStats = allMarketStats[i];
      const market = markets[i];

      if (userTokenStats.crTokenBalance.eq(0)) {
        continue;
      }

      const newBorrowLimitForWithdrawFull = getExpectedBorrowLimit(userBorrowSummary, marketStats, userTokenStats, userTokenStats.underlyingBalance, false);
      const disabled = marketStats.collateralFactor.eq(0) || // 0 collateral factor
                       userTokenStats.borrowBalance.gt(newBorrowLimitForWithdrawFull.newBorrowLimitInNative); // borrow limit check
      const underlyingCollateralBalance = underlyingBalance(userTokenStats.collateralBalance, marketStats.exchangeRate);

      const speeds = lmRewardsStats[i].rewardSpeeds;
      const totalSupply = totalSupplyInUsd(marketStats.supply, marketStats.underlyingPrice, marketStats.exchangeRate, basePrice);

      data.push({
        asset: market.underlyingSymbol,
        address: market.address,
        apy: rateToApy(marketStats.supplyRate, protocol.blocksPerYear),
        distributionApy: distributionApy(speeds, totalSupply, true),
        supplyRate: marketStats.supplyRate,
        balance: displayBalance(userTokenStats.underlyingBalance, market.underlyingDecimal, 2),
        collateralBalance: displayBalance(underlyingCollateralBalance, market.underlyingDecimal, 2),
        collateral: userTokenStats.collateralEnabled,
        disabled
      })
    }
    return data;
  }, [allUserTokenStats, allMarketStats, markets, userBorrowSummary, lmRewardsStats, basePrice, protocol]);

  return data;
}

interface UserSupplyMarketProps {
  showCollateralBalance?: boolean;
}

export const UserSupplyMarket: React.FC<UserSupplyMarketProps> = ({ showCollateralBalance }) => {
  const { t } = useTranslation();
  const userSupplySummary = useUserSupplySummary();
  const totalSupplyBalanceUSD = nativeBalanceToUsd(userSupplySummary.totalSupplyBalanceInNative, userSupplySummary.basePrice, 2);

  const columns: IDataTableColumn<UserSupplyMarketData>[] = [
    {
      name: t('SUPPLIED ASSET'),
      selector: 'asset',
      sortable: true,
      cell: (row) => <TokenIconSymbol data-tag="allowRowEvents" symbol={row.asset} />,
      grow: 100, // force grow
    },
    {
      name: t('APY / EARNED'),
      selector: 'apy',
      sortable: true,
      sortFunction: (a, b) => { return a.supplyRate.toNumber() - b.supplyRate.toNumber() },
      right: true,
    },
    {
      name: t('Reward APY'),
      selector: 'distributionApy',
      cell: (row) => <div data-tag="allowRowEvents" style={{ whiteSpace: 'nowrap' }}>{row.distributionApy}</div>,
      right: true,
    },
    {
      name: t('BALANCE'),
      selector: 'balance',
      cell: (row) => <div data-tag="allowRowEvents" style={{ whiteSpace: 'nowrap' }}>{row.balance}</div>,
      right: true,
    },
    {
      name: t('COLLATERAL'),
      selector: 'collateral',
      right: true,
      cell: (row) => (
        <CollateralSwitch
          enabled={row.collateral}
          marketAddress={row.address}
          disabled={row.disabled}
        />
      ),
    },
  ];

  if (showCollateralBalance) {
    columns.splice(3, 0, {
      name: t('COLLATERAL BALANCE'),
      selector: 'collateralBalance',
      cell: (row) => <div>{row.collateralBalance}</div>,
      right: true,
    })
  }

  const { presentModal } = useModal();
  const onRowClicked = (row: UserSupplyMarketData) => {
    presentModal(<SupplyWithdrawModal marketAddress={row.address} />);
  }

  const data = useUserSupplyMarketData();
  const theme = useTheme();

  const table = <MarketTable columns={columns} data={data} keyField="address"
                             onRowClicked={onRowClicked}
                             noDataComponent={t("NO SUPPLIED ASSETS")} />
  const leftHeader = <Header align="left" title={t('TOTAL SUPPLY BALANCE')} value={totalSupplyBalanceUSD} valueColor={theme.colors.primary} />;
  const rightHeader = <Header align="right" title={t('NET APY')} value={userSupplySummary.netAPY} />;

  return (
    <UserMarket leftHeader={leftHeader} rightHeader={rightHeader} table={table} tableBackgroundColor={theme.colors.supplyCardBackground} />
  );
};

interface CollateralSwitchProps {
  enabled: boolean; // switch is on or off
  marketAddress: string;
  disabled: boolean; // switch is disabled or not
}

const CollateralSwitch: React.FC<CollateralSwitchProps> = ({ enabled, marketAddress, disabled }) => {
  const theme = useTheme();

  const cream = useCream();
  const { addTx } = useContext(TxContext);

  const toggleEnableCollateral = useCallback(async () => {
    if (!cream) return;
    if (enabled) {
      const response = await cream.disableCollateral(marketAddress);
      addTx(response.hash, `Disable collateral`)
    } else {
      const response = await cream.enableCollateral(marketAddress);
      addTx(response.hash, `Enable collateral`)
    }
  }, [cream, addTx, enabled, marketAddress])

  return (
      <Switch
        disabled={disabled}
        checked={enabled}
        onChange={() => toggleEnableCollateral()}
        height={16} width={32}
        uncheckedIcon={false}
        checkedIcon={false}
        handleDiameter={12}
        onColor={theme.colors.creamGreen}
        offHandleColor={"#000"}
        onHandleColor={"#000"}
      />
  )
}

export default UserSupplyMarket;
