import { JsonRpcProvider } from '@ethersproject/providers';
import axios from 'axios';
import { BnbUsdPriceEndpoint, EthUsdPriceEndpoint } from './constants';
import ArbitrumMarkets from './markets/arbitrum';
import AvalancheMarkets from './markets/avalanche';
import BSCMarkets from './markets/bsc';
import EthereumMarkets from './markets/ethereum';
import FantomMarkets from './markets/fantom';
import FujiMarkets from './markets/fuji';
import GoerliMarkets from './markets/goerli';
import IronBankMarkets from './markets/ironbank';
import PolygonMarkets from './markets/polygon';
import { ChainConfigParams, LongTermPool, Market } from './Type';

export type Protocol = {
  name: string;
  networkId: number;
  defaultProvider: JsonRpcProvider;
  blocksPerYear: number;
  refreshRate: number;
  markets: Market[];
  lpMarkets: Market[];
  comptrollerName: string;
  explorer: string;
  getBasePrice: () => Promise<number>;

  creamAddress: string;
  compoundLensAddress: string;
  comptrollerAddress: string;
  multiCallAddress: string;
  maximillionAddress: string;
  chainConfig: ChainConfigParams;
  sushiAddress: string;
  pancakeAddress: string;
  longTermPools: LongTermPool[];
  nativeContractAddress: string;

  liquidityMiningAddress: string;
  liquidityMiningLensAddress: string;
  iceCreamAddress: string;
  theGraphApi: string;
}

export const EthereumProtocol: Protocol = {
  name: 'Ethereum',
  networkId: 1,
  defaultProvider: new JsonRpcProvider('https://ethnode.steaker.capital/'),
  blocksPerYear: 2102400,
  refreshRate: 15,
  markets: EthereumMarkets,
  lpMarkets: EthereumMarkets.filter(market => market.name.includes('SushiSwap')),
  comptrollerName: 'eth',
  explorer: 'https://etherscan.io',
  getBasePrice: async () => {
    const ethPrice = await axios.get(EthUsdPriceEndpoint);
    return ethPrice.data.ethereum.usd;
  },
  creamAddress: '0x2ba592F78dB6436527729929AAf6c908497cB200',
  compoundLensAddress: '0x04c762a5dF2Fa02FE868F25359E0C259fB811CfE',
  comptrollerAddress: '0x3d5BC3c8d13dcB8bF317092d84783c2697AE9258',
  multiCallAddress: '0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441',
  maximillionAddress: '0x06091717521c2002f2859c75f53987eaF1E5aa2f',
  chainConfig: {
    chainId: '0x1',
    chainName: 'Ethereum',
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18
    },
    rpcUrls: ['https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
    blockExplorerUrls: ['https://etherscan.io'],
  },
  sushiAddress: '0x6b3595068778dd592e39a122f4f5a5cf09c90fe2',
  pancakeAddress: '',
  longTermPools: [
    {
      address: '0x780F75ad0B02afeb6039672E6a6CEDe7447a8b45',
      years: 1
    },
    {
      address: '0xBdc3372161dfd0361161e06083eE5D52a9cE7595',
      years: 2
    },
    {
      address: '0xD5586C1804D2e1795f3FBbAfB1FBB9099ee20A6c',
      years: 3
    },
    {
      address: '0xE618C25f580684770f2578FAca31fb7aCB2F5945',
      years: 4
    },
  ],
  nativeContractAddress: '0xD06527D5e56A3495252A528C4987003b712860eE',
  liquidityMiningAddress: '',
  liquidityMiningLensAddress: '',
  iceCreamAddress: '0x3986425b96F11972d31C78ff340908832C5c0043',
  theGraphApi: 'https://gateway.thegraph.com/api/b88724cff2230d97b45c3533fb7d8625/subgraphs/id/0x197939c1ca20c2b506d6811d8b6cdb3394471074-0',
};

export const GoerliProtocol: Protocol = {
  name: 'goerli',
  networkId: 5,
  defaultProvider: new JsonRpcProvider('https://rpc.goerli.mudit.blog/'),
  blocksPerYear: 2102400,
  refreshRate: 15,
  markets: GoerliMarkets,
  lpMarkets: [],
  comptrollerName: 'goerli',
  explorer: 'https://goerli.etherscan.io',
  getBasePrice: async () => {
    const ethPrice = await axios.get(EthUsdPriceEndpoint);
    return ethPrice.data.ethereum.usd;
  },
  creamAddress: '0xc1452e956a37651BF17E99efa7568c9dc56A6C39',
  compoundLensAddress: '0x3e82edd07175E353338712ebB16d5dEB19D19fAD',
  comptrollerAddress: '0x1e6bB7EdE15bA37b5861307a1f3f37dD7B56189D',
  multiCallAddress: '0x77dCa2C955b15e9dE4dbBCf1246B4B85b651e50e',
  maximillionAddress: '0x9dabBdEf3a6b3cb95EFe3088568B12aD36de1637',
  chainConfig: {
    chainId: '0x5',
    chainName: 'Goerli',
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18
    },
    rpcUrls: ['https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
    blockExplorerUrls: ['https://goerli.etherscan.io'],
  },
  sushiAddress: '',
  pancakeAddress: '',
  longTermPools: [],
  nativeContractAddress: '0x5dFa416bdd6d42ea1759AfD3E08165691E49c004',
  liquidityMiningAddress: '0x8C4567ADfeaE86b72c7A56807Eb1115c80F207BF',
  liquidityMiningLensAddress: '0xc3AD320774E5E44514C739F069bdA8f37EE1e489',
  iceCreamAddress: '0x66e9c76b76F73B7F04108E26d201bC4023386Fa6',
  theGraphApi: '',
};

export const IronBankProtocol: Protocol = {
  name: 'Iron Bank',
  networkId: 1,
  defaultProvider: new JsonRpcProvider('https://ethnode.steaker.capital/'),
  blocksPerYear: 2102400,
  refreshRate: 15,
  markets: IronBankMarkets,
  lpMarkets: [],
  comptrollerName: 'ironbank',
  explorer: 'https://etherscan.io',
  getBasePrice: async () => 1,
  creamAddress: '0x2ba592F78dB6436527729929AAf6c908497cB200',
  compoundLensAddress: '0x328A7b4d538A2b3942653a9983fdA3C12c571141',
  comptrollerAddress: '0xAB1c342C7bf5Ec5F02ADEA1c2270670bCa144CbB',
  multiCallAddress: '0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441',
  maximillionAddress: '',
  chainConfig: {
    chainId: '0x1',
    chainName: 'Ethereum',
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18
    },
    rpcUrls: ['https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
    blockExplorerUrls: ['https://etherscan.io'],
  },
  nativeContractAddress: '',
  sushiAddress: '0x6b3595068778dd592e39a122f4f5a5cf09c90fe2',
  pancakeAddress: '',
  longTermPools: [],
  liquidityMiningAddress: '',
  liquidityMiningLensAddress: '',
  iceCreamAddress: '',
  theGraphApi: 'https://gateway.thegraph.com/api/b88724cff2230d97b45c3533fb7d8625/subgraphs/id/0x197939c1ca20c2b506d6811d8b6cdb3394471074-1',
};

export const BscProtocol: Protocol = {
  name: 'Binance Smart Chain',
  networkId: 56,
  defaultProvider: new JsonRpcProvider('https://bsc-dataseed1.binance.org/'),
  blocksPerYear: 10512000,
  refreshRate: 10,
  markets: BSCMarkets,
  lpMarkets: BSCMarkets.filter(market => market.name.includes('Pancake') && market.underlyingName !== 'PancakeSwap Token'),
  comptrollerName: 'bsc',
  explorer: 'https://bscscan.com',
  getBasePrice: async () => {
    const bnbPrice = await axios.get(BnbUsdPriceEndpoint);
    return bnbPrice.data.binancecoin.usd;
  },
  creamAddress: '0xd4cb328a82bdf5f03eb737f37fa6b370aef3e888',
  compoundLensAddress: '0x06fd4e17Dd35d0dE9FE17eeAE4e94fBA57fEF154',
  comptrollerAddress: '0x589de0f0ccf905477646599bb3e5c622c84cc0ba',
  multiCallAddress: '0x59CED6942f5506bc01e5D438e7ce3D4635271659',
  maximillionAddress: '0x803Bb411e0c0200349e30314d60c30a7509b6245',
  chainConfig: {
    chainId: '0x38',
    chainName: 'Binance Smart Chain',
    nativeCurrency: {
      name: 'Binance Coin',
      symbol: 'BNB',
      decimals: 18
    },
    rpcUrls: ['https://bsc-dataseed2.binance.org/'],
    blockExplorerUrls: ['https://bscscan.com'],
  },
  sushiAddress: '0x947950BcC74888a40Ffa2593C5798F11Fc9124C4',
  pancakeAddress: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
  longTermPools: [],
  nativeContractAddress: '0x1Ffe17B99b439bE0aFC831239dDECda2A790fF3A',
  liquidityMiningAddress: '0x004D2829E5C21377DBE561E15c8a830389D524Fa',
  liquidityMiningLensAddress: '0x56D21E79d72D7f62E038Adc31Ccd38989f077d08',
  iceCreamAddress: '',
  theGraphApi: 'https://api.thegraph.com/subgraphs/name/creamfinancedev/cream-portfolio-bsc',
};

export const PolygonProtocol: Protocol = {
  name: 'Polygon',
  networkId: 137,
  defaultProvider: new JsonRpcProvider('https://rpc-mainnet.matic.quiknode.pro'),
  blocksPerYear: 15768000,
  refreshRate: 10,
  markets: PolygonMarkets,
  lpMarkets: [],
  comptrollerName: 'polygon',
  explorer: 'https://polygonscan.com',
  getBasePrice: async () => 1,
  creamAddress: '0x04f3C4cF2e806da6DF31e80E8A5D121F98eDD61d',
  compoundLensAddress: '0xa7B0C2e904d597b89b4bf91927A3B90459f8bb9E',
  comptrollerAddress: '0x20CA53E2395FA571798623F1cFBD11Fe2C114c24',
  multiCallAddress: '0x92bB764BFb54d0c3Cba333d5D59cAbE9174b7D77',
  maximillionAddress: '',
  chainConfig: {
    chainId: '0x89',
    chainName: 'Polygon',
    nativeCurrency: {
      name: 'Polygon',
      symbol: 'Matic',
      decimals: 18
    },
    rpcUrls: ['https://rpc-mainnet.matic.network'],
    blockExplorerUrls: ['https://pologonscan.com/'],
  },
  sushiAddress: '0x0b3F868E0BE5597D5DB7fEB59E1CADBb0fdDa50a',
  pancakeAddress: '',
  longTermPools: [],
  nativeContractAddress: '',
  liquidityMiningAddress: '',
  liquidityMiningLensAddress: '',
  iceCreamAddress: '',
  theGraphApi: 'https://api.thegraph.com/subgraphs/name/creamfinancedev/cream-portfolio-polygon',
};

export const FantomProtocol: Protocol = {
  name: 'Fantom',
  networkId: 250,
  defaultProvider: new JsonRpcProvider('https://rpcapi.fantom.network/'),
  blocksPerYear: 31536000,
  refreshRate: 10,
  markets: FantomMarkets,
  lpMarkets: [],
  comptrollerName: 'fantom',
  explorer: 'https://ftmscan.com',
  getBasePrice: async () => 1,
  creamAddress: '0x657A1861c15A3deD9AF0B6799a195a249ebdCbc6',
  compoundLensAddress: '0xCEb1cE674f38398432d20bc8f90345E91Ef46fd3',
  comptrollerAddress: '0x4250A6D3BD57455d7C6821eECb6206F507576cD2',
  multiCallAddress: '0x11e516AA99eD87D3076a5e5Fc842FA63e181F4C2',
  maximillionAddress: '',
  chainConfig: {
    chainId: '0xfa',
    chainName: 'Fantom',
    nativeCurrency: {
      name: 'Fantom',
      symbol: 'FTM',
      decimals: 18
    },
    rpcUrls: ['https://rpcapi.fantom.network'],
    blockExplorerUrls: ['https://ftmscan.com'],
  },
  sushiAddress: '0xae75A438b2E0cB8Bb01Ec1E1e376De11D44477CC',
  pancakeAddress: '',
  longTermPools: [],
  nativeContractAddress: '',
  liquidityMiningAddress: '',
  liquidityMiningLensAddress: '',
  iceCreamAddress: '',
  theGraphApi: 'https://api.thegraph.com/subgraphs/name/creamfinancedev/cream-portfolio-fantom',
};

export const ArbitrumProtocol: Protocol = {
  name: 'Arbitrum',
  networkId: 42161,
  defaultProvider: new JsonRpcProvider('https://arb1.arbitrum.io/rpc'),
  blocksPerYear: 2102400,
  refreshRate: 15,
  markets: ArbitrumMarkets,
  lpMarkets: [],
  comptrollerName: 'arbitrum',
  explorer: 'https://mainnet-arb-explorer.netlify.app',
  getBasePrice: async () => 1,
  creamAddress: '',
  compoundLensAddress: '0x139Dd8Bb6355d20342e08ff013150b1aE5040a42',
  comptrollerAddress: '0xbadaC56c9aca307079e8B8FC699987AAc89813ee',
  multiCallAddress: '0x7A7443F8c577d537f1d8cD4a629d40a3148Dd7ee',
  maximillionAddress: '',
  chainConfig: {
    chainId: '0xa4b1',
    chainName: 'Arbitrum',
    nativeCurrency: {
      name: 'Arbitrum',
      symbol: 'ETH',
      decimals: 18
    },
    rpcUrls: ['https://arb1.arbitrum.io/rpc'],
    blockExplorerUrls: ['https://arbiscan.io'],
  },
  sushiAddress: '',
  pancakeAddress: '',
  longTermPools: [],
  nativeContractAddress: '',
  liquidityMiningAddress: '',
  liquidityMiningLensAddress: '',
  iceCreamAddress: '',
  theGraphApi: 'https://api.thegraph.com/subgraphs/name/creamfinancedev/cream-arbitrum',
};

export const AvalancheProtocol: Protocol = {
  name: 'Avalanche',
  networkId: 43114,
  defaultProvider: new JsonRpcProvider('https://api.avax.network/ext/bc/C/rpc'),
  blocksPerYear: 31536000,
  refreshRate: 10,
  markets: AvalancheMarkets,
  lpMarkets: [],
  comptrollerName: 'avalanche',
  explorer: 'https://cchain.explorer.avax.network',
  getBasePrice: async () => 1,
  creamAddress: '',
  compoundLensAddress: '0x5b4058A9000e86fe136Ac896352C4DFD539E32a1',
  comptrollerAddress: '0x2eE80614Ccbc5e28654324a66A396458Fa5cD7Cc',
  multiCallAddress: '0xa00FB557AA68d2e98A830642DBbFA534E8512E5f',
  maximillionAddress: '',
  chainConfig: {
    chainId: '0xa86a',
    chainName: 'Avalanche Mainnet',
    nativeCurrency: {
      name: 'Avax',
      symbol: 'Avax',
      decimals: 18
    },
    rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
    blockExplorerUrls: ['https://cchain.explorer.avax.network'],
  },
  sushiAddress: '',
  pancakeAddress: '',
  longTermPools: [],
  nativeContractAddress: '',
  liquidityMiningAddress: '',
  liquidityMiningLensAddress: '',
  iceCreamAddress: '',
  theGraphApi: 'https://api.thegraph.com/subgraphs/name/creamfinancedev/cream-avalanche',
}

export const FujiProtocol: Protocol = {
  name: 'Fuji',
  networkId: 43113,
  defaultProvider: new JsonRpcProvider('https://api.avax-test.network/ext/bc/C/rpc'),
  blocksPerYear: 31536000,
  refreshRate: 10,
  markets: FujiMarkets,
  lpMarkets: [],
  comptrollerName: 'fuji',
  explorer: 'https://cchain.explorer.avax-test.network',
  getBasePrice: async () => 1,
  creamAddress: '',
  compoundLensAddress: '0x812C0b2a2A0A74f6f6ed620Fbd2B67Fec7DB2190',
  comptrollerAddress: '0x66e9c76b76F73B7F04108E26d201bC4023386Fa6',
  multiCallAddress: '0xB1FD648D8CA4bE22445963554b85AbbFC210BC83',
  maximillionAddress: '',
  chainConfig: {
    chainId: '0xa869',
    chainName: 'Avalanche FUJI',
    nativeCurrency: {
      name: 'Avax',
      symbol: 'Avax',
      decimals: 18
    },
    rpcUrls: ['https://api.avax-test.network/ext/bc/C/rpc'],
    blockExplorerUrls: ['https://cchain.explorer.avax-test.network'],
  },
  sushiAddress: '',
  pancakeAddress: '',
  longTermPools: [],
  nativeContractAddress: '',
  liquidityMiningAddress: '',
  liquidityMiningLensAddress: '',
  iceCreamAddress: '',
  theGraphApi: '',
}

export const ProductionProtocols = [
  EthereumProtocol,
  IronBankProtocol,
  BscProtocol,
  PolygonProtocol,
  FantomProtocol,
  ArbitrumProtocol,
  AvalancheProtocol,
];

export const DevProtocols = [
  GoerliProtocol,
];
