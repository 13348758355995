import React from 'react';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from "react-i18next";

interface EYNTKProps {
  side: 'supply' | 'borrow';
}

const EYNTK: React.FC<EYNTKProps> = ({ side }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return side === 'supply' ? (
    <Wrapper>
      <Bar color={theme.colors.creamGreen}/>
      <div>
        <Title color={theme.colors.creamGreen}>
          {t("SUPPLY ASSETS")}
        </Title>
        <Link href="https://forum.cream.finance/t/lending-faq-supply/1667" target="_blank">
          {t("SUPPLYING KNOWLEDGE")}
        </Link>
      </div>
    </Wrapper>
  ) : (
    <Wrapper>
      <Bar color={theme.colors.creamPurple}/>
      <div>
        <Title color={theme.colors.creamPurple}>
          {t("Borrow assets")}
        </Title>
        <Link href="https://forum.cream.finance/t/lending-faq-borrow/1668" target="_blank">
          {t("BORROWING KNOWLEDGE")}
        </Link>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 28px;
`

const Bar = styled.div<{ color: string; }>`
  width: 2px;
  height: 37px;
  border: 1px solid ${(props) => props.color};
  border-radius: 2px;
  margin-right: 34px;
`

const Title = styled.div<{ color: string }>`
  color: ${(props) => props.color};
  font-size: ${(props) => props.theme.textSize.big};
  font-weight: bold;
`

const Link = styled.a`
  display: block;
  font-size: ${(props) => props.theme.textSize.small};
  cursor: pointer;
`

export default EYNTK;
