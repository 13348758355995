import styled from 'styled-components';

const WarningText = styled.div`
  color: #e26969;
  font-size: ${(props) => props.theme.textSize.big};
  text-align: center;
  margin-top: 40px;
`;

export default WarningText;
