import { useContext, useMemo } from 'react';
import { BigNumber } from 'ethers';
import { UserSupplySummary } from '../cream/Type';
import { MarketContext } from '../providers/MarketProvider';
import { ProtocolContext } from '../providers/ProtocolProvider';
import {
  oracleNativeBalance,
  rateToApy,
  getNetRate
} from '../cream/utils';

const useUserSupplySummary = (): UserSupplySummary => {
  const { protocol } = useContext(ProtocolContext);
  const { markets, allMarketStats, allUserTokenStats, basePrice } = useContext(MarketContext);

  const data: UserSupplySummary = useMemo(() => {
    let totalSupplyBalanceInNative = BigNumber.from(0);
    let totalRateInNative = BigNumber.from(0);

    for (const i in markets) {
      const userTokenStats = allUserTokenStats[i];
      const marketStats = allMarketStats[i];
      if (userTokenStats.crTokenBalance.eq(0) && userTokenStats.borrowBalance.eq(0)) {
        continue;
      }
      const nativeSupplyBalance = oracleNativeBalance(userTokenStats.underlyingBalance, marketStats.underlyingPrice)
      totalSupplyBalanceInNative = totalSupplyBalanceInNative.add(nativeSupplyBalance);

      const nativeSupplyRate = nativeSupplyBalance.mul(marketStats.supplyRate);
      totalRateInNative = totalRateInNative.add(nativeSupplyRate);

      const nativeBorrowBalance = oracleNativeBalance(userTokenStats.borrowBalance, marketStats.underlyingPrice)
      const nativeBorrowRate = nativeBorrowBalance.mul(marketStats.borrowRate);
      totalRateInNative = totalRateInNative.sub(nativeBorrowRate);
    }
    const netRate = getNetRate(totalSupplyBalanceInNative, totalRateInNative)

    return {
      totalSupplyBalanceInNative,
      netAPY: rateToApy(netRate, protocol.blocksPerYear),
      basePrice
    };
  }, [markets, allUserTokenStats, allMarketStats, basePrice, protocol]);
  return data;
}

export default useUserSupplySummary;
