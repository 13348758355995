import { BigNumber, Contract } from 'ethers';
import { Signer } from '@ethersproject/abstract-signer';
import { Provider, TransactionResponse } from '@ethersproject/abstract-provider';
import ComptrollerABI from './ABIs/comptroller';
import { GAS_MULTIPLIER } from "./constants";

class Comptroller {
  private contract: Contract;

  constructor(address: string, provider: Provider | Signer) {
    this.contract = new Contract(address, ComptrollerABI, provider);
  }

  connect(signer: Signer) {
    this.contract = this.contract.connect(signer);
  }

  getAssetsIn(address: string): Promise<string[]> {
    return this.contract.getAssetsIn(address);
  }

  async allMarkets(): Promise<string[]> {
    return this.contract.allMarkets();
  }

  async enterMarkets(markets: string[]): Promise<TransactionResponse> {
    const gas = await this.contract.estimateGas.enterMarkets(markets);
    return this.contract.enterMarkets(markets, {gasLimit: gas.mul(GAS_MULTIPLIER)});
  }

  async exitMarket(market: string): Promise<TransactionResponse> {
    const gas = await this.contract.estimateGas.exitMarket(market);
    return this.contract.exitMarket(market, {gasLimit: gas.mul(GAS_MULTIPLIER)});
  }

  async mintGuardianPaused(address: string): Promise<boolean> {
    return this.contract.mintGuardianPaused(address);
  }

  async borrowGuardianPaused(address: string): Promise<boolean> {
    return this.contract.borrowGuardianPaused(address);
  }

  async supplyCap(address: string): Promise<BigNumber> {
    return this.contract.supplyCaps(address);
  }

  async borrowCap(address: string): Promise<BigNumber> {
    return this.contract.borrowCaps(address);
  }

  async getAllMarkets(): Promise<string[]> {
    return this.contract.getAllMarkets();
  }
}

export default Comptroller;
