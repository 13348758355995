import { BigNumber, Contract } from 'ethers';
import { Signer } from "@ethersproject/abstract-signer";
import { Provider, TransactionResponse } from '@ethersproject/abstract-provider';

import { GAS_MULTIPLIER } from "./constants";
import FeeDistributorABI from './ABIs/feeDistributor';

class IceCreamFeeDistributor {
  private contract: Contract;

  address: string;

  constructor(address: string, provider: Provider | Signer) {
    this.contract = new Contract(address, FeeDistributorABI, provider);
    this.address = address;
  }

  async claim(address: string): Promise<TransactionResponse> {
    const gas = await this.contract.estimateGas['claim(address)'](address);
    return this.contract['claim(address)'](address, {gasLimit: gas.mul(GAS_MULTIPLIER)});
  }

  async claimable(address: string): Promise<BigNumber> {
    return this.contract.callStatic['claim(address)'](address);
  }
}

export default IceCreamFeeDistributor;
