import React, { useState } from 'react';
import styled, { CSSProperties } from 'styled-components';
interface Option {
  title: string;
  value: any;
}

interface DropdownProps {
  options: Array<Option>;
  selectedValue: any;
  alignTop: boolean;
  onSelect: (value: any) => void;
  style?: CSSProperties
}

const DropdownContainer: React.FC<DropdownProps> = ({ options, selectedValue, alignTop, onSelect, style }) => {
  const [isExpanded, setExpanded] = useState(false);
  return (
    <Container
      onClick={() => setExpanded(true)}
      onMouseEnter={() => setExpanded(true)}
      onMouseLeave={() => setExpanded(false)}
      style={style}
    >
      <span style={{ marginRight: 'auto' }}>{options.find(e => e.value === selectedValue)?.title}</span>
      {isExpanded && (
          <Dropdown alignTop={alignTop}>
            {options.map(option =>
              <DropdownItem
                key={option.value}
                onClick={(e) => {
                  e.stopPropagation();
                  setExpanded(false)
                  onSelect(option.value)
                }}
              >
                {option.title}
              </DropdownItem>)
            }
          </Dropdown>)
        }
    </Container>
  );
};

const Container = styled.span`
  position: relative;
  cursor: pointer;
  font-weight: 500;
  padding: 8px;
  margin: 8px;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #E6E6E6;
`

const Dropdown = styled.ul<{alignTop: boolean}>`
  position: absolute;
  background: #000000;
  border: 1px solid #1E2738;
  box-sizing: border-box;
  box-shadow: 0px 32px 64px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  top: ${(props) => props.alignTop ? '32px' : 'unset'};
  bottom: ${(props) => !props.alignTop ? '32px' : 'unset'};
  z-index: 1;
  list-style-type: none;
  padding: 8px;
`;

const DropdownItem = styled.li`
  color: #E6E6E6;
  height: 40px;
  line-height: 40px;
  padding: 0 30px;
  white-space:nowrap;
  &:hover, &:active {
    color: black;
    background: #69E2DB;
    border-radius: 4px;
  }
`;

export default DropdownContainer;
