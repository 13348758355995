import { darken } from 'polished';
import React, { DOMAttributes } from 'react';
import styled from 'styled-components';
import media from '../../media';

interface ButtonProps {
  bgColor?: string;
  textColor?: string;
  borderRadius?: string;
  disabled?: boolean;
}

export const Button = styled.button<ButtonProps>`
  width: auto;
  padding: 0 20px;
  height: 36px;
  line-height: 36px;
  font-size: 13px;
  border: 0;
  outline: none;
  border-radius: ${(props) => props.borderRadius || '4px'};
  transition: all 0.2s ease-out;
  font-weight: 500;
  background-color: ${(props) => props.bgColor || props.theme.colors.supplyCardBackground};
  color: ${(props) => props.textColor || props.theme.colors.creamGreen};
  opacity: ${(props) => props.disabled ? '0.2' : '1'};
  cursor: ${(props) => props.disabled ? 'not-allowed' : 'pointer'};
  
  ${media.down('sm')} {
    height: 28px;
    line-height: 28px;
    font-size: 11px;
  }

  &:hover {
    background-color: ${(props) => darken(0.06, props.bgColor || props.theme.colors.supplyCardBackground)};
    transition: all 0s;
  }

  &:active {
    outline: 0;
    transform: scale(0.99);
    transition: all 0.1s ease-in;
    background-color: ${(props) => darken(0.1, props.bgColor || props.theme.colors.supplyCardBackground)};
  }
`;

export const FullWidthButton = styled(Button)`
  width: 100%;
`

interface NumberedButtonProps {
  number: number;
}

export const NumberedButton: React.FC<NumberedButtonProps & ButtonProps & DOMAttributes<any>> = ({
  number, children, ...props
}) => {
  return (
    <NumberedButtonWrapper {...props}>
      <Number>{number}</Number>
      {children}
    </NumberedButtonWrapper>
  )
}

const NumberedButtonWrapper = styled(FullWidthButton)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Number = styled.div`
  height: 24px;
  width: 24px;
  border-radius: 12px;
  background-color: #1e2738;
  margin-right: 12px;
  color: white;
  line-height: 24px;
  
  ${media.down('sm')} {
    height: 18px;
    width: 18px;
    border-radius: 9px;
    line-height: 18px;
    font-size: 11px;
  }
`

export const ClaimButton = styled(Button)`
  border: 1px solid ${(props) => props.theme.colors.creamGreen};
  padding: 0 10px;
  margin-left: 15px;
`
