import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers';
import Onboard from 'bnc-onboard';
import { API, Initialization, Subscriptions, Wallet } from 'bnc-onboard/dist/src/interfaces';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import ReactGA from 'react-ga4';
import {
  ArbitrumProtocol, AvalancheProtocol,
  BscProtocol,
  EthereumProtocol, FantomProtocol, FujiProtocol,
  GoerliProtocol,
  PolygonProtocol,
} from '../cream/Protocols';
import { ProtocolContext } from './ProtocolProvider';

interface Context {
  connected: boolean;
  connectWallet(): void;
  disconnectWallet(): void;
  walletAddress: string | null;
  ensName: string | null;
  networkId: number;
  provider: any;
  signer: JsonRpcSigner | null;
}

export const ConnectionContext = createContext<Context>({
  connected: false,
  connectWallet: () => {},
  disconnectWallet: () => {},
  walletAddress: null,
  ensName: null,
  networkId: 1,
  provider: GoerliProtocol.defaultProvider,
  signer: null,
});

const ConnectionProvider: React.FC = ({ children }) => {
  const { protocol, changeProtocol } = useContext(ProtocolContext);
  // since `changeProtocol` changes when `protocol` changes, we have to use a ref so that onboard subscription
  // uses the latest `changeProtocol`.
  const changeProtocolRef = useRef(changeProtocol);
  changeProtocolRef.current = changeProtocol;

  const [wallet, setWallet] = useState<Wallet>();
  const [walletAddress, setWalletAddress] = useState<string | null>(null);
  const [ensName, setENSName] = useState<string | null>(null);
  const [connected, setConnected] = useState(false);

  const networkId = protocol.networkId;
  const [onboard, setOnboard] = useState<API>();

  useEffect(() => {
    if (onboard) return; // only initialize once

    const subscriptions: Subscriptions = {
      wallet: (wallet) => {
        if (wallet.provider) {
          setWallet(wallet);
        } else {
          setWallet(undefined);
        }

        if (wallet.name) {
          window.localStorage.setItem('selectedWallet', wallet.name)
        } else {
          window.localStorage.removeItem('selectedWallet');
        }
      },
      address: (newAddress: string) => {
        setWalletAddress(newAddress);
        ReactGA.set({ userId: newAddress });
      },
      network: (onboardNetwork: number) => {
        const changeProtocol = changeProtocolRef.current;
        // change app protocol if user switches from wallet
        switch (onboardNetwork) {
          case 1:
            changeProtocol(EthereumProtocol); break;
          case 5:
            changeProtocol(GoerliProtocol); break;
          case 56:
            changeProtocol(BscProtocol); break;
          case 137:
            changeProtocol(PolygonProtocol); break;
          case 250:
            changeProtocol(FantomProtocol); break;
          case 42161:
            changeProtocol(ArbitrumProtocol); break;
          case 43113:
            changeProtocol(FujiProtocol); break;
          case 43114:
            changeProtocol(AvalancheProtocol); break;
        }
      },
    }

    setOnboard(initOnboard(networkId, subscriptions));
  }, [networkId, onboard]);

  useEffect(() => {
    onboard?.config({ networkId: networkId });
    if (wallet) {
      onboard?.walletCheck().then(setConnected);
    }
  }, [networkId, onboard, wallet])

  const provider = (() => {
    if (!onboard) {
      return protocol.defaultProvider;
    }

    const {
      wallet: onboardWallet,
      network,
    } = onboard.getState();

    if (!onboardWallet.provider || !wallet?.provider || network !== networkId) {
      if (connected) {
        setConnected(false);
      }
      return protocol.defaultProvider;
    }

    return new Web3Provider(wallet.provider);
  })();

  const signer = (() => {
    if (!connected) {
      return null;
    }
    return provider.getSigner();
  })();

  useEffect(() => {
    const previouslySelectedWallet = window.localStorage.getItem('selectedWallet');
    if (previouslySelectedWallet && onboard && !wallet) {
      onboard.walletSelect(previouslySelectedWallet).then(() => {
        onboard.walletCheck().then(setConnected);
      });
    }
  }, [onboard, wallet]);

  useEffect(() => {
    if (walletAddress && networkId === 1) {
      provider.lookupAddress(walletAddress).then(setENSName).catch((err) => {
        console.error('failed to fetch ens name:', err)
      });
    }
  }, [networkId, provider, walletAddress]);

  const connectWallet = useCallback(async () => {
    if (!onboard) {
      return;
    }

    const selected = await onboard.walletSelect();
    if (!selected) {
      return;
    }

    if (wallet?.provider) {
      try {
        await wallet.provider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: protocol.chainConfig.chainId }],
        });
      } catch (error) {
        if (error.code === 4902 || wallet.name === 'imToken') {
          try {
            await wallet.provider.request({
              method: 'wallet_addEthereumChain',
              params: [protocol.chainConfig],
            });
          } catch (addError) {
            console.log('failed to add chain config:', addError)
            // handle "add" error
          }
        }
      }
    }

    setConnected(await onboard.walletCheck());
  }, [onboard, protocol, wallet]);

  const disconnectWallet = useCallback(() => {
    onboard?.walletReset();
    setConnected(false);
    setWalletAddress(null);
    setENSName(null);
    window.localStorage.removeItem('selectedWallet');
  }, [onboard])

  const context = useMemo(() => ({
    connected,
    connectWallet,
    disconnectWallet,
    walletAddress,
    ensName,
    networkId,
    provider,
    signer,
  }), [connected, connectWallet, disconnectWallet, walletAddress, ensName, networkId, provider, signer]);

  return (
    <ConnectionContext.Provider value={context}>
      {children}
    </ConnectionContext.Provider>
  )
}

const initOnboard = (networkId: number, subscriptions: Subscriptions) => {
  const option: Initialization = {
    networkId,
    darkMode: true,
    subscriptions: subscriptions,
    hideBranding: true,
    walletSelect: {
      wallets: [
        { walletName: 'detectedwallet' },
        { walletName: 'metamask' },
        {
          walletName: 'walletConnect',
          rpc: {
            1: EthereumProtocol.defaultProvider.connection.url,
            5: GoerliProtocol.defaultProvider.connection.url,
            56: BscProtocol.defaultProvider.connection.url,
            137: PolygonProtocol.defaultProvider.connection.url,
            250: FantomProtocol.defaultProvider.connection.url,
            42161: ArbitrumProtocol.defaultProvider.connection.url,
            43113: FujiProtocol.defaultProvider.connection.url,
            43114: AvalancheProtocol.defaultProvider.connection.url,
          },
        },
        {
          walletName: 'imToken',
          rpcUrl: EthereumProtocol.defaultProvider.connection.url,
        },
        { walletName: 'trust' },
        { walletName: 'coinbase' },
        { walletName: 'tokenpocket' },
        { walletName: 'binance' },
      ],
    },
    walletCheck: [
      { checkName: 'connect' },
      { checkName: 'accounts' },
      { checkName: 'network' },
      { checkName: 'derivationPath' },
    ],
  };

  if (networkId === 56) {
    option.networkName = 'Binance smart chain mainnet';
  } else if (networkId === 137) {
    option.networkName = 'Matic network'
  } else if (networkId === 250) {
    option.networkName = 'Fantom mainnet';
  }

  return Onboard(option);
}

export default ConnectionProvider;
