import { Category } from '../Category';
import { Market } from '../Type';

const PolygonMarkets: Market[] = [
  {
    name: 'Cream USD Coin',
    address: '0x73CF8c5D14Aa0EbC89f18272A568319F5BAB6cBD',
    symbol: 'crUSDC',
    underlyingAddress: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    underlyingDecimal: 6,
    underlyingName: 'USD Coin',
    underlyingSymbol: 'USDC',
    category: Category.stable,
    zeroAllowance: false,
  },
  {
    name: 'Cream Tether USD',
    address: '0xf976C9bc0E16B250E0B1523CffAa9E4c07Bc5C8a',
    symbol: 'crUSDT',
    underlyingAddress: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
    underlyingDecimal: 6,
    underlyingName: 'Tether USD',
    underlyingSymbol: 'USDT',
    category: Category.stable,
    zeroAllowance: false,
  },
  {
    name: 'Cream Dai USD',
    address: '0x4eCEDdF62277eD78623f9A94995c680f8fd6C00e',
    symbol: 'crDAI',
    underlyingAddress: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
    underlyingDecimal: 18,
    underlyingName: 'Dai Stablecoin',
    underlyingSymbol: 'DAI',
    category: Category.stable,
    zeroAllowance: false,
  },
  {
    name: 'Cream Matic Token',
    address: '0x3FaE5e5722C51cdb5B0afD8c7082e8a6AF336Ee8',
    symbol: 'crMATIC',
    underlyingAddress: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    underlyingDecimal: 18,
    underlyingName: 'Wrapped Matic',
    underlyingSymbol: 'WMATIC',
    category: Category.major,
    zeroAllowance: false,
  },
  {
    name: 'Cream Wrapped Ether',
    address: '0x7ef18d0a9C3Fb1A716FF6c3ED0Edf52a2427F716',
    symbol: 'crWETH',
    underlyingAddress: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    underlyingDecimal: 18,
    underlyingName: 'Wrapped Ether',
    underlyingSymbol: 'WETH',
    category: Category.major,
    zeroAllowance: false,
  },
  {
    name: 'Cream Wrapped Bitcoin',
    address: '0x5Dc3A30d8c5937f1529C3c93507C16d86A17072A',
    symbol: 'crWBTC',
    underlyingAddress: '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6',
    underlyingDecimal: 8,
    underlyingName: 'Wrapped Bitcoin',
    underlyingSymbol: 'WBTC',
    category: Category.major,
    zeroAllowance: false,
  },
  {
    name: 'Cream ChainLink Token',
    address: '0x20d5d319C2964ecb52e1B006a4C059b7f6d6ad0a',
    symbol: 'crLINK',
    underlyingAddress: '0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39',
    underlyingDecimal: 18,
    underlyingName: 'ChainLink Token',
    underlyingSymbol: 'LINK',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream SushiToken',
    address: '0x468a7BF78f11Da82c90b17a93adb7B14999aF5AB',
    symbol: 'crSUSHI',
    underlyingAddress: '0x0b3F868E0BE5597D5DB7fEB59E1CADBb0fdDa50a',
    underlyingDecimal: 18,
    underlyingName: 'SushiToken',
    underlyingSymbol: 'SUSHI',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream CRV',
    address: '0xE82225bA6BeD28406912522F01C7102DD9f07e78',
    symbol: 'crCRV',
    underlyingAddress: '0x172370d5Cd63279eFa6d502DAB29171933a610AF',
    underlyingDecimal: 18,
    underlyingName: 'CRV',
    underlyingSymbol: 'CRV',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream Quickswap',
    address: '0x2eE80614Ccbc5e28654324a66A396458Fa5cD7Cc',
    symbol: 'crQUICK',
    underlyingAddress: '0x831753DD7087CaC61aB5644b308642cc1c33Dc13',
    underlyingDecimal: 18,
    underlyingName: 'Quickswap',
    underlyingSymbol: 'QUICK',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream Aave',
    address: '0x4486835e0C567A320C0636d8F6e6e6679A46a271',
    symbol: 'crAAVE',
    underlyingAddress: '0xD6DF932A45C0f255f85145f286eA0b292B21C90B',
    underlyingDecimal: 18,
    underlyingName: 'Aave',
    underlyingSymbol: 'AAVE',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream DefiPulse Index',
    address: '0xfBbfa5fd64246046e683c423aa2AB0470fbD328D',
    symbol: 'crDPI',
    underlyingAddress: '0x85955046DF4668e1DD369D2DE9f3AEB98DD2A369',
    underlyingDecimal: 18,
    underlyingName: 'DefiPulse Index',
    underlyingSymbol: 'DPI',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream Frax Share',
    address: '0x9B21EB2E30D8320c3c1b8d8465284D78E58cB971',
    symbol: 'crFXS',
    underlyingAddress: '0x3e121107F6F22DA4911079845a470757aF4e1A1b',
    underlyingDecimal: 18,
    underlyingName: 'Frax Share',
    underlyingSymbol: 'FXS',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream Synthetix Network Token',
    address: '0xd4409B8D17d5d49a7ed9Ae734B0E8EdBa29b9FFA',
    symbol: 'crSNX',
    underlyingAddress: '0x50B728D8D964fd00C2d0AAD81718b71311feF68a',
    underlyingDecimal: 18,
    underlyingName: 'Synthetix Network Token',
    underlyingSymbol: 'SNX',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream Uniswap',
    address: '0x7ea7174dD0CB4Ab84f42177F01e9a8a79475d381',
    symbol: 'crUNI',
    underlyingAddress: '0xb33EaAd8d922B1083446DC23f610c2567fB5180f',
    underlyingDecimal: 18,
    underlyingName: 'Uniswap',
    underlyingSymbol: 'UNI',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream Interest-Bearing BTC',
    address: '0x98182BF525A4252C436ac349a4b79c7E6cd0EB7A',
    symbol: 'crIBBTC',
    underlyingAddress: '0x4EaC4c4e9050464067D673102F8E24b2FccEB350',
    underlyingDecimal: 18,
    underlyingName: 'Interest-Bearing BTC',
    underlyingSymbol: 'IBBTC',
    category: Category.major,
    zeroAllowance: false,
  },
];

export default PolygonMarkets;
