import React from 'react';
import styled from 'styled-components';
import { matchPath, NavLink, useLocation } from 'react-router-dom';
import media from '../../../media';
import { Nav } from '../type';

interface NavProps {
  navs: Nav[];
  onSubNavSelected: (subNav: Nav[]) => void;
}

const NavigationBar: React.FC<NavProps> = ({ navs, onSubNavSelected }) => {
  const location = useLocation();
  const isSubNavActive = (subNavs: Nav[]) => {
    for (const s of subNavs) {
      if (matchPath(location.pathname, { path: s.link })) {
        return true;
      }
    }

    return false;
  }

  return (
    <StyledNav>
      {navs.map((n, idx) => {
        return n.link ? (
          <StyledLink
            key={idx}
            exact
            activeClassName="active"
            to={n.link}
            isActive={(match, location) => {
              if (n.isActive) {
                return n.isActive(location.pathname);
              }
              return !!match;
            }}>
            <StyledText>{n.title}</StyledText>
          </StyledLink>
        ) : (
          <StyledLink
            key={idx}
            exact
            activeClassName="active"
            to='#'
            isActive={() => isSubNavActive(n.subNav || [])}
            onClick={() => onSubNavSelected(n.subNav || [])}
          >
            <StyledText>{n.title}</StyledText>
          </StyledLink>
        )
      })}
    </StyledNav>
  )
};

const StyledNav = styled.nav`
  display: flex;
  align-items: stretch;
  height: ${(props) => props.theme.topBarSize}px;
  margin-left: 80px;
  
  ${media.down('sm')} {
    margin-left: 20px;
  }
`;

const StyledLink = styled(NavLink)`
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0 ${(props) => props.theme.spacing[4]};
  font-weight: 500;
  font-size: 13px;

  &.active {
    color: ${props => props.theme.colors.creamGreen};
  }

  &:hover {
    color: ${props => props.theme.colors.creamGreen};
    text-decoration: none !important;
  }
`;

const StyledText = styled.span`
  font-weight: bolder;
  letter-spacing: ${(props) => props.theme.letterSpacing.big}
`;

export default NavigationBar;
