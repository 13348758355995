import { useContext, useMemo } from 'react';
import { BigNumber } from 'ethers';
import { UserBorrowSummary } from '../cream/Type';
import { MarketContext } from '../providers/MarketProvider';
import {
  oracleNativeBalance,
  calculateCollateral,
  borrowLimitPercentage,
  underlyingBalance,
} from '../cream/utils';

const useUserBorrowSummary = (): UserBorrowSummary => {
  const { markets, allMarketStats, allUserTokenStats, basePrice } = useContext(MarketContext);

  const data: UserBorrowSummary = useMemo(() => {
    let totalBorrowBalanceInNative = BigNumber.from(0);
    let borrowLimitInNative = BigNumber.from(0);

    for (const i in markets) {
      const userTokenStats = allUserTokenStats[i];
      const marketStats = allMarketStats[i];
      if (userTokenStats.collateralEnabled && !userTokenStats.crTokenBalance.eq(0)) {
        const collateralUnderlyingBalance = underlyingBalance(userTokenStats.collateralBalance, marketStats.exchangeRate);
        const nativeSupplyBalance = oracleNativeBalance(collateralUnderlyingBalance, marketStats.underlyingPrice);
        borrowLimitInNative = borrowLimitInNative.add(
          calculateCollateral(nativeSupplyBalance, marketStats.collateralFactor)
        );
      }

      if (userTokenStats.borrowBalance.eq(0)) {
        continue;
      }
      const nativeBalance = oracleNativeBalance(userTokenStats.borrowBalance, marketStats.underlyingPrice)
      totalBorrowBalanceInNative = totalBorrowBalanceInNative.add(nativeBalance);
    }

    return {
      totalBorrowBalanceInNative,
      borrowLimitPct: borrowLimitPercentage(borrowLimitInNative, totalBorrowBalanceInNative),
      borrowLimitInNative,
      basePrice
    };
  }, [markets, allUserTokenStats, allMarketStats, basePrice]);
  return data;
}

export default useUserBorrowSummary;
