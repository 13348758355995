import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import styled from 'styled-components';

import en from './locales/en/translation.json';
import es from './locales/es/translation.json';
import zhHant from './locales/zh-Hant/translation.json';
import jp from './locales/jp/translation.json';
import kr from './locales/kr/translation.json';

const resources = {
  en: {
    translation: en,
  },
  es: {
    translation: es,
  },
  jp: {
    translation: jp,
  },
  kr: {
    translation: kr,
  },
  'zh-Hant': {
    translation: zhHant,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'en',
    supportedLngs: ['en', 'zh-Hant', 'jp', 'kr', 'es'],
    interpolation: {
      escapeValue: false, // react already safes from xss
      format: (value, format) => {
        if (format === 'warning') {
          return <WarningSpan>value</WarningSpan>
        }
        return value;
      }
    },
    returnEmptyString: false,
  });

const WarningSpan = styled.span`
  ${(props) => props.theme.colors.warning}
`;

export default i18n;
