import { BigNumber, Contract, ethers } from 'ethers';
import { Signer } from '@ethersproject/abstract-signer';
import { Provider, TransactionResponse } from '@ethersproject/abstract-provider';

import Erc20ABI from './ABIs/erc20';

class Erc20 {
  private contract: Contract;

  address: string;

  constructor(address: string, provider: Provider | Signer) {
    this.contract = new Contract(address, Erc20ABI, provider);
    this.address = address;
  }

  connect(signer: Signer) {
    this.contract = this.contract.connect(signer);
  }

  totalSupply(): Promise<BigNumber> {
    return this.contract.totalSupply();
  }

  async approve(spender: string, amount: BigNumber): Promise<TransactionResponse> {
    return this.contract.approve(spender, amount);
  }

  async approveAll(spender: string): Promise<TransactionResponse> {
    return this.approve(spender, ethers.constants.MaxUint256);
  }

  async transfer(recipient: string, amount: BigNumber): Promise<TransactionResponse> {
    return this.contract.transfer(recipient, amount);
  }

  async allowance(owner: string, spender: string): Promise<BigNumber> {
    return this.contract.allowance(owner, spender);
  }

  async balanceOf(account: string): Promise<BigNumber> {
    return this.contract.balanceOf(account);
  }

  async decimals(): Promise<number> {
    return await this.contract.decimals();
  }
}

export default Erc20;
