import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';

interface InfoIconProps {
  text: string;
  url: string;
  color: string;
}

const InfoIcon: React.FC<InfoIconProps> = ({ text, url, color }) => {
  return (
    <Wrapper>
      {text}
      <a href={url} target='_blank' rel="noreferrer">
        <Icon icon={faQuestionCircle} color={color} />
      </a>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled(FontAwesomeIcon)`
  margin-left: 8px;
`;

export default InfoIcon;
