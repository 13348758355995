import { Category } from '../Category';
import { Market } from '../Type';

const EthereumMarkets: Market[] = [
  {
    name: 'Cream Ether',
    address: '0xD06527D5e56A3495252A528C4987003b712860eE',
    symbol: 'crETH',
    underlyingAddress: '',
    underlyingDecimal: 18,
    underlyingName: 'Ethereum',
    underlyingSymbol: 'ETH',
    category: Category.major,
    zeroAllowance: false,
  },
  {
    name: 'Cream USDT',
    address: '0x797AAB1ce7c01eB727ab980762bA88e7133d2157',
    symbol: 'crUSDT',
    underlyingAddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    underlyingDecimal: 6,
    underlyingName: 'Tether USD',
    underlyingSymbol: 'USDT',
    category: Category.stable,
    zeroAllowance: true,
  },
  {
    name: 'Cream USD Coin',
    address: '0x44fbeBd2F576670a6C33f6Fc0B00aA8c5753b322',
    symbol: 'crUSDC',
    underlyingAddress: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    underlyingDecimal: 6,
    underlyingName: 'USD Coin',
    underlyingSymbol: 'USDC',
    category: Category.stable,
    zeroAllowance: false,
  },
  {
    name: 'Cream Compound',
    address: '0x19D1666f543D42ef17F66E376944A22aEa1a8E46',
    symbol: 'crCOMP',
    underlyingAddress: '0xc00e94Cb662C3520282E6f5717214004A7f26888',
    underlyingDecimal: 18,
    underlyingName: 'Compound',
    underlyingSymbol: 'COMP',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream Balancer',
    address: '0xcE4Fe9b4b8Ff61949DCfeB7e03bc9FAca59D2Eb3',
    symbol: 'crBAL',
    underlyingAddress: '0xba100000625a3754423978a60c9317c58a424e3D',
    underlyingDecimal: 18,
    underlyingName: 'Balancer',
    underlyingSymbol: 'BAL',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream YFI',
    address: '0xCbaE0A83f4f9926997c8339545fb8eE32eDc6b76',
    symbol: 'crYFI',
    underlyingAddress: '0x0bc529c00C6401aEF6D220BE8C6Ea1667F6Ad93e',
    underlyingDecimal: 18,
    underlyingName: 'yearn.finance',
    underlyingSymbol: 'YFI',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream yCRV',
    address: '0x9baF8a5236d44AC410c0186Fe39178d5AAD0Bb87',
    symbol: 'crYCRV',
    underlyingAddress: '0xdF5e0e81Dff6FAF3A7e52BA697820c5e32D806A8',
    underlyingDecimal: 18,
    underlyingName: 'Curve.fi yDAI/yUSDC/yUSDT/yTUSD',
    underlyingSymbol: 'YCRV',
    category: Category.gov,
    zeroAllowance: true,
  },
  {
    name: 'Cream ChainLink Token',
    address: '0x697256CAA3cCaFD62BB6d3Aa1C7C5671786A5fD9',
    symbol: 'crLINK',
    underlyingAddress: '0x514910771AF9Ca656af840dff83E8264EcF986CA',
    underlyingDecimal: 18,
    underlyingName: 'ChainLink Token',
    underlyingSymbol: 'LINK',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream Cream',
    address: '0x892B14321a4FCba80669aE30Bd0cd99a7ECF6aC0',
    symbol: 'crCREAM',
    underlyingAddress: '0x2ba592F78dB6436527729929AAf6c908497cB200',
    underlyingDecimal: 18,
    underlyingName: 'Cream',
    underlyingSymbol: 'CREAM',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream EthLend Token',
    address: '0x8B86e0598616a8d4F1fdAE8b59E55FB5Bc33D0d6',
    symbol: 'crLEND',
    underlyingAddress: '0x80fB784B7eD66730e8b1DBd9820aFD29931aab03',
    underlyingDecimal: 18,
    underlyingName: 'EthLend Token',
    underlyingSymbol: 'LEND',
    category: Category.legacy,
    zeroAllowance: true,
  },
  {
    name: 'Cream CRV',
    address: '0xc7Fd8Dcee4697ceef5a2fd4608a7BD6A94C77480',
    symbol: 'crCRV',
    underlyingAddress: '0xD533a949740bb3306d119CC777fa900bA034cd52',
    underlyingDecimal: 18,
    underlyingName: 'Curve DAO Token',
    underlyingSymbol: 'CRV',
    category: Category.gov,
    zeroAllowance: true,
  },
  {
    name: 'Cream renBTC',
    address: '0x17107f40d70f4470d20CB3f138a052cAE8EbD4bE',
    symbol: 'crRENBTC',
    underlyingAddress: '0xEB4C2781e4ebA804CE9a9803C67d0893436bB27D',
    underlyingDecimal: 8,
    underlyingName: 'renBTC',
    underlyingSymbol: 'RENBTC',
    category: Category.major,
    zeroAllowance: false,
  },
  {
    name: 'Cream Binance USD',
    address: '0x1FF8CDB51219a8838b52E9cAc09b71e591BC998e',
    symbol: 'crBUSD',
    underlyingAddress: '0x4Fabb145d64652a948d72533023f6E7A623C7C53',
    underlyingDecimal: 18,
    underlyingName: 'Binance USD',
    underlyingSymbol: 'BUSD',
    category: Category.stable,
    zeroAllowance: false,
  },
  {
    name: 'Cream Meta',
    address: '0x3623387773010d9214B10C551d6e7fc375D31F58',
    symbol: 'crMTA',
    underlyingAddress: '0xa3BeD4E1c75D00fa6f4E5E6922DB7261B5E9AcD2',
    underlyingDecimal: 18,
    underlyingName: 'Meta',
    underlyingSymbol: 'MTA',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream yyCRV',
    address: '0x4EE15f44c6F0d8d1136c83EfD2e8E4AC768954c6',
    symbol: 'crYYCRV',
    underlyingAddress: '0x5dbcF33D8c2E976c6b560249878e6F1491Bca25c',
    underlyingDecimal: 18,
    underlyingName: 'yearn Curve.fi yDAI/yUSDC/yUSDT/yTUSD',
    underlyingSymbol: 'YYCRV',
    category: Category.legacy,
    zeroAllowance: false,
  },
  {
    name: 'Cream SushiToken',
    address: '0x338286C0BC081891A4Bda39C7667ae150bf5D206',
    symbol: 'crSUSHI',
    underlyingAddress: '0x6B3595068778DD592e39A122f4f5a5cF09C90fE2',
    underlyingDecimal: 18,
    underlyingName: 'SushiToken',
    underlyingSymbol: 'SUSHI',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream FTX Token',
    address: '0x10FDBD1e48eE2fD9336a482D746138AE19e649Db',
    symbol: 'crFTT',
    underlyingAddress: '0x50D1c9771902476076eCFc8B2A83Ad6b9355a4c9',
    underlyingDecimal: 18,
    underlyingName: 'FTT',
    underlyingSymbol: 'FTT',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream yETH',
    address: '0x01da76DEa59703578040012357b81ffE62015C2d',
    symbol: 'crYETH',
    underlyingAddress: '0xe1237aA7f535b0CC33Fd973D66cBf830354D16c7',
    underlyingDecimal: 18,
    underlyingName: 'yearn Wrapped Ether',
    underlyingSymbol: 'YETH',
    category: Category.legacy,
    zeroAllowance: false,
  },
  {
    name: 'Cream Serum',
    address: '0xef58b2d5A1b8D3cDE67b8aB054dC5C831E9Bc025',
    symbol: 'crSRM',
    underlyingAddress: '0x476c5E26a75bd202a9683ffD34359C0CC15be0fF',
    underlyingDecimal: 6,
    underlyingName: 'Serum',
    underlyingSymbol: 'SRM',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream Uniswap',
    address: '0xe89a6D0509faF730BD707bf868d9A2A744a363C7',
    symbol: 'crUNI',
    underlyingAddress: '0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984',
    underlyingDecimal: 18,
    underlyingName: 'Uniswap',
    underlyingSymbol: 'UNI',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream Wrapped NXM',
    address: '0xeFF039C3c1D668f408d09dD7B63008622a77532C',
    symbol: 'crWNXM',
    underlyingAddress: '0x0d438F3b5175Bebc262bF23753C1E53d03432bDE',
    underlyingDecimal: 18,
    underlyingName: 'Wrapped NXM',
    underlyingSymbol: 'WNXM',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream Swag Token',
    address: '0x22B243B96495C547598D9042B6f94B01C22B2e9E',
    symbol: 'crSWAG',
    underlyingAddress: '0x87eDfFDe3E14c7a66c9b9724747a1C5696b742e6',
    underlyingDecimal: 18,
    underlyingName: 'Swag Token',
    underlyingSymbol: 'SWAG',
    category: Category.legacy,
    zeroAllowance: false,
  },
  {
    name: 'Cream Celsius',
    address: '0x8b3FF1ed4F36C2c2be675AFb13CC3AA5d73685a5',
    symbol: 'crCEL',
    underlyingAddress: '0xaaAEBE6Fe48E54f431b0C390CfaF0b017d09D42d',
    underlyingDecimal: 4,
    underlyingName: 'Celsius',
    underlyingSymbol: 'CEL',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream DefiPulse Index',
    address: '0x2A537Fa9FFaea8C1A41D3C2B68a9cb791529366D',
    symbol: 'crDPI',
    underlyingAddress: '0x1494CA1F11D487c2bBe4543E90080AeBa4BA3C2b',
    underlyingDecimal: 18,
    underlyingName: 'DefiPulse Index',
    underlyingSymbol: 'DPI',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream Binance Wrapped BTC',
    address: '0x7ea9C63E216D5565c3940A2B3d150e59C2907Db3',
    symbol: 'crBBTC',
    underlyingAddress: '0x9BE89D2a4cd102D8Fecc6BF9dA793be995C22541',
    underlyingDecimal: 8,
    underlyingName: 'Binance Wrapped BTC',
    underlyingSymbol: 'BBTC',
    category: Category.major,
    zeroAllowance: false,
  },
  {
    name: 'Cream AAVE Token',
    address: '0x3225E3C669B39C7c8B3e204a8614bB218c5e31BC',
    symbol: 'crAAVE',
    underlyingAddress: '0x7Fc66500c84A76Ad7e9c93437bFc5Ac33E2DDaE9',
    underlyingDecimal: 18,
    underlyingName: 'Aave Token',
    underlyingSymbol: 'AAVE',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream BarnBridge Governance Token',
    address: '0xf55BbE0255f7f4E70f63837Ff72A577fbDDbE924',
    symbol: 'crBOND',
    underlyingAddress: '0x0391D2021f89DC339F60Fff84546EA23E337750f',
    underlyingDecimal: 18,
    underlyingName: 'BarnBridge Governance Token',
    underlyingSymbol: 'BOND',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream Keep3rV1',
    address: '0x903560b1CcE601794C584F58898dA8a8b789Fc5d',
    symbol: 'crKP3R',
    underlyingAddress: '0x1cEB5cB57C4D4E2b2433641b95Dd330A33185A44',
    underlyingDecimal: 18,
    underlyingName: 'Keep3rV1',
    underlyingSymbol: 'KP3R',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream Huobi BTC',
    address: '0x054B7ed3F45714d3091e82aAd64A1588dC4096Ed',
    symbol: 'crHBTC',
    underlyingAddress: '0x0316EB71485b0Ab14103307bf65a021042c6d380',
    underlyingDecimal: 18,
    underlyingName: 'Huobi BTC',
    underlyingSymbol: 'HBTC',
    category: Category.major,
    zeroAllowance: false,
  },
  {
    name: 'Cream Huobi FIL',
    address: '0xd5103AfcD0B3fA865997Ef2984C66742c51b2a8b',
    symbol: 'crHFIL',
    underlyingAddress: '0x9AFb950948c2370975fb91a441F36FDC02737cD4',
    underlyingDecimal: 18,
    underlyingName: 'Huobi FIL',
    underlyingSymbol: 'HFIL',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream Cream ETH 2',
    address: '0xfd609a03B393F1A1cFcAcEdaBf068CAD09a924E2',
    symbol: 'crCRETH2',
    underlyingAddress: '0xcBc1065255cBc3aB41a6868c22d1f1C573AB89fd',
    underlyingDecimal: 18,
    underlyingName: 'Cream ETH 2',
    underlyingSymbol: 'CRETH2',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream HUSD',
    address: '0xD692ac3245bb82319A31068D6B8412796eE85d2c',
    symbol: 'crHUSD',
    underlyingAddress: '0xdF574c24545E5FfEcb9a659c229253D4111d87e1',
    underlyingDecimal: 8,
    underlyingName: 'HUSD',
    underlyingSymbol: 'HUSD',
    category: Category.stable,
    zeroAllowance: false,
  },
  {
    name: 'Cream Dai Stablecoin',
    address: '0x92B767185fB3B04F881e3aC8e5B0662a027A1D9f',
    symbol: 'crDAI',
    underlyingAddress: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
    underlyingDecimal: 18,
    underlyingName: 'Dai Stablecoin',
    underlyingSymbol: 'DAI',
    category: Category.stable,
    zeroAllowance: false,
  },
  {
    name: 'Cream Hegic',
    address: '0x10a3da2BB0Fae4D591476fd97D6636fd172923a8',
    symbol: 'crHEGIC',
    underlyingAddress: '0x584bC13c7D411c00c01A62e8019472dE68768430',
    underlyingDecimal: 18,
    underlyingName: 'Hegic',
    underlyingSymbol: 'HEGIC',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream Empty Set Dollar',
    address: '0x3C6C553A95910F9FC81c98784736bd628636D296',
    symbol: 'crESD',
    underlyingAddress: '0x36F3FD68E7325a35EB768F1AedaAe9EA0689d723',
    underlyingDecimal: 18,
    underlyingName: 'Empty Set Dollar',
    underlyingSymbol: 'ESD',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream Cover Protocol Governance Token',
    address: '0x21011BC93d9E515B9511A817A1eD1D6d468f49Fc',
    symbol: 'crCOVER',
    underlyingAddress: '0x4688a8b1F292FDaB17E9a90c8Bc379dC1DBd8713',
    underlyingDecimal: 18,
    underlyingName: 'Cover Protocol Governance Token',
    underlyingSymbol: 'COVER',
    category: Category.legacy,
    zeroAllowance: false,
  },
  {
    name: 'Cream 1INCH Token',
    address: '0x85759961b116f1D36fD697855c57A6ae40793D9B',
    symbol: 'cr1INCH',
    underlyingAddress: '0x111111111117dC0aa78b770fA6A738034120C302',
    underlyingDecimal: 18,
    underlyingName: '1INCH Token',
    underlyingSymbol: '1INCH',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream OMGToken',
    address: '0x7Aaa323D7e398be4128c7042d197a2545f0f1fea',
    symbol: 'crOMG',
    underlyingAddress: '0xd26114cd6EE289AccF82350c8d8487fedB8A0C07',
    underlyingDecimal: 18,
    underlyingName: 'OMGToken',
    underlyingSymbol: 'OMG',
    category: Category.gov,
    zeroAllowance: true,
  },
  {
    name: 'Cream Uniswap V2 WBTC-ETH',
    address: '0x011a014d5e8Eb4771E575bB1000318D509230Afa',
    symbol: 'crUNI-V2-WBTC-ETH',
    underlyingAddress: '0xBb2b8038a1640196FbE3e38816F3e67Cba72D940',
    underlyingDecimal: 18,
    underlyingName: 'Uniswap V2',
    underlyingSymbol: 'UNI-V2-WBTC-ETH',
    category: Category.wrapped,
    zeroAllowance: false,
  },
  {
    name: 'Cream Uniswap V2 ETH-USDT',
    address: '0xE6C3120F38F56deb38B69b65cC7dcAF916373963',
    symbol: 'crUNI-V2-ETH-USDT',
    underlyingAddress: '0x0d4a11d5EEaaC28EC3F61d100daF4d40471f1852',
    underlyingDecimal: 18,
    underlyingName: 'Uniswap V2',
    underlyingSymbol: 'UNI-V2-ETH-USDT',
    category: Category.wrapped,
    zeroAllowance: false,
  },
  {
    name: 'Cream Uniswap V2 USDC-ETH',
    address: '0x4Fe11BC316B6d7A345493127fBE298b95AdaAd85',
    symbol: 'crUNI-V2-USDC-ETH',
    underlyingAddress: '0xB4e16d0168e52d35CaCD2c6185b44281Ec28C9Dc',
    underlyingDecimal: 18,
    underlyingName: 'Uniswap V2',
    underlyingSymbol: 'UNI-V2-USDC-ETH',
    category: Category.wrapped,
    zeroAllowance: false,
  },
  {
    name: 'Cream Uniswap V2 DAI-ETH',
    address: '0xcD22C4110c12AC41aCEfA0091c432ef44efaAFA0',
    symbol: 'crUNI-V2-DAI-ETH',
    underlyingAddress: '0xA478c2975Ab1Ea89e8196811F51A7B7Ade33eB11',
    underlyingDecimal: 18,
    underlyingName: 'Uniswap V2',
    underlyingSymbol: 'UNI-V2-DAI-ETH',
    category: Category.wrapped,
    zeroAllowance: false,
  },
  {
    name: 'Cream SushiBar',
    address: '0x228619CCa194Fbe3Ebeb2f835eC1eA5080DaFbb2',
    symbol: 'crXSUSHI',
    underlyingAddress: '0x8798249c2E607446EfB7Ad49eC89dD1865Ff4272',
    underlyingDecimal: 18,
    underlyingName: 'SushiBar',
    underlyingSymbol: 'XSUSHI',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream SushiSwap LP Token WBTC-ETH',
    address: '0x73f6cBA38922960b7092175c0aDD22Ab8d0e81fC',
    symbol: 'crSLP-WBTC-ETH',
    underlyingAddress: '0xCEfF51756c56CeFFCA006cD410B03FFC46dd3a58',
    underlyingDecimal: 18,
    underlyingName: 'SushiSwap LP Token',
    underlyingSymbol: 'SLP-WBTC-ETH',
    category: Category.wrapped,
    zeroAllowance: false,
  },
  {
    name: 'Cream SushiSwap LP Token DAI-ETH',
    address: '0x38f27c03d6609a86FF7716ad03038881320BE4Ad',
    symbol: 'crSLP-DAI-ETH',
    underlyingAddress: '0xC3D03e4F041Fd4cD388c549Ee2A29a9E5075882f',
    underlyingDecimal: 18,
    underlyingName: 'SushiSwap LP Token',
    underlyingSymbol: 'SLP-DAI-ETH',
    category: Category.wrapped,
    zeroAllowance: false,
  },
  {
    name: 'Cream SushiSwap LP Token USDC-ETH',
    address: '0x5EcaD8A75216CEa7DFF978525B2D523a251eEA92',
    symbol: 'crSLP-USDC-ETH',
    underlyingAddress: '0x397FF1542f962076d0BFE58eA045FfA2d347ACa0',
    underlyingDecimal: 18,
    underlyingName: 'SushiSwap LP Token',
    underlyingSymbol: 'SLP-USDC-ETH',
    category: Category.wrapped,
    zeroAllowance: false,
  },
  {
    name: 'Cream SushiSwap LP Token ETH-USDT',
    address: '0x5C291bc83d15f71fB37805878161718eA4b6AEe9',
    symbol: 'crSLP-ETH-USDT',
    underlyingAddress: '0x06da0fd433C1A5d7a4faa01111c044910A184553',
    underlyingDecimal: 18,
    underlyingName: 'SushiSwap LP Token',
    underlyingSymbol: 'SLP-ETH-USDT',
    category: Category.wrapped,
    zeroAllowance: false,
  },
  {
    name: 'Cream SushiSwap LP Token SUSHI-ETH',
    address: '0x6BA0C66C48641e220CF78177C144323b3838D375',
    symbol: 'crSLP-SUSHI-ETH',
    underlyingAddress: '0x795065dCc9f64b5614C407a6EFDC400DA6221FB0',
    underlyingDecimal: 18,
    underlyingName: 'SushiSwap LP Token',
    underlyingSymbol: 'SLP-SUSHI-ETH',
    category: Category.wrapped,
    zeroAllowance: false,
  },
  {
    name: 'Cream SushiSwap LP Token YFI-ETH',
    address: '0xd532944df6DFd5Dd629E8772F03D4fC861873abF',
    symbol: 'crSLP-YFI-ETH',
    underlyingAddress: '0x088ee5007C98a9677165D78dD2109AE4a3D04d0C',
    underlyingDecimal: 18,
    underlyingName: 'SushiSwap LP Token',
    underlyingSymbol: 'SLP-YFI-ETH',
    category: Category.wrapped,
    zeroAllowance: false,
  },
  {
    name: 'Cream Wrapped BTC',
    address: '0x197070723CE0D3810a0E47F06E935c30a480D4Fc',
    symbol: 'crWBTC',
    underlyingAddress: '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
    underlyingDecimal: 8,
    underlyingName: 'Wrapped BTC',
    underlyingSymbol: 'WBTC',
    category: Category.major,
    zeroAllowance: false,
  },
  {
    name: 'Cream Synthetix Network Token',
    address: '0xC25EAE724f189Ba9030B2556a1533E7c8A732E14',
    symbol: 'crSNX',
    underlyingAddress: '0xC011a73ee8576Fb46F5E1c5751cA3B9Fe0af2a6F',
    underlyingDecimal: 18,
    underlyingName: 'Synthetix Network Token',
    underlyingSymbol: 'SNX',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream Synth sUSD',
    address: '0x25555933a8246Ab67cbf907CE3d1949884E82B55',
    symbol: 'crSUSD',
    underlyingAddress: '0x57Ab1ec28D129707052df4dF418D58a2D46d5f51',
    underlyingDecimal: 18,
    underlyingName: 'Synth sUSD',
    underlyingSymbol: 'SUSD',
    category: Category.stable,
    zeroAllowance: false,
  },
  {
    name: 'Cream PickleToken',
    address: '0xc68251421eDDa00a10815E273fA4b1191fAC651b',
    symbol: 'crPICKLE',
    underlyingAddress: '0x429881672B9AE42b8EbA0E26cD9C73711b891Ca5',
    underlyingDecimal: 18,
    underlyingName: 'PickleToken',
    underlyingSymbol: 'PICKLE',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream Akropolis',
    address: '0x65883978aDA0e707c3b2BE2A6825b1C4BDF76A90',
    symbol: 'crAKRO',
    underlyingAddress: '0x8Ab7404063Ec4DBcfd4598215992DC3F8EC853d7',
    underlyingDecimal: 18,
    underlyingName: 'Akropolis',
    underlyingSymbol: 'AKRO',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream Badger Sett Badger',
    address: '0x8B950f43fCAc4931D408F1fcdA55C6CB6cbF3096',
    symbol: 'crBBADGER',
    underlyingAddress: '0x19D97D8fA813EE2f51aD4B4e04EA08bAf4DFfC28',
    underlyingDecimal: 18,
    underlyingName: 'Badger Sett Badger',
    underlyingSymbol: 'BBADGER',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream OriginToken',
    address: '0x59089279987DD76fC65Bf94Cb40E186b96e03cB3',
    symbol: 'crOGN',
    underlyingAddress: '0x8207c1FfC5B6804F6024322CcF34F29c3541Ae26',
    underlyingDecimal: 18,
    underlyingName: 'OriginToken',
    underlyingSymbol: 'OGN',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream Amp',
    address: '0x2Db6c82CE72C8d7D770ba1b5F5Ed0b6E075066d6',
    symbol: 'crAMP',
    underlyingAddress: '0xfF20817765cB7f73d4bde2e66e067E58D11095C2',
    underlyingDecimal: 18,
    underlyingName: 'Amp',
    underlyingSymbol: 'AMP',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream Frax',
    address: '0xb092b4601850E23903A42EaCBc9D8A0EeC26A4d5',
    symbol: 'crFRAX',
    underlyingAddress: '0x853d955aCEf822Db058eb8505911ED77F175b99e',
    underlyingDecimal: 18,
    underlyingName: 'Frax',
    underlyingSymbol: 'FRAX',
    category: Category.stable,
    zeroAllowance: false,
  },
  {
    name: 'Cream AlphaToken',
    address: '0x1d0986Fb43985c88Ffa9aD959CC24e6a087C7e35',
    symbol: 'crALPHA',
    underlyingAddress: '0xa1faa113cbE53436Df28FF0aEe54275c13B40975',
    underlyingDecimal: 18,
    underlyingName: 'AlphaToken',
    underlyingSymbol: 'ALPHA',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream Wrapped UST Token',
    address: '0x51F48b638F82e8765F7a26373A2Cb4CcB10C07af',
    symbol: 'crUST',
    underlyingAddress: '0xa47c8bf37f92aBed4A126BDA807A7b7498661acD',
    underlyingDecimal: 18,
    underlyingName: 'Wrapped UST Token',
    underlyingSymbol: 'UST',
    category: Category.stable,
    zeroAllowance: false,
  },
  {
    name: 'Cream Fantom Token',
    address: '0xc36080892c64821fa8e396bc1bD8678fA3b82b17',
    symbol: 'crFTM',
    underlyingAddress: '0x4E15361FD6b4BB609Fa63C81A2be19d873717870',
    underlyingDecimal: 18,
    underlyingName: 'Fantom Token',
    underlyingSymbol: 'FTM',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream THORChain ETH.RUNE',
    address: '0x8379BAA817c5c5aB929b03ee8E3c48e45018Ae41',
    symbol: 'crRUNE',
    underlyingAddress: '0x3155BA85D5F96b2d030a4966AF206230e46849cb',
    underlyingDecimal: 18,
    underlyingName: 'THORChain ETH.RUNE',
    underlyingSymbol: 'RUNE',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream Perpetual',
    address: '0x299e254A8a165bBeB76D9D69305013329Eea3a3B',
    symbol: 'crPERP',
    underlyingAddress: '0xbC396689893D065F41bc2C6EcbeE5e0085233447',
    underlyingDecimal: 18,
    underlyingName: 'Perpetual',
    underlyingSymbol: 'PERP',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream Rai Reflex Index',
    address: '0xf8445C529D363cE114148662387eba5E62016e20',
    symbol: 'crRAI',
    underlyingAddress: '0x03ab458634910AaD20eF5f1C8ee96F1D6ac54919',
    underlyingDecimal: 18,
    underlyingName: 'Rai Reflex Index',
    underlyingSymbol: 'RAI',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream Ocean Token',
    address: '0x7C3297cFB4c4bbd5f44b450c0872E0ADA5203112',
    symbol: 'crOCEAN',
    underlyingAddress: '0x967da4048cD07aB37855c090aAF366e4ce1b9F48',
    underlyingDecimal: 18,
    underlyingName: 'Ocean Token',
    underlyingSymbol: 'OCEAN',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream Rarible',
    address: '0x081FE64df6dc6fc70043aedF3713a3ce6F190a21',
    symbol: 'crRARI',
    underlyingAddress: '0xFca59Cd816aB1eaD66534D82bc21E7515cE441CF',
    underlyingDecimal: 18,
    underlyingName: 'Rarible',
    underlyingSymbol: 'RARI',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream Spice',
    address: '0x28526Bb33d7230E65E735dB64296413731C5402e',
    symbol: 'crSFI',
    underlyingAddress: '0xb753428af26E81097e7fD17f40c88aaA3E04902c',
    underlyingDecimal: 18,
    underlyingName: 'Spice',
    underlyingSymbol: 'SFI',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream yvCurve-IronBank',
    address: '0x45406ba53bB84Cd32A58e7098a2D4D1b11B107F6',
    symbol: 'crYVCurve-IronBank',
    underlyingAddress: '0x27b7b1ad7288079A66d12350c828D3C00A6F07d7',
    underlyingDecimal: 18,
    underlyingName: 'Curve Iron Bank Pool yVault',
    underlyingSymbol: 'yvCurve-IronBank',
    category: Category.wrapped,
    zeroAllowance: false,
  },
  {
    name: 'Cream yvCurve-sETH',
    address: '0x6d1B9e01aF17Dd08d6DEc08E210dfD5984FF1C20',
    symbol: 'crYVCurve-sETH',
    underlyingAddress: '0x986b4AFF588a109c09B50A03f42E4110E29D353F',
    underlyingDecimal: 18,
    underlyingName: 'Curve sETH Pool yVault',
    underlyingSymbol: 'yvCurve-sETH',
    category: Category.wrapped,
    zeroAllowance: false,
  },
  {
    name: 'Cream yvCurve-stETH',
    address: '0x1F9b4756B008106C806c7E64322d7eD3B72cB284',
    symbol: 'crYVCurve-stETH',
    underlyingAddress: '0xdCD90C7f6324cfa40d7169ef80b12031770B4325',
    underlyingDecimal: 18,
    underlyingName: 'Curve stETH Pool yVault',
    underlyingSymbol: 'yvCurve-stETH',
    category: Category.wrapped,
    zeroAllowance: false,
  },
  {
    name: 'Cream Armor',
    address: '0xab10586C918612BA440482db77549d26B7ABF8f7',
    symbol: 'crARMOR',
    underlyingAddress: '0x1337DEF16F9B486fAEd0293eb623Dc8395dFE46a',
    underlyingDecimal: 18,
    underlyingName: 'Armor',
    underlyingSymbol: 'ARMOR',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream Armor NXM',
    address: '0xdFFf11DFe6436e42a17B86e7F419Ac8292990393',
    symbol: 'crARNXM',
    underlyingAddress: '0x1337DEF18C680aF1f9f45cBcab6309562975b1dD',
    underlyingDecimal: 18,
    underlyingName: 'Armor NXM',
    underlyingSymbol: 'arNXM',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream Melon Token',
    address: '0xDbb5e3081dEf4b6cdD8864aC2aeDA4cBf778feCf',
    symbol: 'crMLN',
    underlyingAddress: '0xec67005c4E498Ec7f55E092bd1d35cbC47C91892',
    underlyingDecimal: 18,
    underlyingName: 'Melon Token',
    underlyingSymbol: 'MLN',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream Vesper Token',
    address: '0x71cEFCd324B732d4E058AfAcBA040d908c441847',
    symbol: 'crVSP',
    underlyingAddress: '0x1b40183EFB4Dd766f11bDa7A7c3AD8982e998421',
    underlyingDecimal: 18,
    underlyingName: 'Vesper Token',
    underlyingSymbol: 'VSP',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream vVSP pool',
    address: '0x1A122348B73B58eA39F822A89e6ec67950c2bBD0',
    symbol: 'crVVSP',
    underlyingAddress: '0xbA4cFE5741b357FA371b506e5db0774aBFeCf8Fc',
    underlyingDecimal: 18,
    underlyingName: 'vVSP pool',
    underlyingSymbol: 'vVSP',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream Gnosis Token',
    address: '0x523EFFC8bFEfC2948211A05A905F761CBA5E8e9E',
    symbol: 'crGNO',
    underlyingAddress: '0x6810e776880C02933D47DB1b9fc05908e5386b96',
    underlyingDecimal: 18,
    underlyingName: 'Gnosis Token',
    underlyingSymbol: 'GNO',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream yvWETH',
    address: '0x4202D97E00B9189936EdF37f8D01cfF88BDd81d4',
    symbol: 'crYVWETH',
    underlyingAddress: '0xa9fE4601811213c340e850ea305481afF02f5b28',
    underlyingDecimal: 18,
    underlyingName: 'WETH yVault',
    underlyingSymbol: 'yvWETH',
    category: Category.wrapped,
    zeroAllowance: false,
  },
  {
    name: 'Cream yUSD',
    address: '0x4BAa77013ccD6705ab0522853cB0E9d453579Dd4',
    symbol: 'crYUSD',
    underlyingAddress: '0x4B5BfD52124784745c1071dcB244C6688d2533d3',
    underlyingDecimal: 18,
    underlyingName: 'Curve Y Pool yVault',
    underlyingSymbol: 'yUSD',
    category: Category.wrapped,
    zeroAllowance: false,
  },
  {
    name: 'Cream TrustSwap Token',
    address: '0x98E329eB5aae2125af273102f3440DE19094b77c',
    symbol: 'crSWAP',
    underlyingAddress: '0xCC4304A31d09258b0029eA7FE63d032f52e44EFe',
    underlyingDecimal: 18,
    underlyingName: 'TrustSwap Token',
    underlyingSymbol: 'SWAP',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream Fei USD',
    address: '0x8C3B7a4320ba70f8239F83770c4015B5bc4e6F91',
    symbol: 'crFEI',
    underlyingAddress: '0x956F47F50A910163D8BF957Cf5846D573E7f87CA',
    underlyingDecimal: 18,
    underlyingName: 'Fei USD',
    underlyingSymbol: 'FEI',
    category: Category.stable,
    zeroAllowance: false,
  },
  {
    name: 'Cream Wootrade Network',
    address: '0xE585c76573D7593ABF21537B607091F76c996E73',
    symbol: 'crWOO',
    underlyingAddress: '0x4691937a7508860F876c9c0a2a617E7d9E945D4B',
    underlyingDecimal: 18,
    underlyingName: 'Wootrade Network',
    underlyingSymbol: 'WOO',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream Bancor Network Token',
    address: '0x81E346729723C4D15d0FB1c5679b9f2926Ff13C6',
    symbol: 'crBNT',
    underlyingAddress: '0x1F573D6Fb3F13d689FF844B4cE37794d79a7FF1C',
    underlyingDecimal: 18,
    underlyingName: 'Bancor Network Token',
    underlyingSymbol: 'BNT',
    category: Category.gov,
    zeroAllowance: true,
  },
  {
    name: 'Cream Euro Tether',
    address: '0x766175eaC1A99C969dDd1EBDBe7e270D508d8FFF',
    symbol: 'crEURT',
    underlyingAddress: '0xC581b735A1688071A1746c968e0798D642EDE491',
    underlyingDecimal: 6,
    underlyingName: 'Euro Tether',
    underlyingSymbol: 'EURT',
    category: Category.stable,
    zeroAllowance: false,
  },
  {
    name: 'Cream Paxos Standard',
    address: '0xD7394428536F63d5659cc869EF69d10f9E66314B',
    symbol: 'crPAX',
    underlyingAddress: '0x8E870D67F660D95d5be530380D0eC0bd388289E1',
    underlyingDecimal: 18,
    underlyingName: 'Paxos Standard',
    underlyingSymbol: 'PAX',
    category: Category.stable,
    zeroAllowance: false,
  },
  {
    name: 'Cream Paxos Gold',
    address: '0x1241B10E7EA55b22f5b2d007e8fECDF73DCff999',
    symbol: 'crPAXG',
    underlyingAddress: '0x45804880De22913dAFE09f4980848ECE6EcbAf78',
    underlyingDecimal: 18,
    underlyingName: 'Paxos Gold',
    underlyingSymbol: 'PAXG',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream Interest-Bearing BTC',
    address: '0x2A867fd776B83e1bd4e13C6611AFd2F6af07EA6D',
    symbol: 'crIBBTC',
    underlyingAddress: '0xc4E15973E6fF2A35cC804c2CF9D2a1b817a8b40F',
    underlyingDecimal: 18,
    underlyingName: 'Interest-Bearing BTC',
    underlyingSymbol: 'ibBTC',
    category: Category.major,
    zeroAllowance: false,
  },
  {
    name: 'Cream Tokenlon',
    address: '0x250Fb308199FE8C5220509C1bf83D21d60b7f74A',
    symbol: 'crLON',
    underlyingAddress: '0x0000000000095413afC295d19EDeb1Ad7B71c952',
    underlyingDecimal: 18,
    underlyingName: 'LON Token',
    underlyingSymbol: 'LON',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream Decentraland MANA',
    address: '0x58DA9c9fC3eb30AbBcbBAb5DDabb1E6e2eF3d2EF',
    symbol: 'crMANA',
    underlyingAddress: '0x0F5D2fB29fb7d3CFeE444a200298f468908cC942',
    underlyingDecimal: 18,
    underlyingName: 'Decentraland MANA',
    underlyingSymbol: 'MANA',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream SAND',
    address: '0x89e42987c39f72e2EAd95a8a5bC92114323d5828',
    symbol: 'crSAND',
    underlyingAddress: '0x3845badAde8e6dFF049820680d1F14bD3903a5d0',
    underlyingDecimal: 18,
    underlyingName: 'SAND',
    underlyingSymbol: 'SAND',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream Axie Infinity Shard',
    address: '0xF04ce2e71D32D789a259428ddcD02D3C9F97fb4E',
    symbol: 'crAXS',
    underlyingAddress: '0xBB0E17EF65F82Ab018d8EDd776e8DD940327B28b',
    underlyingDecimal: 18,
    underlyingName: 'Axie Infinity Shard',
    underlyingSymbol: 'AXS',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream Yield Guild Games Token',
    address: '0x4112a717edD051F77d834A6703a1eF5e3d73387F',
    symbol: 'crYGG',
    underlyingAddress: '0x25f8087EAD173b73D6e8B84329989A8eEA16CF73',
    underlyingDecimal: 18,
    underlyingName: 'Yield Guild Games Token',
    underlyingSymbol: 'YGG',
    category: Category.gov,
    zeroAllowance: false,
  },
];

export default EthereumMarkets;
