import { BigNumber } from 'ethers';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import ArbitrumMarkets from '../cream/markets/arbitrum';
import AvalancheMarkets from '../cream/markets/avalanche';
import BSCMarkets from '../cream/markets/bsc';
import EthereumMarkets from '../cream/markets/ethereum';
import FujiMarkets from '../cream/markets/fuji';
import PolygonMarkets from '../cream/markets/polygon';
import FantomMarkets from '../cream/markets/fantom';
import GoerliMarkets from '../cream/markets/goerli';
import { Market, MarketStats, UserTokenStats, UserLiquidityRewards, LMRewardStats } from '../cream/Type';
import { ConnectionContext } from './ConnectionProvider';
import { CreamContext } from './CreamProvider';
import { ProtocolContext } from './ProtocolProvider';

interface Context {
  markets: Market[];
  allMarketStats: MarketStats[];
  allUserTokenStats: UserTokenStats[];
  userLiquidityRewards: UserLiquidityRewards[];
  lmRewardsStats: LMRewardStats[];
  basePrice: number,
  isLoading: boolean
}

export const MarketContext = createContext<Context>({
  markets: [],
  allMarketStats: [],
  allUserTokenStats: [],
  userLiquidityRewards: [],
  lmRewardsStats: [],
  basePrice: 0,
  isLoading: false
});

const initMarketStats = (markets: Market[]) => {
  return markets.map<MarketStats>(() => (
    {
      address: '',
      supply: BigNumber.from(0),
      borrow: BigNumber.from(0),
      cash: BigNumber.from(0),
      reserves: BigNumber.from(0),
      borrowRate: BigNumber.from(0),
      exchangeRate: BigNumber.from(0),
      supplyRate: BigNumber.from(0),
      borrowPaused: false,
      collateralFactor: BigNumber.from(0),
      supplyPaused: false,
      underlyingPrice: BigNumber.from(0),
      supplyCap: BigNumber.from(0),
      borrowCap: BigNumber.from(0),
      collateralCap: BigNumber.from(0),
      totalCollateralTokens: BigNumber.from(0),
      version: 0,
    }
  ));
}

const initLMRewardStats = (markets: Market[]) => {
  return markets.map<LMRewardStats>(() => (
    {
      rewardSpeeds: [],
    }
  ));
}

const initUserStats = (markets: Market[]) => {
  return markets.map<UserTokenStats>(() => (
    {
      address: '',
      collateralEnabled: false,
      crTokenBalance: BigNumber.from(0),
      underlyingBalance: BigNumber.from(0),
      walletBalance: BigNumber.from(0),
      borrowBalance: BigNumber.from(0),
      collateralBalance: BigNumber.from(0),
      nativeTokenBalance: BigNumber.from(0),
    }
  ));
}

const initUserLiquidityRewards = (markets: Market[]) => {
  return markets.map<UserLiquidityRewards>(() => (
    {
      reward: BigNumber.from(0),
      address: '',
    }
  ));
}

const MarketProvider: React.FC = ({ children }) => {
  const { walletAddress } = useContext(ConnectionContext);
  const { protocol } = useContext(ProtocolContext);
  const { cream } = useContext(CreamContext);

  const markets = protocol.markets;
  const lpMarkets = protocol.lpMarkets;

  const [context, setContext] = useState<Context>({
    markets,
    allMarketStats: initMarketStats(markets),
    allUserTokenStats: initUserStats(markets),
    userLiquidityRewards: initUserLiquidityRewards(lpMarkets),
    lmRewardsStats: initLMRewardStats(markets),
    basePrice: 0,
    isLoading: false
  })

  useEffect(() => {
    if (!cream) {
      return;
    }

    setContext({
      markets,
      allMarketStats: initMarketStats(markets),
      allUserTokenStats: initUserStats(markets),
      userLiquidityRewards: initUserLiquidityRewards(lpMarkets),
      lmRewardsStats: initLMRewardStats(markets),
      basePrice: 0,
      isLoading: true
    });

    let canceled = false;

    const fetchData = () => {
      (async () => {
        const allMarketStats = await cream.getMarketStats(markets);
        const lmRewardsStats = await cream.getLMRewardsStats(markets);
        const allUserTokenStats = walletAddress ? await cream.getUserStats(walletAddress, markets) : initUserStats(markets);
        const userLiquidityRewards = walletAddress ? await cream.getUserLiquidityRewards(walletAddress, lpMarkets) : initUserLiquidityRewards(lpMarkets);
        const basePrice = await cream.getBasePrice();
        if (!canceled) {
          setContext({
            markets,
            allMarketStats,
            allUserTokenStats,
            userLiquidityRewards,
            lmRewardsStats,
            basePrice,
            isLoading: false
          })
        }
      })().catch((error) => {
        console.error('failed to fetch market data:', error)
      });
    }

    fetchData();
    const id = setInterval(() => {
      fetchData()
    }, protocol.refreshRate * 1000);

    return () => {
      canceled = true;
      clearInterval(id);
    }
  }, [markets, lpMarkets, cream, walletAddress, protocol])

  return (
    <MarketContext.Provider value={context}>
      { children }
    </MarketContext.Provider>
  )
}

export default MarketProvider;
