import { BigNumber, Contract } from 'ethers';
import { Signer } from "@ethersproject/abstract-signer";
import { Provider } from '@ethersproject/abstract-provider';

import CreamETH2OracleABI from './ABIs/creamETH2Oracle';

class CreamETH2Oracle {
  private contract: Contract;

  address: string;

  constructor(address: string, provider: Provider | Signer) {
    this.contract = new Contract(address, CreamETH2OracleABI, provider);
    this.address = address;
  }

  exchangeRate(): Promise<BigNumber> {
    return this.contract.callStatic.exchangeRate();
  }
}

export default CreamETH2Oracle;
