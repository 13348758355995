import { BigNumber, ethers } from 'ethers';
import { useCallback, useContext, useEffect, useState } from 'react';
import Erc20 from '../cream/contract/Erc20';
import { Market } from '../cream/Type';
import { ConnectionContext } from '../providers/ConnectionProvider';
import useIsNativeContract from './useIsNativeContract';
import useModal from './useModal';
import ResetAllowanceModal from '../components/Modal/ResetAllowanceModal';

export enum ApproveState {
  UNKNOWN,
  NOT_APPROVED,
  APPROVED,
}

const useApprove = (market: Market) => {
  const { signer, provider, connected, walletAddress } = useContext(ConnectionContext);
  const { presentModal } = useModal();

  const [allowance, setAllowance] = useState<BigNumber>(BigNumber.from(0));
  const [approveState, setApproveState] = useState(ApproveState.UNKNOWN);
  const isNative = useIsNativeContract(market.address);

  const { address: spender, underlyingAddress } = market;

  const contract = useCallback(() => {
    return new Erc20(underlyingAddress, signer || provider);
  }, [signer, underlyingAddress, provider])

  const fetchAllowance = useCallback(async () => {
    if (!connected || !walletAddress) {
      return;
    }

    let allowance: BigNumber;
    let state: ApproveState;
    if (isNative) {
      allowance = await provider.getBalance(walletAddress);
      state = ApproveState.APPROVED;
    } else {
      allowance = await contract().allowance(walletAddress, spender);
      state = allowance.eq(0) ? ApproveState.NOT_APPROVED : ApproveState.APPROVED;
    }

    setAllowance(allowance);
    setApproveState(state);
  }, [connected, walletAddress, isNative, provider, contract, spender]);

  const resetApprove = useCallback(async () => {
    if (!connected || !walletAddress || isNative) {
      return;
    }

    await contract().approve(spender, BigNumber.from(0))
  }, [connected, contract, isNative, spender, walletAddress])

  const approve = useCallback(async (amount: BigNumber) => {
    if (!connected || !walletAddress || isNative) {
      return;
    }

    if (approveState === ApproveState.APPROVED && market.zeroAllowance) {
      presentModal(<ResetAllowanceModal market={market} />)
      return;
    }

    await contract().approve(spender, amount);
  }, [connected, walletAddress, isNative, approveState, market, contract, spender, presentModal]);

  const approveAll = useCallback(async () => {
    await approve(ethers.constants.MaxUint256);
  }, [approve]);

  useEffect(() => {
    fetchAllowance().catch((err) => console.error(`failed to fetch allowance: ${err.stack}`))
  }, [fetchAllowance])

  return {
    approve,
    approveAll,
    approveState,
    allowance,
    resetApprove,
  }
}

export default useApprove;
