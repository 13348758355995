import { BigNumber, Contract } from 'ethers';
import { Signer } from "@ethersproject/abstract-signer";
import { Provider, TransactionResponse } from '@ethersproject/abstract-provider';
import { Protocol } from '../Protocols';

import { GAS_MULTIPLIER } from "./constants";
import IceCreamABI from './ABIs/iceCream';

class IceCream {
  private contract: Contract;

  address: string;

  constructor(protocol: Protocol, provider: Provider | Signer) {
    const address = protocol.iceCreamAddress;
    if (address === '') {
      throw new Error('invalid iceCREAM address');
    }

    this.contract = new Contract(address, IceCreamABI, provider);
    this.address = address;
  }

  balanceOf(account: string): Promise<BigNumber> {
    return this.contract.balanceOf(account);
  }

  unlockTime(account: string): Promise<BigNumber> {
    return this.contract.locked__end(account);
  }

  totalSupply(): Promise<BigNumber> {
    return this.contract.totalSupply();
  }

  supply(): Promise<BigNumber> {
    return this.contract.supply();
  }

  async stake(amount: BigNumber, unlockTime: BigNumber): Promise<TransactionResponse> {
    const gas = await this.contract.estimateGas.create_lock(amount, unlockTime);
    return this.contract.create_lock(amount, unlockTime, {gasLimit: gas.mul(GAS_MULTIPLIER)});
  }

  async increaseAmount(amount: BigNumber): Promise<TransactionResponse> {
    const gas = await this.contract.estimateGas.increase_amount(amount);
    return this.contract.increase_amount(amount, {gasLimit: gas.mul(GAS_MULTIPLIER)});
  }

  async increaseUnlockTime(unlockTime: BigNumber): Promise<TransactionResponse> {
    const gas = await this.contract.estimateGas.increase_unlock_time(unlockTime);
    return this.contract.increase_unlock_time(unlockTime, {gasLimit: gas.mul(GAS_MULTIPLIER)});
  }

  async withdraw(): Promise<TransactionResponse> {
    const gas = await this.contract.estimateGas.withdraw();
    return this.contract.withdraw({gasLimit: gas.mul(GAS_MULTIPLIER)});
  }
}

export default IceCream;
