import { BigNumber, Contract } from 'ethers';
import { Signer } from '@ethersproject/abstract-signer';
import { Provider, TransactionResponse } from '@ethersproject/abstract-provider';

import AbstractCToken from "./AbstractCToken";
import { GAS_MULTIPLIER } from "./constants";
import CErc20ABI from './ABIs/cErc20';

class CErc20 extends AbstractCToken {
  private contract: Contract;

  address: string;

  constructor(address: string, provider: Provider | Signer) {
    super();
    this.contract = new Contract(address, CErc20ABI, provider);
    this.address = address;
  }

  connect(signer: Signer) {
    this.contract = this.contract.connect(signer);
  }

  async decimals(): Promise<number> {
    return this.contract.decimals();
  }

  async mint(mintAmount: BigNumber): Promise<TransactionResponse> {
    const gas = await this.contract.estimateGas.mint(mintAmount);
    return this.contract.mint(mintAmount, {gasLimit: gas.mul(GAS_MULTIPLIER)});
  }

  async redeem(redeemAmount: BigNumber): Promise<TransactionResponse> {
    const gas = await this.contract.estimateGas.redeem(redeemAmount);
    return this.contract.redeem(redeemAmount, {gasLimit: gas.mul(GAS_MULTIPLIER)});
  }

  async redeemUnderlying(redeemAmount: BigNumber): Promise<TransactionResponse> {
    const gas = await this.contract.estimateGas.redeemUnderlying(redeemAmount);
    return this.contract.redeemUnderlying(redeemAmount, {gasLimit: gas.mul(GAS_MULTIPLIER)});
  }

  async borrow(borrowAmount: BigNumber): Promise<TransactionResponse> {
    const gas = await this.contract.estimateGas.borrow(borrowAmount);
    return this.contract.borrow(borrowAmount, {gasLimit: gas.mul(GAS_MULTIPLIER)});
  }

  async repayBorrow(repayAmount: BigNumber): Promise<TransactionResponse> {
    const gas = await this.contract.estimateGas.repayBorrow(repayAmount);
    return this.contract.repayBorrow(repayAmount, {gasLimit: gas.mul(GAS_MULTIPLIER)});
  }

  async claimSushi(account: string): Promise<TransactionResponse> {
    const gas = await this.contract.estimateGas.claimSushi(account);
    return this.contract.claimSushi(account, {gasLimit: gas.mul(GAS_MULTIPLIER)});
  }

  async claimCake(account: string): Promise<TransactionResponse> {
    const gas = await this.contract.estimateGas.claimCake(account);
    return this.contract.claimCake(account, {gasLimit: gas.mul(GAS_MULTIPLIER)});
  }

  // RETURN: The current exchange rate as an unsigned integer, scaled by 1e18.
  async exchangeRateCurrent(): Promise<BigNumber> {
    return this.contract.callStatic.exchangeRateCurrent();
  }
}

export default CErc20;
