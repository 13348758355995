import { Category } from '../Category';
import { Market } from '../Type';

const BSCMarkets: Market[] = [
  {
    name: 'Cream BNB',
    address: '0x1Ffe17B99b439bE0aFC831239dDECda2A790fF3A',
    symbol: 'crBNB',
    underlyingAddress: '',
    underlyingDecimal: 18,
    underlyingName: 'Binance coin',
    underlyingSymbol: 'BNB',
    category: Category.major,
    zeroAllowance: false,
  },
  {
    name: 'Cream BTCB Token',
    address: '0x11883Cdea6bAb720092791cc89affa54428Ce069',
    symbol: 'crBTCB',
    underlyingAddress: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
    underlyingDecimal: 18,
    underlyingName: 'BTCB Token',
    underlyingSymbol: 'BTCB',
    category: Category.major,
    zeroAllowance: false,
  },
  {
    name: 'Cream Binance USD',
    address: '0x2bc4eb013ddee29d37920938b96d353171289b7c',
    symbol: 'crBUSD',
    underlyingAddress: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    underlyingDecimal: 18,
    underlyingName: 'BUSD Token',
    underlyingSymbol: 'BUSD',
    category: Category.stable,
    zeroAllowance: false,
  },
  {
    name: 'Cream Ethereum Token',
    address: '0xb31f5d117541825D6692c10e4357008EDF3E2BCD',
    symbol: 'crETH',
    underlyingAddress: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    underlyingDecimal: 18,
    underlyingName: 'Ethereum Token',
    underlyingSymbol: 'ETH',
    category: Category.major,
    zeroAllowance: false,
  },
  {
    name: 'Cream Tether USD',
    address: '0xEF6d459FE81C3Ed53d292c936b2df5a8084975De',
    symbol: 'crUSDT',
    underlyingAddress: '0x55d398326f99059fF775485246999027B3197955',
    underlyingDecimal: 18,
    underlyingName: 'Tether USD',
    underlyingSymbol: 'USDT',
    category: Category.stable,
    zeroAllowance: false,
  },
  {
    name: 'Cream USD Coin',
    address: '0xD83C88DB3A6cA4a32FFf1603b0f7DDce01F5f727',
    symbol: 'crUSDC',
    underlyingAddress: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
    underlyingDecimal: 18,
    underlyingName: 'USD Coin',
    underlyingSymbol: 'USDC',
    category: Category.stable,
    zeroAllowance: false,
  },
  {
    name: 'Cream XRP',
    address: '0xAa46e2c21B7763a73DB48e9b318899253E66e20C',
    symbol: 'crXRP',
    underlyingAddress: '0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE',
    underlyingDecimal: 18,
    underlyingName: 'XRP Token',
    underlyingSymbol: 'XRP',
    category: Category.major,
    zeroAllowance: false,
  },
  {
    name: 'Cream Litecoin Token',
    address: '0x8cc7E2a6de999758499658bB702143FD025E09B2',
    symbol: 'crLTC',
    underlyingAddress: '0x4338665CBB7B2485A8855A139b75D5e34AB0DB94',
    underlyingDecimal: 18,
    underlyingName: 'Litecoin Token',
    underlyingSymbol: 'LTC',
    category: Category.major,
    zeroAllowance: false,
  },
  {
    name: 'Cream Bitcoin Cash Token',
    address: '0xCb87Cee8c77CdFD310fb3C58ff72e688d46f90b1',
    symbol: 'crBCH',
    underlyingAddress: '0x8fF795a6F4D97E7887C79beA79aba5cc76444aDf',
    underlyingDecimal: 18,
    underlyingName: 'Bitcoin Cash Token',
    underlyingSymbol: 'BCH',
    category: Category.major,
    zeroAllowance: false,
  },
  {
    name: 'Cream ChainLink Token',
    address: '0x3942936782d788ce69155F776A51A5F1C9dd9B22',
    symbol: 'crLINK',
    underlyingAddress: '0xF8A0BF9cF54Bb92F17374d9e9A321E6a111a51bD',
    underlyingDecimal: 18,
    underlyingName: 'ChainLink Token',
    underlyingSymbol: 'LINK',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream Polkadot Token',
    address: '0x53D88d2ffDBE71E81D95b08AE0cA49D0C4A8515f',
    symbol: 'crDOT',
    underlyingAddress: '0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402',
    underlyingDecimal: 18,
    underlyingName: 'Polkadot Token',
    underlyingSymbol: 'DOT',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream Cardano Token',
    address: '0x81C15D3E956e55e77E1f3F257f0A65Bd2725fC55',
    symbol: 'crADA',
    underlyingAddress: '0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47',
    underlyingDecimal: 18,
    underlyingName: 'Cardano Token',
    underlyingSymbol: 'ADA',
    category: Category.major,
    zeroAllowance: false,
  },
  {
    name: 'Cream Cream',
    address: '0x426D6D53187be3288fe37f214e3F6901D8145b62',
    symbol: 'crCREAM',
    underlyingAddress: '0xd4CB328A82bDf5f03eB737f37Fa6B370aef3e888',
    underlyingDecimal: 18,
    underlyingName: 'Cream',
    underlyingSymbol: 'CREAM',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream Band Protocol Token',
    address: '0x738f3810b3dA0F3e6dC8C689D0d72f3b4992c43b',
    symbol: 'crBAND',
    underlyingAddress: '0xAD6cAEb32CD2c308980a548bD0Bc5AA4306c6c18',
    underlyingDecimal: 18,
    underlyingName: 'Band Protocol Token',
    underlyingSymbol: 'BAND',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream EOS Token',
    address: '0x19eE64850862cFd234e20c0db4edA286f12ec907',
    symbol: 'crEOS',
    underlyingAddress: '0x56b6fB708fC5732DEC1Afc8D8556423A2EDcCbD6',
    underlyingDecimal: 18,
    underlyingName: 'EOS Token',
    underlyingSymbol: 'EOS',
    category: Category.major,
    zeroAllowance: false,
  },
  {
    name: 'Cream Dai Token',
    address: '0x9095e8d707E40982aFFce41C61c10895157A1B22',
    symbol: 'crDAI',
    underlyingAddress: '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
    underlyingDecimal: 18,
    underlyingName: 'Dai Token',
    underlyingSymbol: 'DAI',
    category: Category.stable,
    zeroAllowance: false,
  },
  {
    name: 'Cream Tezos Token',
    address: '0xE692714717a89E4F2ab89dd17d8DDdD7bb52De8e',
    symbol: 'crXTZ',
    underlyingAddress: '0x16939ef78684453bfDFb47825F8a5F714f12623a',
    underlyingDecimal: 18,
    underlyingName: 'Tezos Token',
    underlyingSymbol: 'XTZ',
    category: Category.major,
    zeroAllowance: false,
  },
  {
    name: 'Cream Filecoin',
    address: '0x1aF8c1C3AD36A041cb6678feD86B1E095004fd16',
    symbol: 'crFIL',
    underlyingAddress: '0x0D8Ce2A99Bb6e3B7Db580eD848240e4a0F9aE153',
    underlyingDecimal: 18,
    underlyingName: 'Filecoin',
    underlyingSymbol: 'FIL',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream YFI',
    address: '0xEA466cd2583A0290b9E7b987a769a7Eb468FB0A5',
    symbol: 'crYFI',
    underlyingAddress: '0x88f1A5ae2A3BF98AEAF342D26B30a79438c9142e',
    underlyingDecimal: 18,
    underlyingName: 'yearn.finance',
    underlyingSymbol: 'YFI',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream Uniswap',
    address: '0x3B0Be453a4008EBc2eDd457e7Bd355f1C5469d68',
    symbol: 'crUNI',
    underlyingAddress: '0xBf5140A22578168FD562DCcF235E5D43A02ce9B1',
    underlyingDecimal: 18,
    underlyingName: 'Uniswap',
    underlyingSymbol: 'UNI',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream Cosmos Token',
    address: '0x0E9d900C884964dC4B26db96Ba113825B1a09Baa',
    symbol: 'crATOM',
    underlyingAddress: '0x0Eb3a705fc54725037CC9e008bDede697f62F335',
    underlyingDecimal: 18,
    underlyingName: 'Cosmos Token',
    underlyingSymbol: 'ATOM',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream AlphaToken',
    address: '0x264Bc4Ea2F45cF6331AD6C3aC8d7257Cf487FcbC',
    symbol: 'crALPHA',
    underlyingAddress: '0xa1faa113cbE53436Df28FF0aEe54275c13B40975',
    underlyingDecimal: 18,
    underlyingName: 'AlphaToken',
    underlyingSymbol: 'ALPHA',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream Trust Wallet',
    address: '0x2d3bfaDF9BC94E3Ab796029A030e863F1898aA06',
    symbol: 'crTWT',
    underlyingAddress: '0x4B0F1812e5Df2A09796481Ff14017e6005508003',
    underlyingDecimal: 18,
    underlyingName: 'Trust Wallet',
    underlyingSymbol: 'TWT',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream PancakeSwap Token',
    address: '0xBf9B95b78bc42F6CF53FF2A0ce19D607cFe1ff82',
    symbol: 'crCAKE',
    underlyingAddress: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    underlyingDecimal: 18,
    underlyingName: 'PancakeSwap Token',
    underlyingSymbol: 'CAKE',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream Venus',
    address: '0x4EBdeF163fF08ac1D56A89bAfeFd6C01cc28a48f',
    symbol: 'crXVS',
    underlyingAddress: '0xcF6BB5389c92Bdda8a3747Ddb454cB7a64626C63',
    underlyingDecimal: 18,
    underlyingName: 'Venus',
    underlyingSymbol: 'XVS',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream Basic Attention Token',
    address: '0x4cB7F1f4aD7a6b53802589Af3B90612C1674Fec4',
    symbol: 'crBAT',
    underlyingAddress: '0x101d82428437127bF1608F699CD651e6Abf9766E',
    underlyingDecimal: 18,
    underlyingName: 'Basic Attention Token',
    underlyingSymbol: 'BAT',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream VAI Stablecoin',
    address: '0x84902bd5ccef97648Bf69C5096729A9367043bEb',
    symbol: 'crVAI',
    underlyingAddress: '0x4BD17003473389A42DAF6a0a729f6Fdb328BbBd7',
    underlyingDecimal: 18,
    underlyingName: 'VAI Stablecoin',
    underlyingSymbol: 'VAI',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream AUTOv2',
    address: '0xF77DF34F4Bf632Fb5CA928592a73a29A42BCf0B1',
    symbol: 'crAUTO',
    underlyingAddress: '0xa184088a740c695E156F91f5cC086a06bb78b827',
    underlyingDecimal: 18,
    underlyingName: 'AUTOv2',
    underlyingSymbol: 'AUTO',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream renBTC',
    address: '0x7F746A80506a4cafA39938f7C08Ad59cFa6dE418',
    symbol: 'crRENBTC',
    underlyingAddress: '0xfCe146bF3146100cfe5dB4129cf6C82b0eF4Ad8c',
    underlyingDecimal: 8,
    underlyingName: 'renBTC',
    underlyingSymbol: 'RENBTC',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream renZEC',
    address: '0xbE7E1d74AcAE787355169bC61A8729b2040fCe6b',
    symbol: 'crRENZEC',
    underlyingAddress: '0x695FD30aF473F2960e81Dc9bA7cB67679d35EDb7',
    underlyingDecimal: 8,
    underlyingName: 'renZEC',
    underlyingSymbol: 'RENZEC',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream Binance Beacon ETH',
    address: '0xDCf60E349a5AAeeEcdd2fb6772931FBF3486eD1C',
    symbol: 'crBETH',
    underlyingAddress: '0x250632378E573c6Be1AC2f97Fcdf00515d0Aa91B',
    underlyingDecimal: 18,
    underlyingName: 'Binance Beacon ETH',
    underlyingSymbol: 'BETH',
    category: Category.major,
    zeroAllowance: false,
  },
  {
    name: 'Cream IoTeX Network',
    address: '0xc17C8C5b8bB9456c624f8534FdE6cBda2451488C',
    symbol: 'crIOTX',
    underlyingAddress: '0x9678E42ceBEb63F23197D726B29b1CB20d0064E5',
    underlyingDecimal: 18,
    underlyingName: 'IoTeX Network',
    underlyingSymbol: 'IOTX',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream Swipe',
    address: '0xa8D75A0D17D2f4F2f4673975Ab8470269D019c96',
    symbol: 'crSXP',
    underlyingAddress: '0x47BEAd2563dCBf3bF2c9407fEa4dC236fAbA485A',
    underlyingDecimal: 18,
    underlyingName: 'Swipe',
    underlyingSymbol: 'SXP',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream SushiToken',
    address: '0x9B53e7D5e3F6Cc8694840eD6C9f7fee79e7Bcee5',
    symbol: 'crSUSHI',
    underlyingAddress: '0x947950BcC74888a40Ffa2593C5798F11Fc9124C4',
    underlyingDecimal: 18,
    underlyingName: 'SushiToken',
    underlyingSymbol: 'SUSHI',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Cream Pancake LP Token CAKE-BNB',
    address: '0x98b7871702B602E65eAEF46051D75ca334f872D0',
    symbol: 'crCAKE-LP-CAKE-BNB',
    underlyingAddress: '0x0eD7e52944161450477ee417DE9Cd3a859b14fD0',
    underlyingDecimal: 18,
    underlyingName: 'Pancake CAKE-BNB v2',
    underlyingSymbol: 'Cake-LP-CAKE-BNB v2',
    category: Category.wrapped,
    zeroAllowance: false,
  },
  {
    name: 'Cream Pancake LP Token BUSD-BNB',
    address: '0x7FD568d6a1a11B19427c8cCb90F7bB80E4Ab1C5F',
    symbol: 'crCAKE-LP-BUSD-BNB',
    underlyingAddress: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    underlyingDecimal: 18,
    underlyingName: 'Pancake BUSD-BNB v2',
    underlyingSymbol: 'Cake-LP-BUSD-BNB v2',
    category: Category.wrapped,
    zeroAllowance: false,
  },
  {
    name: 'Cream Pancake LP Token BTCB-BNB',
    address: '0x5eA2dD1DE21Ed3b5316CEF89985EDc66cF9b2a0E',
    symbol: 'crCAKE-LP-BTCB-BNB',
    underlyingAddress: '0x61EB789d75A95CAa3fF50ed7E47b96c132fEc082',
    underlyingDecimal: 18,
    underlyingName: 'Pancake BTCB-BNB v2',
    underlyingSymbol: 'Cake-LP-BTCB-BNB v2',
    category: Category.wrapped,
    zeroAllowance: false,
  },
  {
    name: 'Cream Pancake LP Token ETH-BNB',
    address: '0x9972Dd9912367cF395bEA752ad49D81f33F7cA85',
    symbol: 'crCAKE-LP-ETH-BNB',
    underlyingAddress: '0x74E4716E431f45807DCF19f284c7aA99F18a4fbc',
    underlyingDecimal: 18,
    underlyingName: 'Pancake ETH-BNB v2',
    underlyingSymbol: 'Cake-LP-ETH-BNB v2',
    category: Category.wrapped,
    zeroAllowance: false,
  },
  {
    name: 'Cream Pancake LP Token USDT-BUSD',
    address: '0x04c61EF58a6f1D6c572045a39A5434Ab9Dee69Fb',
    symbol: 'crCAKE-LP-USDT-BUSD',
    underlyingAddress: '0x7EFaEf62fDdCCa950418312c6C91Aef321375A00',
    underlyingDecimal: 18,
    underlyingName: 'Pancake USDT-BUSD v2',
    underlyingSymbol: 'Cake-LP-USDT-BUSD v2',
    category: Category.wrapped,
    zeroAllowance: false,
  },
  {
    name: 'Cream Pancake LP Token CAKE-BNB',
    address: '0x36CdF5972aCA2b70F10D0f7aF0D95871ABC065d9',
    symbol: 'crCAKE-LP-CAKE-BNB',
    underlyingAddress: '0xA527a61703D82139F8a06Bc30097cC9CAA2df5A6',
    underlyingDecimal: 18,
    underlyingName: 'Pancake CAKE-BNB v1',
    underlyingSymbol: 'Cake-LP-CAKE-BNB v1',
    category: Category.legacy,
    zeroAllowance: false,
  },
  {
    name: 'Cream Pancake LP Token BUSD-BNB',
    address: '0x26A490a0F419DcFBEd97E568403654c2746a7110',
    symbol: 'crCAKE-LP-BUSD-BNB',
    underlyingAddress: '0x1B96B92314C44b159149f7E0303511fB2Fc4774f',
    underlyingDecimal: 18,
    underlyingName: 'Pancake BUSD-BNB v1',
    underlyingSymbol: 'Cake-LP-BUSD-BNB v1',
    category: Category.legacy,
    zeroAllowance: false,
  },
  {
    name: 'Cream Pancake LP Token BTCB-BNB',
    address: '0xe39b9E0366940Eb3CA62b85Ffae838eF0f8B14e0',
    symbol: 'crCAKE-LP-BTCB-BNB',
    underlyingAddress: '0x7561EEe90e24F3b348E1087A005F78B4c8453524',
    underlyingDecimal: 18,
    underlyingName: 'Pancake BTCB-BNB v1',
    underlyingSymbol: 'Cake-LP-BTCB-BNB v1',
    category: Category.legacy,
    zeroAllowance: false,
  },
  {
    name: 'Cream Pancake LP Token ETH-BNB',
    address: '0x5Fa61a5A65920F90Af970B13b7f28DaEef0440B7',
    symbol: 'crCAKE-LP-ETH-BNB',
    underlyingAddress: '0x70D8929d04b60Af4fb9B58713eBcf18765aDE422',
    underlyingDecimal: 18,
    underlyingName: 'Pancake ETH-BNB v1',
    underlyingSymbol: 'Cake-LP-ETH-BNB v1',
    category: Category.legacy,
    zeroAllowance: false,
  },
  {
    name: 'Cream Pancake LP Token USDT-BUSD',
    address: '0x19b08fF7D90d63ad109c6FaBB8e0fcC866477A41',
    symbol: 'crCAKE-LP-USDT-BUSD',
    underlyingAddress: '0xc15fa3E22c912A276550F3E5FE3b0Deb87B55aCd',
    underlyingDecimal: 18,
    underlyingName: 'Pancake USDT-BUSD v1',
    underlyingSymbol: 'Cake-LP-USDT-BUSD v1',
    category: Category.legacy,
    zeroAllowance: false,
  },
  {
    name: 'Cream Wrapped BNB',
    address: '0x15CC701370cb8ADA2a2B6f4226eC5CF6AA93bC67',
    symbol: 'crWBNB',
    underlyingAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    underlyingDecimal: 18,
    underlyingName: 'Wrapped BNB',
    underlyingSymbol: 'WBNB',
    category: Category.major,
    zeroAllowance: false,
  }
];

export default BSCMarkets;
