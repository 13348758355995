const liquidityMiningLens = [
  {
    "inputs": [
      {
        "internalType": "contract LiquidityMining",
        "name": "_liquidityMining",
        "type": "address"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "constructor"
  },
  {
    "inputs": [
      {
        "internalType": "address[]",
        "name": "cTokens",
        "type": "address[]"
      }
    ],
    "name": "getAllMarketRewardSpeeds",
    "outputs": [
      {
        "components": [
          {
            "internalType": "address",
            "name": "cToken",
            "type": "address"
          },
          {
            "components": [
              {
                "components": [
                  {
                    "internalType": "address",
                    "name": "rewardTokenAddress",
                    "type": "address"
                  },
                  {
                    "internalType": "string",
                    "name": "rewardTokenSymbol",
                    "type": "string"
                  },
                  {
                    "internalType": "uint8",
                    "name": "rewardTokenDecimals",
                    "type": "uint8"
                  }
                ],
                "internalType": "struct LiquidityMiningLens.RewardTokenInfo",
                "name": "rewardToken",
                "type": "tuple"
              },
              {
                "components": [
                  {
                    "internalType": "uint256",
                    "name": "speed",
                    "type": "uint256"
                  },
                  {
                    "internalType": "uint256",
                    "name": "start",
                    "type": "uint256"
                  },
                  {
                    "internalType": "uint256",
                    "name": "end",
                    "type": "uint256"
                  }
                ],
                "internalType": "struct LiquidityMiningLens.RewardSpeed",
                "name": "supplySpeed",
                "type": "tuple"
              },
              {
                "components": [
                  {
                    "internalType": "uint256",
                    "name": "speed",
                    "type": "uint256"
                  },
                  {
                    "internalType": "uint256",
                    "name": "start",
                    "type": "uint256"
                  },
                  {
                    "internalType": "uint256",
                    "name": "end",
                    "type": "uint256"
                  }
                ],
                "internalType": "struct LiquidityMiningLens.RewardSpeed",
                "name": "borrowSpeed",
                "type": "tuple"
              }
            ],
            "internalType": "struct LiquidityMiningLens.RewardSpeedInfo[]",
            "name": "rewardSpeeds",
            "type": "tuple[]"
          }
        ],
        "internalType": "struct LiquidityMiningLens.MarketRewardSpeed[]",
        "name": "",
        "type": "tuple[]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "cToken",
        "type": "address"
      }
    ],
    "name": "getMarketRewardSpeeds",
    "outputs": [
      {
        "components": [
          {
            "internalType": "address",
            "name": "cToken",
            "type": "address"
          },
          {
            "components": [
              {
                "components": [
                  {
                    "internalType": "address",
                    "name": "rewardTokenAddress",
                    "type": "address"
                  },
                  {
                    "internalType": "string",
                    "name": "rewardTokenSymbol",
                    "type": "string"
                  },
                  {
                    "internalType": "uint8",
                    "name": "rewardTokenDecimals",
                    "type": "uint8"
                  }
                ],
                "internalType": "struct LiquidityMiningLens.RewardTokenInfo",
                "name": "rewardToken",
                "type": "tuple"
              },
              {
                "components": [
                  {
                    "internalType": "uint256",
                    "name": "speed",
                    "type": "uint256"
                  },
                  {
                    "internalType": "uint256",
                    "name": "start",
                    "type": "uint256"
                  },
                  {
                    "internalType": "uint256",
                    "name": "end",
                    "type": "uint256"
                  }
                ],
                "internalType": "struct LiquidityMiningLens.RewardSpeed",
                "name": "supplySpeed",
                "type": "tuple"
              },
              {
                "components": [
                  {
                    "internalType": "uint256",
                    "name": "speed",
                    "type": "uint256"
                  },
                  {
                    "internalType": "uint256",
                    "name": "start",
                    "type": "uint256"
                  },
                  {
                    "internalType": "uint256",
                    "name": "end",
                    "type": "uint256"
                  }
                ],
                "internalType": "struct LiquidityMiningLens.RewardSpeed",
                "name": "borrowSpeed",
                "type": "tuple"
              }
            ],
            "internalType": "struct LiquidityMiningLens.RewardSpeedInfo[]",
            "name": "rewardSpeeds",
            "type": "tuple[]"
          }
        ],
        "internalType": "struct LiquidityMiningLens.MarketRewardSpeed",
        "name": "",
        "type": "tuple"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "rewardToken",
        "type": "address"
      }
    ],
    "name": "getRewardTokenInfo",
    "outputs": [
      {
        "components": [
          {
            "internalType": "address",
            "name": "rewardTokenAddress",
            "type": "address"
          },
          {
            "internalType": "string",
            "name": "rewardTokenSymbol",
            "type": "string"
          },
          {
            "internalType": "uint8",
            "name": "rewardTokenDecimals",
            "type": "uint8"
          }
        ],
        "internalType": "struct LiquidityMiningLens.RewardTokenInfo",
        "name": "",
        "type": "tuple"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "rewardToken",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "account",
        "type": "address"
      }
    ],
    "name": "getRewardTokenUserBalance",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "account",
        "type": "address"
      }
    ],
    "name": "getRewardsAvailable",
    "outputs": [
      {
        "components": [
          {
            "components": [
              {
                "internalType": "address",
                "name": "rewardTokenAddress",
                "type": "address"
              },
              {
                "internalType": "string",
                "name": "rewardTokenSymbol",
                "type": "string"
              },
              {
                "internalType": "uint8",
                "name": "rewardTokenDecimals",
                "type": "uint8"
              }
            ],
            "internalType": "struct LiquidityMiningLens.RewardTokenInfo",
            "name": "rewardToken",
            "type": "tuple"
          },
          {
            "internalType": "uint256",
            "name": "amount",
            "type": "uint256"
          }
        ],
        "internalType": "struct LiquidityMiningLens.RewardAvailable[]",
        "name": "",
        "type": "tuple[]"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "liquidityMining",
    "outputs": [
      {
        "internalType": "contract LiquidityMining",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  }
];

export default liquidityMiningLens;
