import React from 'react';
import styled from 'styled-components';

interface LinksProps {
  title: string;
  titleColor: string;
  links: { text: string; url: string; }[];
  className?: string;
}

const Links: React.FC<LinksProps> = ({ title, titleColor, links, className }) => {
  return (
    <Wrapper className={className}>
      <Title textColor={titleColor} className="fw-bold">{title}</Title>
      {links.map(({ text, url }, idx) => {
        return <Link key={idx} href={url}>{text}</Link>
      })}
    </Wrapper>
  )
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div<{textColor: string}>`
  color: ${(props) => props.textColor};
  font-size: ${(props) => props.theme.textSize.big};
  margin-bottom: 12px;
`;

const Link = styled.a`
  line-height: 24px;
  
  &:hover {
    color: white !important;
  }
`;

export default Links;
