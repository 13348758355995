import { BigNumber, Contract } from 'ethers';
import { Signer } from '@ethersproject/abstract-signer';
import { Provider, TransactionResponse } from '@ethersproject/abstract-provider';

import AbstractCToken from "./AbstractCToken";
import { GAS_MULTIPLIER } from "./constants";
import CEtherABI from './ABIs/cEther';

class CEther extends AbstractCToken {
  private contract: Contract;

  address: string;

  constructor(address: string, provider: Provider | Signer) {
    super();
    this.contract = new Contract(address, CEtherABI, provider);
    this.address = address;
  }

  connect(signer: Signer) {
    this.contract = this.contract.connect(signer);
  }

  async decimals(): Promise<number> {
    return this.contract.decimals();
  }

  async mint(mintAmount: BigNumber): Promise<TransactionResponse> {
    const gas = await this.contract.estimateGas.mint({value: mintAmount});
    return this.contract.mint(
      {
        gasLimit: gas.mul(GAS_MULTIPLIER),
        value: mintAmount,
      }
    );
  }

  async redeem(redeemAmount: BigNumber): Promise<TransactionResponse> {
    const gas = await this.contract.estimateGas.redeem(redeemAmount);
    return this.contract.redeem(redeemAmount, {gasLimit: gas.mul(GAS_MULTIPLIER)});
  }

  async redeemUnderlying(redeemAmount: BigNumber): Promise<TransactionResponse> {
    const gas = await this.contract.estimateGas.redeemUnderlying(redeemAmount);
    return this.contract.redeemUnderlying(redeemAmount, {gasLimit: gas.mul(GAS_MULTIPLIER)});
  }

  async borrow(borrowAmount: BigNumber): Promise<TransactionResponse> {
    const gas = await this.contract.estimateGas.borrow(borrowAmount);
    return this.contract.borrow(borrowAmount, {gasLimit: gas.mul(GAS_MULTIPLIER)});
  }

  async repayBorrow(repayAmount: BigNumber): Promise<TransactionResponse> {
    const gas = await this.contract.estimateGas.repayBorrow({value: repayAmount});
    return this.contract.repayBorrow(
      {
        gasLimit: gas.mul(GAS_MULTIPLIER),
        value: repayAmount,
      }
    );
  }

  // RETURN: The current exchange rate as an unsigned integer, scaled by 1e18.
  async exchangeRateCurrent(): Promise<BigNumber> {
    return this.contract.callStatic.exchangeRateCurrent();
  }
}

export default CEther;
